import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import psd2Sp1 from './3rd/psd2Sp1.reducers';
import rgbPayments from './3rd/rgbPayment.reducers';
import signant from './3rd/signant.reducers';
import stripe from './3rd/stripe.reducers';
import adminEmail from './adminEmail.reducers';
import bank from './bank.reducers';
import bankTransaction from './bankTransaction.reducers';
import company from './company.reducers';
import companyBank from './companyBank.reducers';
import companyPaymentPackages from './companyPaymentPackages.reducers';
import companyService from './companyService.reducers';
import companyUsers from './companyUsers.reducers';
import companyUsersAlertVoucher from './companyUsersAlertVoucher.reducers';
import companyUsersAssignPayment from './companyUsersAssignPayment.reducers';
import companyUsersReminder from './companyUsersReminder.reducers';
import connectMissing from './connectMissing.reducers';
import globalRule from './globalRule.reducers';
import inbox from './inbox.reducers';
import localRule from './localRule.reducers';
import notification from './notification.reducers';
import paymentPackages from './paymentPackages.reducers';
import roleReducers from './roles.reducers';
import setting from './setting.reducers';
import settingBank from './settingBank.reducers';
import settingZtl from './settingZtl.reducers';
import system from './system.reducers';
import task from './task.reducers';
import transactonVoucher from './transactionVoucher.reducers';
import voucher from './voucher.reducers';
import voucherPayment from './voucherPayment.reducers';
import voucherPaymentsBasket from './voucherPaymentsBasket.reducers';
import voucherPaymentInternal from './voucherPaymentInternal.reducers';
import voucherPaymentInternalFilterData from './voucherPaymentInternalFilter.reducers';
import statistic from './statistic.reducers';
import paymentList from './paymentList.reducers';
import consent from './consent.reducers';

export default combineReducers({
  form: reduxForm,
  system,
  company,
  voucher,
  voucherPayment,
  bank,
  bankTransaction,
  inbox,
  adminEmail,
  transactonVoucher,
  connectMissing,
  globalRule,
  localRule,
  companyUsers,
  companyUsersReminder,
  companyUsersAssignPayment,
  companyUsersAlertVoucher,
  setting,
  settingBank,
  settingZtl,
  signant,
  voucherPaymentsBasket,
  companyService,
  companyPaymentPackages,
  paymentPackages,
  stripe,
  psd2Sp1,
  companyBank,
  rgbPayments,
  roleReducers,
  notification,
  task,
  voucherPaymentInternal,
  voucherPaymentInternalFilterData,
  statistic,
  paymentList,
  consent
});
