import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import TransactionVoucherServices from '../../../services/transactionVoucherServices';

export default function* getTransactionMissingSummary() {
  yield takeEvery(actionTypes.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_REQUEST, adminVoucherConnectSaga);
}

export function* adminVoucherConnectSaga(action) {
  try {
    const response = yield call(TransactionVoucherServices.adminVoucherConnect, action.payload);
    if (response.success) {
      yield put(actions.adminVoucherConnectSuccess(response.data));
    } else {
      yield put(actions.adminVoucherConnectFail(response.data));
    }
  } catch (error) {
    yield put(actions.adminVoucherConnectFail(error));
  }
}
