import * as types from '../actionTypes/ActionTypes';

export const setCurrentTransaction = (payload) => ({
  type: types.BANK.SET_CURRENT_TRANSACTION,
  payload
});

export const setFilterBank = (payload) => ({
  type: types.BANK.SET_FILTER_BANK,
  payload
});

export const resetFilterBank = (payload) => ({
  type: types.BANK.RESET_FILTER_BANK,
  payload
});

export const fetchBankData = (payload) => ({
  type: types.BANK.FETCH_BANK_DATA_REQUEST,
  payload
});
export const fetchBankDataSuccess = (payload) => ({
  type: types.BANK.FETCH_BANK_DATA_SUCCESS,
  payload
});
export const fetchBankDataFail = (payload) => ({
  type: types.BANK.FETCH_BANK_DATA_FAIL,
  payload
});

export const setBankTransaction = (payload) => ({
  type: types.BANK.SET_BANK_TRANSACTION,
  payload
});

export const getBankBySwiftCode = (payload) => ({
  type: types.BANK.GET_BANK_BY_SWIFT_CODE_REQUEST,
  payload
});
export const getBankBySwiftCodeSuccess = (payload) => ({
  type: types.BANK.GET_BANK_BY_SWIFT_CODE_SUCCESS,
  payload
});
export const getBankBySwiftCodeFail = (payload) => ({
  type: types.BANK.GET_BANK_BY_SWIFT_CODE_FAIL,
  payload
});

export const fetchBankByCompany = (payload) => ({
  type: types.BANK.FETCH_BANK_BY_COMPANY_REQUEST,
  payload
});
export const fetchBankByCompanySuccess = (payload) => ({
  type: types.BANK.FETCH_BANK_BY_COMPANY_SUCCESS,
  payload
});
export const fetchBankByCompanyFail = (payload) => ({
  type: types.BANK.FETCH_BANK_BY_COMPANY_FAIL,
  payload
});

export const getBankByFilter = (payload) => ({
  type: types.BANK.GET_BANK_BY_FILTER_REQUEST,
  payload
});
export const getBankByFilterSuccess = (payload) => ({
  type: types.BANK.GET_BANK_BY_FILTER_SUCCESS,
  payload
});
export const getBankByFilterFail = (payload) => ({
  type: types.BANK.GET_BANK_BY_FILTER_FAIL,
  payload
});

export const changeColumnDefine = (payload) => ({
  type: types.BANK.CHANGE_COLUMN_DEFINE_IMPORT,
  payload
});

export const changeDataImport = (payload) => ({
  type: types.BANK.CHANGE_DATA_IMPORT,
  payload
});

export const resetDataImport = (payload) => ({
  type: types.BANK.RESET_DATA_IMPORT,
  payload
});

export const setTabBankDetail = (payload) => ({
  type: types.BANK.SET_TAB_BANK_DETAIL,
  payload
});
