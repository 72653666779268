export const VOUCHER = {
  SET_VOUCHER_LIST_DATA: 'SET_VOUCHER_LIST_DATA',
  SET_CURRENT_VOUCHER: 'SET_CURRENT_VOUCHER',
  SET_FILTER_VOUCHER: 'SET_FILTER_VOUCHER',
  SET_OPEN_WINDOW_VOUCHER: 'SET_OPEN_WINDOW_VOUCHER',
  RESET_FILTER: 'RESET_FILTER',
  SET_VALUE_RECORD_TRANSACTION: 'SET_VALUE_RECORD_TRANSACTION',

  FETCH_VOUCHER_DATA_REQUEST: 'FETCH_VOUCHER_DATA_REQUEST',
  REMOVE_ROW_VOUCHER: 'REMOVE_ROW_VOUCHER',
  FETCH_VOUCHER_DATA_SUCCESS: 'FETCH_VOUCHER_DATA_SUCCESS',
  FETCH_VOUCHER_DATA_FAIL: 'FETCH_VOUCHER_DATA_FAIL',

  GET_VOUCHER_BALANCE_REQUEST: 'GET_VOUCHER_BALANCE_REQUEST',
  GET_VOUCHER_BALANCE_SUCCESS: 'GET_VOUCHER_BALANCE_SUCCESS',
  GET_VOUCHER_BALANCE_FAIL: 'GET_VOUCHER_BALANCE_FAIL',

  GET_MISSING_SUMMARY_REQUEST: 'GET_MISSING_SUMMARY_REQUEST',
  GET_MISSING_SUMMARY_SUCCESS: 'GET_MISSING_SUMMARY_SUCCESS',
  GET_MISSING_SUMMARY_FAIL: 'GET_MISSING_SUMMARY_FAIL',

  FETCH_ADMIN_VOUCHER_REQUEST: 'FETCH_ADMIN_VOUCHER_REQUEST',
  FETCH_ADMIN_VOUCHER_SUCCESS: 'FETCH_ADMIN_VOUCHER_SUCCESS',
  FETCH_ADMIN_VOUCHER_FAIL: 'FETCH_ADMIN_VOUCHER_FAIL',

  GET_VOUCHER_DETAIL_REQUEST: 'GET_VOUCHER_DETAIL_REQUEST',
  GET_VOUCHER_DETAIL_SUCCESS: 'GET_VOUCHER_DETAIL_SUCCESS',
  GET_VOUCHER_DETAIL_FAIL: 'GET_VOUCHER_DETAIL_FAIL',

  GET_VOUCHER_SUGGEST_INFO: 'GET_VOUCHER_SUGGEST_INFO',
  GET_VOUCHER_SUGGEST_INFO_SUCCESS: 'GET_VOUCHER_SUGGEST_INFO_SUCCESS',
  GET_VOUCHER_SUGGEST_INFO_FAIL: 'GET_VOUCHER_SUGGEST_INFO_FAIL',

  SET_VOUCHER_LABELS: 'SET_VOUCHER_LABELS',

  // V2
  GET_VOUCHER_BALANCES_V2_REQUEST: 'GET_VOUCHER_BALANCES_V2_REQUEST',
  GET_VOUCHER_BALANCES_V2_SUCCESS: 'GET_VOUCHER_BALANCES_V2_SUCCESS',
  GET_VOUCHER_BALANCES_V2_FAIL: 'GET_VOUCHER_BALANCES_FAIL',
};
