import LoggingServices from '../services/loggingServices';

const logger = async (msg, logFile = 'error') => {
  if (window.location.host === 'localhost:4002') {
    console.log(16, msg);
    return;
  }
  // log database
  const des = msg && msg.substring(0, 155);
  const log = JSON.stringify({ message: msg, description: des });
  LoggingServices.insert({
    level: logFile,
    log,
    system: 'Bilagsky-Web'
  });
  // log file
};

export default logger;
