import baseServices from '../baseServices';
import { api } from '../../constants/api.constant';
import { APIError } from '../../constants/errorCode/system';
import httpHelper from '../../helpers/httpHelper';

export default {
  fetchAll: async (params) => {
    try {
      if (!params.from || !params.to) {
        delete params.from;
        delete params.to;
      }
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPaymentsV2.fetchAll}?${queryParams}`;
      const data = await baseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  ztlScaSuccess: async (params) => {
    try {
      const { voucherPaymentIds } = params;
      const url = api.voucherPaymentsV2.scaSuccess.replace(':voucherPaymentIds', voucherPaymentIds);
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  payNow: async (params) => {
    try {
      const url = api.voucherPaymentsV2.payNow;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  singing: async (params) => {
    try {
      const { ids } = params;
      const url = api.voucherPaymentsV2.signing.replace(':ids', ids);
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addToPaymentList: async (params) => {
    try {
      const url = api.voucherPaymentsV2.addToPaymentList;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      console.log(41, error)
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatistic: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPaymentsV2.getStatistic}?${queryParams}`;
      const data = await baseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async (params) => {
    try {
      const { id } = params;
      const url = api.voucherPaymentsV2.update.replace(':id', id);
      const data = await baseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async (params) => {
    try {
      const url = api.voucherPaymentsV2.delete;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  checkDuplicate: async (params = {}) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = api.voucherPaymentsV2.exists + `?${queryParams}`;
      const data = await baseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  handleZtlScaCallback: async (params) => {
    try {
      const { flowId } = params;
      const url = api.voucherPaymentsV2.handleZtlScaCallback.replace(':flowId', flowId);
      console.log(112, url)
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
