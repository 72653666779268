import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import { voucherServicesV2 } from '../../../services/v2/voucherServicesV2';

export default function* getVoucherBalancesSaga() {
  yield takeEvery(actionTypes.VOUCHER.GET_VOUCHER_BALANCES_V2_REQUEST, getVoucherBalances);
}

export function* getVoucherBalances(action) {
  try {
    const response = yield call(voucherServicesV2.getVoucherBalances, action.payload);
    if (response.success) {
      yield put(actions.getVoucherBalancesV2Success(response.data));
    } else {
      yield put(actions.getVoucherBalancesV2Fail(response.data));
    }
  } catch (error) {
    yield put(actions.getVoucherBalancesV2Fail(error));
  }
}
