import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherServices from '../../../services/voucherServices';

export default function* fetchAdminVoucherSaga() {
  yield takeEvery(actionTypes.VOUCHER.FETCH_ADMIN_VOUCHER_REQUEST, fetchAdminVoucher);
}

export function* fetchAdminVoucher(action) {
  try {
    // 3 - get voucher statistic
    const response = yield call(VoucherServices.fetchAdminVoucher, action.payload);
    if (response.success) {
      yield put(actions.fetchAdminVoucherSuccess(response.data));
    } else {
      yield put(actions.fetchAdminVoucherFail(response.data));
    }
  } catch (error) {
    yield put(actions.fetchAdminVoucherFail(error));
  }
}
