import * as types from '../actionTypes/ActionTypes';

export const adminVoucherConnect = payload => ({
  type: types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_REQUEST,
  payload
});

export const adminVoucherConnectSuccess = payload => ({
  type: types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_SUCCESS,
  payload
});

export const adminVoucherConnectFail = payload => ({
  type: types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_FAIL,
  payload
});
