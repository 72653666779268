
export const COMPANY_USERS_ASSIGN_PAYMENT = {
  GET_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST: 'GET_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST',
  GET_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS: 'GET_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS',
  GET_COMPANY_USERS_ASSIGN_PAYMENT_FAIL: 'GET_COMPANY_USERS_ASSIGN_PAYMENT_FAIL',
  ADD_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST: 'ADD_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST',
  ADD_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS: 'ADD_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS',
  ADD_COMPANY_USERS_ASSIGN_PAYMENT_FAIL: 'ADD_COMPANY_USERS_ASSIGN_PAYMENT_FAIL',
  UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST: 'UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST',
  UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS: 'UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS',
  UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_FAIL: 'UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_FAIL',
  GET_BANKS_VERIFIED_PAYMENT_REQUREST: 'GET_BANKS_VERIFIED_PAYMENT_REQUREST',
  GET_BANKS_VERIFIED_PAYMENT_SUCCESS: 'GET_BANKS_VERIFIED_PAYMENT_SUCCESS',
  GET_BANKS_VERIFIED_PAYMENT_FAIL: 'GET_BANKS_VERIFIED_PAYMENT_FAIL',
  ADD_UPDATE_BANKS_VERIFIED_PAYMENT_REQUREST: 'ADD_UPDATE_BANKS_VERIFIED_PAYMENT_REQUREST',
  ADD_UPDATE_BANKS_VERIFIED_PAYMENT_SUCCESS: 'ADD_UPDATE_BANKS_VERIFIED_PAYMENT_SUCCESS',
  ADD_UPDATE_BANKS_VERIFIED_PAYMENT_FAIL: 'ADD_UPDATE_BANKS_VERIFIED_PAYMENT_FAIL',
  FETCH_ASSIGN_USERS_BY_COMPANY: 'FETCH_ASSIGN_USERS_BY_COMPANY',
  FETCH_ASSIGN_USERS_BY_COMPANY_SUCCESS: 'FETCH_ASSIGN_USERS_BY_COMPANY_SUCCESS',
  FETCH_ASSIGN_USERS_BY_COMPANY_FAIL: 'FETCH_ASSIGN_USERS_BY_COMPANY_FAIL'
};
