import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';

export default {
  getUserByToken: async (token) => {
    try {
      const url = api.account.getUserByToken.replace(':token', token);
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  checkLogin: async (params) => {
    try {
      const url = api.account.checkLogin;
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      throw error;
    }
  },
  changeLanguage: async params => {
    try {
      const url = api.account.changeLanguage;
      const data = await BaseServices.postOrytonDataToken(url, params);
      return await data.json();
    } catch (error) {
      throw error;
    }
  },
  logout: async (params) => {
    try {
      const url = api.account.logout;
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      throw error;
    }
  },
};
