export const VOUCHER_PAYMENT = {
  SET_PAYMENT_LIST_TABLE_CHECKED: 'SET_PAYMENT_LIST_TABLE_CHECKED',
  SET_CURRENT_VOUCHER_PAYMENT: 'SET_CURRENT_VOUCHER_PAYMENT',
  SET_OPEN_FULLSCREEN: 'SET_OPEN_FULLSCREEN',
  SET_FILTER: 'SET_FILTER',
  SET_SHOW_PAYMENT_OPTIONS: 'SET_SHOW_PAYMENT_OPTIONS',

  ADD_TO_PAYMENT_LIST_REQUEST: 'ADD_TO_PAYMENT_LIST_REQUEST',
  ADD_TO_PAYMENT_LIST_SUCCESS: 'ADD_TO_PAYMENT_LIST_SUCCESS',
  ADD_TO_PAYMENT_LIST_FAIL: 'ADD_TO_PAYMENT_LIST_FAIL',

  FETCH_VOUCHER_PAYMENT_REQUEST: 'FETCH_VOUCHER_PAYMENT_REQUEST',
  FETCH_VOUCHER_PAYMENT_SUCCESS: 'FETCH_VOUCHER_PAYMENT_SUCCESS',
  FETCH_VOUCHER_PAYMENT_FAIL: 'FETCH_VOUCHER_PAYMENT_FAIL',

  PAY_TO_BANK_REQUEST: 'PAY_TO_BANK_REQUEST',
  PAY_TO_BANK_SUCCESS: 'PAY_TO_BANK_SUCCESS',
  PAY_TO_BANK_FAIL: 'PAY_TO_BANK_FAIL',

  SAVE_VOUCHER_PAYMENT_REQUEST: 'SAVE_VOUCHER_PAYMENT_REQUEST',
  SAVE_VOUCHER_PAYMENT_SUCCESS: 'SAVE_VOUCHER_PAYMENT_SUCCESS',
  SAVE_VOUCHER_PAYMENT_FAIL: 'SAVE_VOUCHER_PAYMENT_FAIL',

  GET_BY_VOUCHER_ID_REQUEST: 'GET_BY_VOUCHER_ID_REQUEST',
  GET_BY_VOUCHER_ID_SUCCESS: 'GET_BY_VOUCHER_ID_SUCCESS',
  GET_BY_VOUCHER_ID_FAIL: 'GET_BY_VOUCHER_ID_FAIL',

  UPDATE_VOUCHER_PAYMENTS_STATUS: 'UPDATE_VOUCHER_PAYMENTS_STATUS',
  UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS: 'UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS',
  UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL: 'UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL',

  GET_BY_PAYMENTS_UUID_REQUEST: 'GET_BY_PAYMENT_UUID_REQUEST',
  GET_BY_PAYMENTS_UUID_SUCCESS: 'GET_BY_PAYMENTS_UUID_SUCCESS',
  GET_BY_PAYMENTS_UUID_FAIL: 'GET_BY_PAYMENTS_UUID_FAIL',

  GET_VOUCHER_PAYMENT_STATISTIC: 'GET_VOUCHER_PAYMENT_STATISTIC',
  GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS: 'GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS',
  GET_VOUCHER_PAYMENT_STATISTIC_FAIL: 'GET_VOUCHER_PAYMENT_STATISTIC_FAIL',

  ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST: 'ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST',
  ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS: 'ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS',
  ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL: 'ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL',

  GET_PAYMENT_ACTIONS: 'GET_PAYMENT_ACTIONS',
  GET_PAYMENT_ACTIONS_SUCCESS: 'GET_PAYMENT_ACTIONS_SUCCESS',
  GET_PAYMENT_ACTIONS_FAIL: 'GET_PAYMENT_ACTIONS_FAIL'
};
