import CryptoJS from 'crypto-js';
import * as ConfigJSON from '../configs/server.configs';
import { authorization, webConfig } from '../configs/system.configs';
import * as Utils from '../utils/Utils';
import BaseServices from './baseServices';
import accountServices from './accountServices';

const config = ConfigJSON[process.env.NODE_ENV];

export default {
  logout: async () => {
    const _user = Utils.getUserLogin();
    if (_user?.AccessToken) {
      const response = await accountServices.logout({ token: _user.AccessToken });
    }
    localStorage.removeItem(authorization);
    localStorage.removeItem('_user');
    window.location.href = webConfig.logoutUrl;
  },
  getAuthenticateToken: () => {
    try {
      const _user = Utils.getUserLogin();
      // for webview app
      if (!_user) {
        const tokenEncrypt = localStorage.getItem(authorization);
        if (!tokenEncrypt) {
          return null;
        }
        return CryptoJS.AES.decrypt(tokenEncrypt.toString(), config.tokenSecret).toString(CryptoJS.enc.Utf8);
      }
      return _user?.AccessToken;
    } catch (error) { }
  },
  getBankToken: () => {
    return 'Oryton_BankService_1234!@';
  },
  getPsd2Token: () => {
    return 'Oryton_PSD2_1234!@';
  },
  getEHFToken: () => {
    return 'Oryton_EHF_J9qE%h';
  },
  getAuthenticate: () => {
    try {
      const tokenEncrypt = localStorage.getItem(authorization);
      if (tokenEncrypt === null || tokenEncrypt === undefined) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(tokenEncrypt.toString(), config.tokenSecret);
      const token = bytes.toString(CryptoJS.enc.Utf8);
      return token;
    } catch (e) {
      return null;
    }
  },
  refreshToken: async (refreshToken) => {
    try {
      const url = webConfig.refreshToken;
      const data = await BaseServices.postData({
        url,
        data: { refreshToken },
      });
      return await data.json();
    } catch (error) {
      return { isSuccess: false, message: error.message };
    }
  },
};
