import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import * as Utils from '../../utils/Utils';
import rgbPaymentsAuthsService from '../../services/3rd/rgb/rgbPaymentsAuths.service';
import rgbPaymentsBasketsService from '../../services/3rd/rgb/rgbPaymentsBaskets.service';

class SignCancel extends Component {
  componentDidMount() {
    const { history } = this.props;
    const action = Utils.getParameterByName('action');
    switch (action) {
      case 'signant-auth':
        this.handleSignantAuthProcess();
        break;
      case 'payment-auth':
        this.updateVoucherPayments();
        break;
      default:
        history.push('/404');
        break;
    }
  }

  handleSignantAuthProcess = async () => {
    return false;
  }

  getBasketUUID = async () => {
    // get paymentUUID and update status to CANCELED
    const basketUUID = Utils.getParameterByName('basketUUID');
    if (basketUUID) {
      // check basketUUID exist
      const basketResponse = await rgbPaymentsBasketsService.getByBasketUUID({ basketUUID });
      const { success, data } = basketResponse;
      if (!success) return null;
      if (success) if (data.length === 0) return null;
      return basketUUID;
    }
    return null;
  }

  // update voucher payment to cancel
  updateVoucherPayments = async () => {
    const { history } = this.props;
    // get paymentUUID and update status to CANCELED
    const basketUUID = await this.getBasketUUID();
    if (basketUUID) {
      // update voucher payment to cancel status
      // await VoucherPaymentsBasketServices.updateVoucherPaymentsBasket({ basketUUID, status: 2 });
      // update signant auth to cancel
      const authParam = {
        basketUUID,
        statusText: 'CANCELED',
        statusID: 4001
      };
      // update payment auths status
      const res = await rgbPaymentsAuthsService.updateByBasketUUID(authParam);
      const { success: successRes } = res;
      if (successRes) this._autoClickCloseModal();
      return false;
    }
    return history.push('/404');
  }

  _autoClickCloseModal() {
    setTimeout(() => {
      const { parent: { document } } = window;
      const btnClose = document.querySelectorAll('.modal-close-icon');
      if (btnClose && btnClose[0]) btnClose[0].click();
    }, 3000);
  }

  render() {
    const { t } = this.props;
    return (
      <div className='row'>
        <div className='col s12'>
          <div className='card valign-wrapper'>
            <div className="card-content valign center-block">
              {t('text-cancel')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const signantAuthRequestData = state.signant.signantAuthRequestData;
  return {
    signantAuthRequestData
  };
};

export default withRouter(connect(mapStateToProps)(withNamespaces('signCancel')(SignCancel)));
