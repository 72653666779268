import * as types from '../actionTypes/ActionTypes';

const STEPS_ENUM = {
  ONBOARDING: 0,
  CONSENT: 1,
  PAYMENT: 2,
};

// INIT
const initialState = {
  currentStep: STEPS_ENUM.ONBOARDING,
};

const settingZtl = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTING_ZTL.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.SETTING_ZTL.SETTING_ONBOARDING:
      return {
        ...state,
        onboarding: action.payload,
      };
    default:
      return state;
  }
};

export default settingZtl;
