import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import GlobalRuleServices from '../../../../services/globalRuleServices';

export default function* fetchAllGlobalRuleSaga() {
  yield takeEvery(actionTypes.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_REQUEST, fetchAllGlobalRule);
}

export function* fetchAllGlobalRule(action) {
  try {
    const response = yield call(GlobalRuleServices.fetchAll, action.payload);
    if (response.success) {
      yield put(actions.fetchAllGlobalRuleSuccess(response.data));
    } else {
      yield put(actions.fetchAllGlobalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.fetchAllGlobalRuleFail(error));
  }
}
