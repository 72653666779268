import BaseServices from './baseServices';
import { APIError } from '../constants/errorCode/system';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  add: async params => {
    try {
      const url = api.companyPaymentPackages.add;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const { id } = params;
      const url = api.companyPaymentPackages.update.replace(':id', id);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getByCompanyId: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = api.companyPaymentPackages.getByCompanyId + '?' + query;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
