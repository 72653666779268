import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyServices from '../../../services/companyServices';

export default function* updateCompanyServiceSaga() {
  yield takeEvery(actionTypes.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_REQUEST, updateCompanyServices);
}

export function* updateCompanyServices(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(companyServices.updatePaymentStatus, { companyId });
    const { success, data } = response;
    if (success) {
      yield put(actions.updateCompanyServiceSuccess(data));
      yield put(actions.fetchAllCompanyService({ companyId }));
    } else {
      yield put(actions.updateCompanyServiceFail(response));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.updateCompanyServiceFail(error));
  }
}
