import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ztlServices from '../../../../services/ztlServices';
import * as Utils from '../../../../utils/Utils';
import Loading from '../../../UI/Loading';
import { useStyles } from './styles';
import { RoutesUrl } from '../../../../constants/routes.constants';

const STATUS_SIGNANT = {
  WAITING: 2,
  REJECTED: 0,
  ACCEPTED: 1,
};

const ConsentSignant = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [statusSca, setStatusSca] = useState(STATUS_SIGNANT.WAITING);
  const [accountData, setAccountData] = useState([]);
  const classes = useStyles();

  const handleSCACallback = async () => {
    setLoading(true);
    try {
      const { sca = '', naasConsentReference, instructionId } = Utils.getJsonFromUrl();
      if (sca && instructionId) {
        const response = await ztlServices.scaSuccess({
          consentReference: naasConsentReference,
          instructionId,
          scaStatus: sca,
        });
        const { success, data } = response;
        if (success) {
          setAccountData(data);
        }
        if (success && sca === 'success') {
          setStatusSca(STATUS_SIGNANT.ACCEPTED);
        } else {
          setStatusSca(STATUS_SIGNANT.REJECTED);
        }
      } else {
        setStatusSca(STATUS_SIGNANT.REJECTED);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
      autoClickCloseModal();
      window?.ReactNativeWebView?.postMessage('success');
    }
  };

  const autoClickCloseModal = () => {
    setTimeLeft(3);
    setTimeout(() => {
      window.close();
    }, 3000);
  };

  const renderSCAByStatus = () => {
    switch (statusSca) {
      case STATUS_SIGNANT.ACCEPTED:
        return <span className={classes.accepted}>{t('ztl:consent:sca:accepted')}</span>;
      case STATUS_SIGNANT.REJECTED:
        return <span className={classes.rejected}>{t('ztl:consent:sca:rejected')}</span>;
      default:
        return '';
    }
  };

  useEffect(() => {
    handleSCACallback();
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
      const localStorageKey = 'consent-company-alias';
      const consentCompanyAlias = Utils.getLocalStorage(localStorageKey);
      if (consentCompanyAlias) {
        const redirectUrl = window.location.origin + RoutesUrl.Setting.replace(':company', consentCompanyAlias);
        setTimeout(() => {
          Utils.deleteLocalStorage(localStorageKey);
        }, 300);
        window.location.href = redirectUrl;
      }
    }
    // exit early when we reach 0
    if (!timeLeft) {
      return;
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const _renderAccountData = () => {
    const text = t('ztl:consent:statuses:accepted');
    if (accountData?.length > 0) {
      return accountData.map(account => {
        const accountInfo = account?.iban || account?.bban;
        return (
          <div key={account?.id} className='center border-bottom-silver'>
            <span className='mr-3'>{accountInfo}</span>
            <span className='blue-text'>{text}</span>
          </div>
        )
      });
    }
    return null;
  }

  return (
    <div>
      <div className="row" style={{
        height: '100vh',
        display: 'flex',
        width: '30%'
      }}>
        <div className="col s12" style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Loading loading={loading || statusSca === STATUS_SIGNANT.WAITING} />
          {renderSCAByStatus()}
          {timeLeft
            ? <div className='red-text center'>( {timeLeft} )</div>
            : ''}
          {_renderAccountData()}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces(['ztl'])(ConsentSignant);
