import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as Actions from '../../../actions/3rd/stripe.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import stripeServices from '../../../../services/3rd/stripe';

export default function* createPaymentIntantSaga() {
  yield takeEvery(actionTypes.STRIPE.CREATE_PAYMENT_INTENT, createPaymentIntant);
}

export function* createPaymentIntant(action) {
  try {
    const response = yield call(stripeServices.createPaymentIntent, action.payload);
    const { data, success } = response;
    if (success) {
      yield put(Actions.stripe.createPaymentIntentSuccess(data));
    } else {
      const { message } = response;
      yield put(Actions.stripe.createPaymentIntentFail(message));
    }
  } catch (error) {
    yield put(Actions.stripe.createPaymentIntentFail(error));
  }
}
