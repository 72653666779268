import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import voucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* createUnpaidEhfVouchersToPaymentListInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST, addUnpaidEhfVouchersToPaymentList);
}

export function* addUnpaidEhfVouchersToPaymentList(action) {
  try {
    const response = yield call(voucherPaymentServices.addUnpaidEhfVouchersToPaymentList, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.addUnpaidEhfVouchersToPaymentListSuccessInternal(data));
      // fetch voucher payment list data after crawl
      yield put(actions.fetchVoucherPaymentInternal());
    } else {
      yield put(actions.addUnpaidEhfVouchersToPaymentListFailInternal(response.data));
    }
  } catch (error) {
    yield put(actions.addUnpaidEhfVouchersToPaymentListFailInternal(error));
  }
}
