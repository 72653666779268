import * as types from '../actionTypes/ActionTypes';

export const setShowPaymentOptionsInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SET_SHOW_PAYMENT_OPTIONS,
  payload,
});

export const setPaymentListTableCheckedInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SET_PAYMENT_LIST_TABLE_CHECKED,
  payload,
});

export const setOpenFullScreenInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SET_OPEN_FULLSCREEN,
  payload,
});

export const setFilterInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SET_FILTER,
  payload,
});

export const addToPaymentListInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_TO_PAYMENT_LIST_REQUEST,
  payload,
});
export const addToPaymentListSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_TO_PAYMENT_LIST_SUCCESS,
  payload,
});
export const addToPaymentListFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_TO_PAYMENT_LIST_FAIL,
  payload,
});

export const fetchVoucherPaymentInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_FETCH_VOUCHER_PAYMENT_REQUEST,
  payload,
});
export const fetchVoucherPaymentSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_FETCH_VOUCHER_PAYMENT_SUCCESS,
  payload,
});
export const fetchVoucherPaymentFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_FETCH_VOUCHER_PAYMENT_FAIL,
  payload,
});

export const payToBankInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_PAY_TO_BANK_REQUEST,
  payload,
});
export const payToBankSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_PAY_TO_BANK_SUCCESS,
  payload,
});
export const payToBankFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_PAY_TO_BANK_FAIL,
  payload,
});

export const saveVoucherPaymentInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SAVE_VOUCHER_PAYMENT_REQUEST,
  payload,
});
export const saveVoucherPaymentSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SAVE_VOUCHER_PAYMENT_SUCCESS,
  payload,
});
export const saveVoucherPaymentFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SAVE_VOUCHER_PAYMENT_FAIL,
  payload,
});

export const getByVoucherIdInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_VOUCHER_ID_REQUEST,
  payload,
});
export const getByVoucherIdSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_VOUCHER_ID_SUCCESS,
  payload,
});
export const getByVoucherIdFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_VOUCHER_ID_FAIL,
  payload,
});

export const setCurrentVoucherPaymentInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_SET_CURRENT_VOUCHER_PAYMENT,
  payload,
});

export const updateVoucherPaymentsStatusInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS,
  payload,
});

export const updateVoucherPaymentsStatusSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS,
  payload,
});

export const updateVoucherPaymentsStatusFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL,
  payload,
});

export const getByPaymentsUUIDInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_PAYMENTS_UUID_REQUEST,
  payload,
});
export const getByPaymentsUUIDSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_PAYMENTS_UUID_SUCCESS,
  payload,
});
export const getByPaymentsUUIDdFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_PAYMENTS_UUID_FAIL,
  payload,
});

export const getVoucherPaymentStatisticInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC,
  payload,
});
export const getVoucherPaymentStatisticSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS,
  payload,
});
export const getVoucherPaymentStatisticFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_FAIL,
  payload,
});

export const addUnpaidEhfVouchersToPaymentListInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST,
  payload,
});
export const addUnpaidEhfVouchersToPaymentListSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS,
  payload,
});
export const addUnpaidEhfVouchersToPaymentListFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL,
  payload,
});

export const getPaymentActionsInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_PAYMENT_ACTIONS,
  payload,
});
export const getPaymentActionsSuccessInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_PAYMENT_ACTIONS_SUCCESS,
  payload,
});
export const getPaymentActionsFailInternal = (payload) => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_PAYMENT_ACTIONS_FAIL,
  payload,
});

// action loading delete payment internal

export const deletePaymentInternalRequest = () => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_DELETE_RECORD_PAYMENT_REQUEST,
});

export const deletePaymentInternalSuccess = () => ({
  type: types.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_TO_PAYMENT_LIST_SUCCESS,
});
