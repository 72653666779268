import * as types from '../../actionTypes/ActionTypes';
// INIT
const initialState = {
  isLoading: false,
  authorizeData: null,
  errors: {
    authorize: null
  }
};

const psd2Sp1 = (state = initialState, action) => {
  switch (action.type) {
    case types.PSD2_SP1.CRS_AUTHORIZE:
      return {
        ...state,
        isLoading: true,
        errors: {
          authorize: null
        }
      };
    case types.PSD2_SP1.CRS_AUTHORIZE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authorizeData: [...action.payload]
      };
    case types.PSD2_SP1.CRS_AUTHORIZE_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: {
          authorize: action.payload
        }
      };
    default:
      return state;
  }
};

export default psd2Sp1;
