import * as types from '../actionTypes/ActionTypes';

export const setVoucherListData = (payload) => ({
  type: types.VOUCHER.SET_VOUCHER_LIST_DATA,
  payload,
});
export const setCurrentVoucher = (payload) => ({
  type: types.VOUCHER.SET_CURRENT_VOUCHER,
  payload,
});
export const setFilterVoucher = (payload) => ({
  type: types.VOUCHER.SET_FILTER_VOUCHER,
  payload,
});
export const setValueRecordTransaction = (payload) => ({
  type: types.VOUCHER.SET_VALUE_RECORD_TRANSACTION,
  payload,
});
export const setOpenWindowVoucher = (payload) => ({
  type: types.VOUCHER.SET_OPEN_WINDOW_VOUCHER,
  payload,
});
export const resetVoucherFilter = (payload) => ({
  type: types.VOUCHER.RESET_FILTER,
  payload,
});
export const removeRowVoucher = (payload) => ({
  type: types.VOUCHER.REMOVE_ROW_VOUCHER,
  payload,
});

export const fetchVoucherData = (payload) => ({
  type: types.VOUCHER.FETCH_VOUCHER_DATA_REQUEST,
  payload,
});
export const fetchVoucherDataSuccess = (payload) => ({
  type: types.VOUCHER.FETCH_VOUCHER_DATA_SUCCESS,
  payload,
});
export const fetchVoucherDataFail = (payload) => ({
  type: types.VOUCHER.FETCH_VOUCHER_DATA_FAIL,
  payload,
});

export const getVoucherBalance = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCE_REQUEST,
  payload,
});
export const getVoucherBalanceSuccess = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCE_SUCCESS,
  payload,
});
export const getVoucherBalanceFail = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCE_FAIL,
  payload,
});

export const getMissingSummary = (payload) => ({
  type: types.VOUCHER.GET_MISSING_SUMMARY_REQUEST,
  payload,
});
export const getMissingSummarySuccess = (payload) => ({
  type: types.VOUCHER.GET_MISSING_SUMMARY_SUCCESS,
  payload,
});
export const getMissingSummaryFail = (payload) => ({
  type: types.VOUCHER.GET_MISSING_SUMMARY_FAIL,
  payload,
});

export const fetchAdminVoucher = (payload) => ({
  type: types.VOUCHER.FETCH_ADMIN_VOUCHER_REQUEST,
  payload,
});
export const fetchAdminVoucherSuccess = (payload) => ({
  type: types.VOUCHER.FETCH_ADMIN_VOUCHER_SUCCESS,
  payload,
});
export const fetchAdminVoucherFail = (payload) => ({
  type: types.VOUCHER.FETCH_VOUCHER_DATA_FAIL,
  payload,
});

export const getVoucherDetail = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_DETAIL_REQUEST,
  payload,
});
export const getVoucherDetailSuccess = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_DETAIL_SUCCESS,
  payload,
});
export const getVoucherDetailFail = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_DETAIL_FAIL,
  payload,
});

export const getVoucherSuggestInfo = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_SUGGEST_INFO,
  payload,
});
export const getVoucherSuggestInfoSuccess = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_SUGGEST_INFO_SUCCESS,
  payload,
});
export const getVoucherSuggestInfoFail = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_SUGGEST_INFO_FAIL,
  payload,
});

export const setVoucherLabels = (payload) => ({
  type: types.VOUCHER.SET_VOUCHER_LABELS,
  payload,
});

export const getVoucherBalancesV2 = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCES_V2_REQUEST,
  payload,
});
export const getVoucherBalancesV2Success = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCES_V2_SUCCESS,
  payload,
});
export const getVoucherBalancesV2Fail = (payload) => ({
  type: types.VOUCHER.GET_VOUCHER_BALANCES_V2_FAIL,
  payload,
});
