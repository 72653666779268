import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isGetting: false,
    isFetching: false,
    isUpdating: false,
    isGettingBanksVerifiedPayment: false,
    isFetchingAssignUserByCompany: false
  },
  data: null,
  assignPaymentUsers: [],
  banksVerifiedPayment: [],
  assignUsersByCompany: null
};

const companyUsersAssignPayment = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST:
      return {
        ...state,
        loading: {
          isGetting: true
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS:
      return {
        ...state,
        assignPaymentUsers: action.payload,
        loading: {
          isGetting: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_FAIL:
      return {
        ...state,
        loading: {
          isGetting: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST:
      return {
        ...state,
        loading: {
          isFetching: true
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_FAIL:
      return {
        ...state,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST:
      return {
        ...state,
        loading: {
          isUpdating: true
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: {
          isUpdating: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_FAIL:
      return {
        ...state,
        loading: {
          isUpdating: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_REQUREST:
      return {
        ...state,
        loading: {
          isGettingBanksVerifiedPayment: true
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_SUCCESS:
      return {
        ...state,
        banksVerifiedPayment: action.payload,
        loading: {
          isGettingBanksVerifiedPayment: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_FAIL:
      return {
        ...state,
        loading: {
          isGettingBanksVerifiedPayment: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY:
      return {
        ...state,
        loading: {
          isFetchingAssignUserByCompany: true
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        assignUsersByCompany: action.payload,
        loading: {
          isFetchingAssignUserByCompany: false
        }
      };
    case types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY_FAIL:
      return {
        ...state,
        loading: {
          isFetchingAssignUserByCompany: false
        }
      };
    default:
      return state;
  }
};

export default companyUsersAssignPayment;
