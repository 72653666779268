import * as actionTypes from '../../actionTypes/ActionTypes';

export default {
  initAuth: payload => {
    return {
      type: actionTypes.SIGNANT.INIT_AUTH,
      payload
    };
  },
  initAuthSuccess: payload => {
    return {
      type: actionTypes.SIGNANT.INIT_AUTH_SUCCESS,
      payload
    };
  },
  initAuthFail: payload => {
    return {
      type: actionTypes.SIGNANT.INIT_AUTH_FAIL,
      payload
    };
  },
  getAuthStatus: payload => {
    return {
      type: actionTypes.SIGNANT.GET_AUTH_STATUS,
      payload
    };
  },
  getAuthStatusSuccess: payload => {
    return {
      type: actionTypes.SIGNANT.GET_AUTH_SUCCESS,
      payload
    };
  },
  getAuthStatusFail: payload => {
    return {
      type: actionTypes.SIGNANT.GET_AUTH_FAIL,
      payload
    };
  },
  signantAuthRequest: payload => {
    return {
      type: actionTypes.SIGNANT.AUTH_REQUEST,
      payload
    };
  },
  signantAuthSuccess: payload => {
    return {
      type: actionTypes.SIGNANT.AUTH_SUCCESS,
      payload
    };
  },
  signantAuthFail: payload => {
    return {
      type: actionTypes.SIGNANT.AUTH_FAIL,
      payload
    };
  },
  handleAuthorizeCompanyBanks: payload => {
    return {
      type: actionTypes.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS,
      payload
    };
  },
  handleAuthorizeCompanyBanksSuccess: payload => {
    return {
      type: actionTypes.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS_SUCCESS,
      payload
    };
  },
  handleAuthorizeCompanyBanksFail: payload => {
    return {
      type: actionTypes.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS_FAIL,
      payload
    };
  }
};
