import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import jsonp from '../utils/jsonp';
import BaseServices from './baseServices';

export default {
  checkOrganisation: async (company) => {
    try {
      const orgNumber = company.OrganisationNumber;
      const url = `https://hotell.difi.no/api/jsonp/difi/elma/participants?query=${orgNumber}`;
      return new Promise((resolve) => {
        jsonp(url, (response) => {
          if (response.page) resolve(response);
        });
      });
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  buyEHF: async (params) => {
    try {
      const url = api.service.ehf;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  checkInformationEHF: async (orgnr) => {
    try {
      const url = api.service.infor_ehf + orgnr;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result?.data;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchEHF: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.service.ehf}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  deleteEHF: async (params) => {
    try {
      const url = api.service.deleteEhf;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchEmailForward: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.oryton.fetchEmailForward}?${query}`;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addEmailForward: async (params) => {
    try {
      const url = api.oryton.addEmailForward;
      const data = await BaseServices.postOrytonDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  removeEmailForward: async (params) => {
    try {
      const url = api.oryton.removeEmailForward.replace(':uuid', params.uuid);
      const data = await BaseServices.deleteOrytonDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  buyAlert: async (params) => {
    try {
      const url = api.service.alert;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getAlert: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.service.alert}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getOwner: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.service.getOwner}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchCompanyUsersByCompanyId: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.ajaxServices.fetchCompanyUsers}?${query}`;
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addCompanyUsers: async (params) => {
    try {
      const url = api.service.addCompanyUsers;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  add: async (params) => {
    try {
      const url = api.companyService.add;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  update: async (params) => {
    try {
      const { id } = params;
      const url = api.companyService.update.replace(':id', id);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = api.companyService.fetchAll + '?' + query;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getPaymentStatus: async ({ companyId }) => {
    try {
      const url = api.companyService.paymentStatusV2.replace(':companyId', companyId);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  updatePaymentStatus: async ({ companyId }) => {
    try {
      const url = api.companyService.paymentStatusV2.replace(':companyId', companyId);
      const data = await BaseServices.putDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompanyDivisionByOrg: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.service.getCompanyDivisionByOrg}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addCompanyDivisionByOrg: async (params) => {
    try {
      const url = api.service.addCompanyDivisionByOrg;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  updateCompanyDivisionByOrg: async (params) => {
    try {
      const url = api.service.updateCompanyDivisionByOrg;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  deleteCompanyDivision: async (params) => {
    try {
      const url = api.service.deleteCompanyDivision;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getEHFAccessPointUrl: async (orgnr) => {
    try {
      const query = httpHelper.serialize({ orgnr });
      const url = `${api.service.getEHFAccessPointUrl}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getActiveCompanyServices: async (companyId) => {
    try {
      const url = api.companyService.listActiveCompanyServices.replace(':companyId', companyId);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  buyZtlService: async (params) => {
    try {
      const url = api.companyService.buyZtlService.replace(':companyId', params?.companyId);
      const data = await BaseServices.postDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addRgbIntegration: async (params) => {
    try {
      const url = api.company.addRgbIntegration;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  checkRgbIntegration: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = api.company.getRgbIntegration + `?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompany: async companyId => {
    try {
      const url = api.oryton.getCompany.replace(':id', companyId);
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
