export const COLOR = {
  white: '#ffffff',
  black: '#000000',
  darker: '#495057',
  lighter: '#e9ecef',
  grayBorder: '#ced4da',
  graye4e4e4: '#e4e4e4',
  purple: '#7b1fa2',
  pink: '#ff4bac',
  seance: '#8e24aa',
  orange: 'orange',
  apple: '#63ba3c',
  feijoa: '#a5dc86',
  malachite: '#12ad2b',
  supernova: '#ffce00',
  flushMahogany: '#ca3745',
  pomegranate: '#f44336',
  lochmara: '#0081c6',
  dustyGray: '#999',
  mineShaft: '#3F3F3F',
  jungleGreen: '#26A69A',
  alto: '#D9D9D9',
  sunglo: '#e2777a',
  eastSide: '#cc99cd',
  scorpion: '#575757',
  spindle: '#b4dbed',
  malibuN: '#8CD4F5',
  pictonBlue: '#5dc2f1',
  manhattan: '#F8BB86',
  emperor: '#555555',
  gallery: '#EFEFEF',
  malibu: '#6DB8FF',
  froly: '#F27474',
  feijoa: '#A5DC86',
  dodgerBlue: '#2196f3',
  iron: '#d9dde0',
  mercury: '#e5e5e5',
  aquaHaze: '#eff3f6',
  silverRust: '#cfc7c7',
  wildSand: '#f5f5f5',
  cerulean: '#03a9f4',
  aquaHazeGray: '#f5f8f9',
  whiteIce: '#d6eff7',
  grayBtn: '#838383',
  cobalt: '#0747A6',
  anakiwa: '#B3D4FF',
  athensGray: '#f5f6f8',
  matisse: '#216ba5',
  fiord: '#42526E',
  athensGray: '#F4F5F7',
  // emperor: '#585050'
};

export const BREAK_POINT = {
  SMALL: '@media screen and (max-width: 768px)',
  MEDIUM: '@media screen and (min-width: 768px)',
  LARGE: '@media screen and (min-width: 992px)',
  LARGE_M: '@media screen and (max-width: 992px)',
  X_LARGE: '@media screen and (min-width: 1200px)',
  XX_LARGE: '@media screen and (max-width: 1400px)',
};
