import AuthenticationServices from '../../authenticationServices';
// import { webConfig } from '../configs/system.configs';
require('es6-promise').polyfill();
require('isomorphic-fetch');

export default {
  postDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  }
};
