
export const COMPANY_BANK = {
  FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID: 'FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID',
  FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_SUCCESS: 'FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_SUCCESS',
  FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_FAIL: 'FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_FAIL',
  GET_BANK_BALANCE_BY_ACCOUNT_NUMBER: 'GET_BANK_BALANCE_BY_ACCOUNT_NUMBER',
  GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_SUCCESS: 'GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_SUCCESS',
  GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_FAIL: 'GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_FAIL',
  SET_DEFAULT_ACCOUNT_NUMBER: 'SET_DEFAULT_ACCOUNT_NUMBER',
  SET_DEFAULT_ACCOUNT_NUMBER_SUCCESS: 'SET_DEFAULT_ACCOUNT_NUMBER_SUCCESS',
  SET_DEFAULT_ACCOUNT_NUMBER_FAIL: 'SET_DEFAULT_ACCOUNT_NUMBER_FAIL',
  DELETE_COMPANY_BANK: 'DELETE_COMPANY_BANK',
  DELETE_COMPANY_BANK_SUCCESS: 'DELETE_COMPANY_BANK_SUCCESS',
  DELETE_COMPANY_BANK_FAIL: 'DELETE_COMPANY_BANK_FAIL',
  GET_DEFAULT_ACCOUNT_NUMBER: 'GET_DEFAULT_ACCOUNT_NUMBER',
  GET_DEFAULT_ACCOUNT_NUMBER_SUCCESS: 'GET_DEFAULT_ACCOUNT_NUMBER_SUCCESS',
  GET_DEFAULT_ACCOUNT_NUMBER_FAIL: 'GET_DEFAULT_ACCOUNT_NUMBER_FAIL'
};
