import * as types from '../actionTypes/ActionTypes';

export const notification = (data) => ({ type: types.SYSTEM.NOTIFICATION, payload: data });

export const changeDataNotification = (payload) => ({
  type: types.NOTIFICATION.GET_NOTIFICATION_SUCCESS,
  payload,
});

export const fetchNotificationRequest = () => ({
  type: types.NOTIFICATION.GET_NOTIFICATION_REQUEST,
});

export const changeIsUnRead = (payload) => ({
  type: types.NOTIFICATION.CHANGE_IS_READ,
  payload,
});

export const setOpenNotification = (payload) => ({
  type: types.NOTIFICATION.SET_OPEN_NOTIFICATION,
  payload,
});
export const setZtlNotifications = payload => ({
  type: types.NOTIFICATION.SET_ZTL_NOTIFICATION,
  payload,
})
