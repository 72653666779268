import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import * as selectors from '../../../selectors';
import voucherPaymentServices from '../../../services/voucherPaymentServices';
import timeHelper from '../../../helpers/timeHelper';
import internalTransferServices from '../../../services/internalTransferServices';

export default function* fetchVoucherPaymentInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_FETCH_VOUCHER_PAYMENT_REQUEST, fetchVoucherPayment);
}

export function* fetchVoucherPayment(action) {
  try {
    // get company
    const company = yield select(selectors.company);
    const { CompanyId: companyId } = company;
    if (companyId) {
      const filter = yield select(selectors.voucherPaymentInternalFilter);
      const cloneFilter = { ...filter };
      const statisticParams = {
        companyId,
        keyword: cloneFilter.keyword,
        template: cloneFilter.template || '',
      };
      const response = yield call(voucherPaymentServices.getStatisticInternal, statisticParams);
      const { success, data } = response;
      if (success) {
        const found = data.find((item) => item.total >= 0) || {};
        const { status } = found;
        const statuses = [status + '' || '0'];
        cloneFilter.statuses = statuses;
        // set filter
        const { statuses: filterStatuses } = filter;
        if (filterStatuses !== statuses) {
          // check filterStatus have data
          const foundStatuses = data.find((item) => item.status === parseInt(filterStatuses) && item.total > 0);
          // if exist keep status else auto get from statisic list
          filter.statuses = foundStatuses ? filterStatuses : statuses;
        }
        yield put(actions.getVoucherPaymentStatisticSuccessInternal(data));
      }
      const params = { ...filter, companyId };
      if (cloneFilter.from && cloneFilter.to) {
        const from = timeHelper.formatUtcDate(cloneFilter.from, 'YYYY-MM-DD');
        const to = timeHelper.formatUtcDate(cloneFilter.to, 'YYYY-MM-DD');
        params.from = from;
        params.to = to;
      } else {
        delete params.from;
        delete params.to;
      }
      delete params.isShowDate;
      const responseData = yield call(internalTransferServices.getListInternalTransfer, params);
      const { success: successRes, data: dataRes } = responseData;
      if (successRes) {
        yield put(actions.fetchVoucherPaymentSuccessInternal(dataRes));
      } else {
        yield put(actions.fetchVoucherPaymentFailInternal(responseData.message));
      }
    }
    yield put(actions.fetchVoucherPaymentFailInternal('Invalid params'));
  } catch (error) {
    yield put(actions.fetchVoucherPaymentFailInternal(error));
  }
}
