import taskServices from '../../services/taskServices';

export default class MyUploadAdapter {
  constructor(loader, setIsUpload) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.setIsUpload = setIsUpload;
  }

  // Starts the upload process.
  upload() {
    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file.then(async (file) => {
      this.setIsUpload(true);
      try {
        const { data } = await taskServices.simpleUpload({ file });
        if (data?.url) {
          return { default: data?.url };
        }
        return { default: await this.getBase64(file) };
      } catch (error) {
        return { default: await this.getBase64(file) };
      } finally {
        this.setIsUpload(false);
      }
    });
  }

  getBase64(file) {
    return new Promise((resolve, rej) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        rej('');
      };
    });
  }

  // Aborts the upload process.
  abort() {}
}
