import { call, put, takeEvery } from 'redux-saga/effects';
import Services from '../../../../services/localRuleServices';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';

export default function* addLocalRuleSaga() {
  yield takeEvery(actionTypes.LOCAL_RULE.ADD_LOCAL_RULE_REQUEST, addLocalRule);
}

export function* addLocalRule(action) {
  try {
    const response = yield call(Services.add, action.payload.values);
    if (response.success) {
      yield put(actions.addLocalRuleSuccess(response.data));
      if (action.payload?.callback) {
        action.payload?.callback();
      }
    } else {
      yield put(actions.addLocalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.addLocalRuleFail(error));
  }
}
