import * as types from '../actionTypes/ActionTypes';

const initialState = {
  historyData: [],
  suggestTransactions: [],
  transactionMissingSummary: {
    data: [],
    total: 0,
    totalMissing: 0
  },
  loading: {
    missingSummary: false,
    suggestTransaction: false,
    getHistory: false
  },
  loadingFetchBankTransactionById: false
};

const bankTransaction = (state = initialState, action) => {
  switch (action.type) {
    case types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_REQUEST:
      return {
        ...state,
        loading: {
          missingSummary: true
        }
      };
    case types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: {
          missingSummary: false
        },
        transactionMissingSummary: action.payload
      };
    case types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_FAIL:
      return {
        ...state,
        loading: {
          suggestTransaction: false
        }
      };
    case types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: {
          suggestTransaction: true
        }
      };
    case types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: {
          suggestTransaction: false
        },
        suggestTransactions: action.payload
      };
    case types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_FAIL:
      return {
        ...state,
        loading: {
          missingSummary: false
        }
      };
    case types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_REQUEST:
      return {
        ...state,
        loadingFetchBankTransactionById: true
      };
    case types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        loadingFetchBankTransactionById: false
      };
    case types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_FAIL:
      return {
        ...state,
        loadingFetchBankTransactionById: false
      };
    case types.BANK_TRANSACTION.GET_HISTORY:
      return {
        ...state,
        loading: {
          getHistory: true
        }
      };
    case types.BANK_TRANSACTION.GET_HISTORY_SUCCESS:
      return {
        ...state,
        historyData: action.payload,
        loading: {
          getHistory: false
        }
      };
    case types.BANK_TRANSACTION.GET_HISTORY_FAIL:
      return {
        ...state,
        loading: {
          getHistory: false
        }
      };
    default:
      return state;
  }
};

export default bankTransaction;
