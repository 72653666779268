export const COMPANY_SERVICE = {
  FETCH_ALL_COMPANY_SERVICE_REQUEST: 'FETCH_ALL_COMPANY_SERVICE_REQUEST',
  FETCH_ALL_COMPANY_SERVICE_SUCCESS: 'FETCH_ALL_COMPANY_SERVICE_SUCCESS',
  FETCH_ALL_COMPANY_SERVICE_FAIL: 'FETCH_ALL_COMPANY_SERVICE_FAIL',
  ADD_COMPANY_SERVICE_REQUEST: 'ADD_COMPANY_SERVICE_REQUEST',
  ADD_COMPANY_SERVICE_SUCCESS: 'ADD_COMPANY_SERVICE_SUCCESS',
  ADD_COMPANY_SERVICE_FAIL: 'ADD_COMPANY_SERVICE_FAIL',
  UPDATE_COMPANY_SERVICE_REQUEST: 'UPDATE_COMPANY_SERVICE_REQUEST',
  UPDATE_COMPANY_SERVICE_SUCCESS: 'UPDATE_COMPANY_SERVICE_SUCCESS',
  UPDATE_COMPANY_SERVICE_FAIL: 'UPDATE_COMPANY_SERVICE_FAIL'
};
