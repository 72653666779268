import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as Actions from '../../../actions/3rd/psd2Sp1.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import psd2sp1Services from '../../../../services/3rd/psd2.sparebank1';

export default function* crsAuthorizeSaga() {
  yield takeEvery(actionTypes.PSD2_SP1.CRS_AUTHORIZE, crsAuthorize);
}

export function* crsAuthorize(action) {
  try {
    const response = yield call(psd2sp1Services.crsAuthorize, action.payload);
    const { data, success } = response;
    if (success) {
      // get voucherPaymentBasket

      yield put(Actions.psd2Sp1.crsAuthorizeSuccess(data));
      // update authorize account number status
    } else {
      yield put(Actions.psd2Sp1.crsAuthorizeFail(data));
    }
  } catch (error) {
    yield put(Actions.psd2Sp1.crsAuthorizeFail(error));
  }
}
