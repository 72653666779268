import * as Config from './server.configs';

export const authorization = 'authorization';
export const TokenSecret = 'fPY4pwhSOo1SakxFDaEd';
export const webConfig = {
  webUrl: Config[process.env.NODE_ENV].web,
  loginUrl: `${Config[process.env.NODE_ENV].ACCOUNT_WEB}/login?redirect-url=${encodeURIComponent(window.location.href)}`,
  logoutUrl: `${Config[process.env.NODE_ENV].ACCOUNT_WEB}/logout.html?redirect-url=${encodeURIComponent(window.location.href)}`,
  addCompany: `${Config[process.env.NODE_ENV].ORYTON_WEB}/finance/companies/add`,
  refreshToken: `${Config[process.env.NODE_ENV].ACCOUNT_API}/api/refresh-token`
};

export const format = {
  bankAccount: {
    format: 'NNNN.NN.NNNNN',
    key: '.'
  }
};

export const TimeConfig = {
  Format: 'DD-MM-YYYY HH:mm:ss',
  Request: 'DD.MM.YYYY',
  FormatDate: 'YYYY-MM-DD'
};

export const APIBrreg = {
  getCompany: 'https://data.brreg.no/enhetsregisteret/api/enheter/:orgnr'
};
