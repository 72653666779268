import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import * as selectors from '../../../selectors';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* addSagaInternal() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_ADD_TO_PAYMENT_LIST_REQUEST, addToPaymentList);
}

export function* addToPaymentList(action) {
  try {
    const response = yield call(VoucherPaymentServices.addToPaymentList, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.addToPaymentListSuccessInternal(data));
      // remove voucher from redux voucher list data
      const voucherListData = yield select(selectors.voucherListDataDefault);
      const voucherListDataNew = { ...voucherListData };
      const { rows } = voucherListDataNew;
      const filterRows = rows.filter((row) => row.Id !== data.Id);
      voucherListData.rows = filterRows;
      voucherListData.total = filterRows.length;
      yield put(actions.setVoucherListData(voucherListData));
      yield put(actions.setCurrentVoucher({}));
    } else {
      yield put(actions.addToPaymentListFailInternal(response.data));
    }
  } catch (error) {
    yield put(actions.addToPaymentListFailInternal(error));
  }
}
