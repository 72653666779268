import * as actionTypes from '../actionTypes/ActionTypes';

export const setShowModal = payload => {
  return {
    type: actionTypes.LOCAL_RULE.SET_SHOW_MODAL,
    payload
  };
};

export const setShowModalCopy = payload => {
  return {
    type: actionTypes.LOCAL_RULE.SET_SHOW_MODAL_COPY,
    payload
  };
};

export const setCopyRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.SET_COPY_RULE_SUCCESS,
    payload
  };
};

export const setCurrentLocalRule = payload => {
  return {
    type: actionTypes.LOCAL_RULE.SET_CURRENT_LOCAL_RULE,
    payload
  };
};

export const fetchAllLocalRuleRequest = payload => {
  return {
    type: actionTypes.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_REQUEST,
    payload
  };
};
export const fetchAllLocalRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_SUCCESS,
    payload
  };
};
export const fetchAllLocalRuleFail = payload => {
  return {
    type: actionTypes.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_FAIL,
    payload
  };
};

// ADD
export const addLocalRuleRequest = payload => {
  return {
    type: actionTypes.LOCAL_RULE.ADD_LOCAL_RULE_REQUEST,
    payload
  };
};
export const addLocalRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.ADD_LOCAL_RULE_SUCCESS,
    payload
  };
};
export const addLocalRuleFail = payload => {
  return {
    type: actionTypes.LOCAL_RULE.ADD_LOCAL_RULE_FAIL,
    payload
  };
};

// UPDATE
export const updateLocalRuleRequest = payload => {
  return {
    type: actionTypes.LOCAL_RULE.UPDATE_LOCAL_RULE_REQUEST,
    payload
  };
};
export const updateLocalRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.UPDATE_LOCAL_RULE_SUCCESS,
    payload
  };
};
export const updateLocalRuleFail = payload => {
  return {
    type: actionTypes.LOCAL_RULE.UPDATE_LOCAL_RULE_FAIL,
    payload
  };
};

// Delete
export const deleteLocalRuleRequest = payload => {
  return {
    type: actionTypes.LOCAL_RULE.DELETE_LOCAL_RULE_REQUEST,
    payload
  };
};
export const deleteLocalRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.DELETE_LOCAL_RULE_SUCCESS,
    payload
  };
};
export const deleteLocalRuleFail = payload => {
  return {
    type: actionTypes.LOCAL_RULE.DELETE_LOCAL_RULE_FAIL,
    payload
  };
};

// copy
export const copyLocalRuleRequest = payload => {
  return {
    type: actionTypes.LOCAL_RULE.COPY_LOCAL_RULE_REQUEST,
    payload
  };
};
export const copyLocalRuleSuccess = payload => {
  return {
    type: actionTypes.LOCAL_RULE.COPY_LOCAL_RULE_SUCCESS,
    payload
  };
};
export const copyLocalRuleFail = payload => {
  return {
    type: actionTypes.LOCAL_RULE.COPY_LOCAL_RULE_FAIL,
    payload
  };
};
