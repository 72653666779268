import { put, takeEvery, call } from 'redux-saga/effects';
import companyServices from '../../../services/companyServices';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';

export default function* fetchAllCompanyServiceSaga() {
  yield takeEvery(actionTypes.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_REQUEST, fetchAllCompanyService);
}

export function* fetchAllCompanyService(action) {
  try {
    const response = yield call(companyServices.getPaymentStatus, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.fetchAllCompanyServiceSuccess(data));
    } else {
      yield put(actions.fetchAllCompanyServiceFail(response));
    }
  } catch (error) {
    yield put(actions.fetchAllCompanyServiceFail(error));
  }
}
