import baseServices from '../../baseServices';
import { APIError } from '../../../constants/errorCode/system';
import API_RGBConstant from '../../../constants/3rd/API_RGB.constant';
import httpHelper from '../../../helpers/httpHelper';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  add: async params => {
    try {
      const url = API_RGBConstant.payments.add;
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = API_RGBConstant.payments.get + '?' + query;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const { id } = params;
      const url = API_RGBConstant.payments.update.replace(':id', id);
      const data = await baseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  savePaymentsBaskets: async params => {
    try {
      const url = API_RGBConstant.payments.savePaymentsBaskets;
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  saveListPaymentsBaskets: async params => {
    try {
      const url = API_RGBConstant.payments.saveListPaymentsBaskets;
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  handleUpdatePaymentsByBasketUUID: async params => {
    try {
      const url = API_RGBConstant.payments.handleUpdatePaymentsByBasketUUID;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updatePaymentStatus: async params => {
    try {
      const url = API_RGBConstant.payments.updatePaymentStatus;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  signingBaskets: async params => {
    try {
      const { basketUUID } = params;
      const url = API_RGBConstant.payments.signingBaskets.replace(':basketUUID', basketUUID);
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  checkDuplicate: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = API_RGBConstant.payments.checkDuplicate + '?' + query;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
};
