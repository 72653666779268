import baseServices from '../../baseServices';
import { APIError } from '../../../constants/errorCode/system';
import API_RGBConstant from '../../../constants/3rd/API_RGB.constant';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  add: async params => {
    try {
      const url = API_RGBConstant.paymentsAuths.add;
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  updateByBasketUUID: async params => {
    try {
      const url = API_RGBConstant.paymentsAuths.updateByBasketUUID;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
