import * as types from '../actionTypes/ActionTypes';

export const addCompanyUsersReminder = payload => ({
  type: types.COMPANY_USERS_REMINDER.ADD_REMINDER_REQUEST,
  payload
});
export const addCompanyUsersReminderSuccess = payload => ({
  type: types.COMPANY_USERS_REMINDER.ADD_REMINDER_SUCCESS,
  payload
});
export const addCompanyUsersReminderFail = payload => ({
  type: types.COMPANY_USERS_REMINDER.ADD_REMINDER_FAIL,
  payload
});

export const updateCompanyUsersReminder = payload => ({
  type: types.COMPANY_USERS_REMINDER.UPDATE_REMINDER_REQUEST,
  payload
});
export const updateCompanyUsersReminderSuccess = payload => ({
  type: types.COMPANY_USERS_REMINDER.UPDATE_REMINDER_SUCCESS,
  payload
});
export const updateCompanyUsersReminderFail = payload => ({
  type: types.COMPANY_USERS_REMINDER.UPDATE_REMINDER_FAIL,
  payload
});
