import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';

export default {
  adminVoucherConnect: async params => {
    try {
      const url = api.transactionVoucher.adminVoucherConnect;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  refreshMatchingVoucherTransaction: async params => {
    try {
      const url = api.transactionVoucher.refreshMatchingVoucherTransaction;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  add: async params => {
    try {
      const url = api.transactionVoucher.add;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
