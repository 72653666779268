import * as types from '../actionTypes/ActionTypes';

export const companyUsersAssignPayment = {
  getRequest: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST,
    payload
  }),
  getSuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS,
    payload
  }),
  getFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_FAIL,
    payload
  }),
  addRequest: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST,
    payload
  }),
  addSuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS,
    payload
  }),
  addFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_FAIL,
    payload
  }),
  updateRequest: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST,
    payload
  }),
  updateSuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_SUCCESS,
    payload
  }),
  updateFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.UPDATE_COMPANY_USERS_ASSIGN_PAYMENT_FAIL,
    payload
  }),
  getBanksVerifiedPaymentRequest: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_REQUREST,
    payload
  }),
  getBanksVerifiedPaymentSuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_SUCCESS,
    payload
  }),
  getBanksVerifiedPaymentFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_FAIL,
    payload
  }),
  addUpdateBanksVerifiedPaymentRequest: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_UPDATE_BANKS_VERIFIED_PAYMENT_REQUREST,
    payload
  }),
  addUpdateBanksVerifiedPaymentSuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_UPDATE_BANKS_VERIFIED_PAYMENT_SUCCESS,
    payload
  }),
  addUpdateBanksVerifiedPaymentFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.ADD_UPDATE_BANKS_VERIFIED_PAYMENT_FAIL,
    payload
  }),
  fetchAssignUsersByCompany: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY,
    payload
  }),
  fetchAssignUsersByCompanySuccess: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY_SUCCESS,
    payload
  }),
  fetchAssignUsersByCompanyFail: payload => ({
    type: types.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY_FAIL,
    payload
  })
};
