import * as actionTypes from '../actionTypes/ActionTypes';

export const setShowModalRule = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.SET_SHOW_MODAL_RULE,
    payload
  };
};

export const setCurrentGlobalRule = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.SET_CURRENT_GLOBAL_RULE,
    payload
  };
};

export const fetchAllGlobalRuleRequest = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_REQUEST,
    payload
  };
};
export const fetchAllGlobalRuleSuccess = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_SUCCESS,
    payload
  };
};
export const fetchAllGlobalRuleFail = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_FAIL,
    payload
  };
};

// ADD
export const addGlobalRuleRequest = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.ADD_GLOBAL_RULE_REQUEST,
    payload
  };
};
export const addGlobalRuleSuccess = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.ADD_GLOBAL_RULE_SUCCESS,
    payload
  };
};
export const addGlobalRuleFail = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.ADD_GLOBAL_RULE_FAIL,
    payload
  };
};

// UPDATE
export const updateGlobalRuleRequest = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.UPDATE_GLOBAL_RULE_REQUEST,
    payload
  };
};
export const updateGlobalRuleSuccess = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.UPDATE_GLOBAL_RULE_SUCCESS,
    payload
  };
};
export const updateGlobalRuleFail = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.UPDATE_GLOBAL_RULE_FAIL,
    payload
  };
};

// Delete
export const deleteGlobalRuleRequest = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.DELETE_GLOBAL_RULE_REQUEST,
    payload
  };
};
export const deleteGlobalRuleSuccess = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.DELETE_GLOBAL_RULE_SUCCESS,
    payload
  };
};
export const deleteGlobalRuleFail = payload => {
  return {
    type: actionTypes.GLOBAL_RULE.DELETE_GLOBAL_RULE_FAIL,
    payload
  };
};
