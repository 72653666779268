import * as types from '../actionTypes/ActionTypes';

const initialState = {
  loading: {
    adminVoucherConnect: false
  }
};

const transactionVoucher = (state = initialState, action) => {
  switch (action.type) {
    case types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_REQUEST:
      return {
        ...state,
        loading: {
          adminVoucherConnect: true
        }
      };
    case types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_SUCCESS:
      return {
        ...state,
        loading: {
          adminVoucherConnect: false
        }
      };
    case types.TRANSACTION_VOUCHER.ADMIN_VOUCHER_CONNECT_FAIL:
      return {
        ...state,
        loading: {
          adminVoucherConnect: false
        }
      };
    default:
      return state;
  }
};

export default transactionVoucher;
