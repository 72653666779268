import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  add: async params => {
    try {
      const url = api.localRule.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.localRule.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.localRule.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  copy: async params => {
    try {
      const url = api.localRule.copy;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.localRule.fetchAll}?${query}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
