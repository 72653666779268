import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isFetching: false,
    isAdding: false,
  },
  companyServiceData: {
    isPaymentOn: false,
    id: null,
  },
};

const companyService = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_REQUEST:
      return {
        ...state,
        loading: {
          isFetching: true,
        },
      };
    case types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        companyServiceData: action.payload,
        loading: {
          isFetching: false,
        },
      };
    case types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_FAIL:
      return {
        ...state,
        loading: {
          isFetching: false,
        },
      };
    case types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_REQUEST:
    case types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_REQUEST:
      return {
        ...state,
        loading: {
          isAdding: true,
        },
      };
    case types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_SUCCESS:
    case types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        loading: {
          isAdding: false,
        },
      };
    case types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_FAIL:
    case types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_FAIL:
      return {
        ...state,
        loading: {
          isAdding: false,
        },
      };
    default:
      return state;
  }
};

export default companyService;
