import AuthenticationServices from '../../authenticationServices';
// import { webConfig } from '../configs/system.configs';
require('es6-promise').polyfill();
require('isomorphic-fetch');

const getLanguage = () => {
  return localStorage.getItem('i18nextLng') || 'en';
};


export default {
  postDataTokenForm: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    const formData = new FormData();
    // eslint-disable-next-line
    for (const name in data) {
      formData.append(name, data[name]);
    }
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    });
  },
  deleteDataToken: async url => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    });
  },
  getDataToken: async (url) => {
    try {
      const token = AuthenticationServices.getAuthenticateToken();
      return fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Lang': getLanguage()
        },
        redirect: 'follow',
        referrer: 'no-referrer'
      });
    } catch (error) {
      throw error;
    }
  },
  postDataToken: async (url, data) => {
    const token = AuthenticationServices.getAuthenticateToken();
    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(data)
    });
  }
};
