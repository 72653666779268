import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import bankServices from '../../../services/bankServices';

export default function* getBankByFilterSaga() {
  yield takeEvery(actionTypes.BANK.GET_BANK_BY_FILTER_REQUEST, getBankByFilter);
}

export function* getBankByFilter(action) {
  try {
    const response = yield call(bankServices.getBankByFilter, action.payload);
    const { success } = response;
    if (success) {
      const { data } = response;
      yield put(actions.getBankByFilterSuccess(data));
    } else {
      yield put(actions.getBankByFilterFail(response));
    }
  } catch (error) {
    yield put(actions.getCompaniesFail(error));
  }
}
