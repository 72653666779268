import * as types from '../actionTypes/ActionTypes';

export const setBreadcrumb = (data) => ({ type: types.SYSTEM.Breadcrumb, payload: data });
export * from './bank.actions';
export * from './bankTransaction.actions';
export * from './company.actions';
export * from './voucher.actions';
export * from './voucherPayment.actions';
export * from './inbox.actions';
export * from './notification.actions';
export * from './adminEmail.actions';
export * from './transactionVoucher.actions';
export * from './connectMissing.actions';
export * from './globalRule.actions';
export * from './localRule.actions';
export * from './companyUsers.actions';
export * from './companyUsersAlertVoucher.actions';
export * from './companyUsersReminder.actions';
export * from './companyBankService.actions';
export * from './voucherPaymentsBasket.actions';
export * from './setting.actions';
export * from './settingBank.actions';
export * from './companyService.actions';
export * from './companyPaymentPackages.actions';
export * from './companyUsersAssignPayment.actions';
export * from './paymentPackages.actions';
export * from './3rd/stripe.actions';
export * from './3rd/psd2Sp1.actions';
export * from './companyBank.actions';
export * from './3rd/rgb.actions';
export * from './voucherPaymentInternal.actions';
export * from './voucherPaymentInternalFilter.actions';
export * from './paymentList.actions';
export * from './consent.actions';
