export const SIGNANT = {
  INIT_AUTH: 'INIT_AUTH',
  INIT_AUTH_SUCCESS: 'INIT_AUTH_SUCCESS',
  INIT_AUTH_FAIL: 'INIT_AUTH_FAIL',
  GET_AUTH_STATUS: 'GET_AUTH_STATUS',
  GET_AUTH_SUCCESS: 'GET_AUTH_SUCCESS',
  GET_AUTH_FAIL: 'GET_AUTH_FAIL',
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAIL: 'AUTH_FAIL',
  HANDLE_AUTHORIZE_COMANY_BANKS: 'HANDLE_AUTHORIZE_COMANY_BANKS',
  HANDLE_AUTHORIZE_COMANY_BANKS_SUCCESS: 'HANDLE_AUTHORIZE_COMANY_BANKS_SUCCESS',
  HANDLE_AUTHORIZE_COMANY_BANKS_FAIL: 'HANDLE_AUTHORIZE_COMANY_BANKS_FAIL'
};
