import * as types from '../actionTypes/ActionTypes';

export const setting = {
  saveSettingUser: (payload, callback) => ({
    type: types.SETTING.SAVE_SETTING_USER,
    payload,
    callback,
  }),
  saveSettingUserSuccess: (payload) => ({
    type: types.SETTING.SAVE_SETTING_USER_SUCCESS,
    payload,
  }),
  saveSettingUserFail: (payload) => ({
    type: types.SETTING.SAVE_SETTING_USER_FAIL,
    payload,
  }),
};
