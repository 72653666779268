import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions/voucherPaymentInternal.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getByVoucherIdInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_PAYMENTS_UUID_REQUEST, getByPaymentsUUID);
}

export function* getByPaymentsUUID(action) {
  try {
    const response = yield call(VoucherPaymentServices.getByPaymentsUUID, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.getByPaymentsUUIDSuccessInternal(data));
    } else {
      yield put(actions.getByPaymentsUUIDdFailInternal(response.message));
    }
  } catch (error) {
    yield put(actions.getByPaymentsUUIDdFailInternal(error));
  }
}
