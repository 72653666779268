import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isShowModalRule: false,
  loading: {
    isFetchingAllGlobalRule: false,
    isDeletingAllGlobalRule: false,
    isAddingGlobalRule: false
  },
  globalRules: {
    rows: [],
    total: 0
  },
  currentGlobalRule: {}
};

const globalRule = (state = initialState, action) => {
  switch (action.type) {
    case types.GLOBAL_RULE.SET_SHOW_MODAL_RULE:
      return {
        ...state,
        isShowModalRule: action.payload
      };
    case types.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isFetchingAllGlobalRule: true
        }
      };
    case types.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_SUCCESS:
      return {
        ...state,
        globalRules: action.payload,
        loading: {
          isFetchingAllGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.FETCH_ALL_GLOBAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isFetchingAllGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.DELETE_GLOBAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isDeletingAllGlobalRule: true
        }
      };
    case types.GLOBAL_RULE.DELETE_GLOBAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isDeletingAllGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.DELETE_GLOBAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isDeletingAllGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.ADD_GLOBAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: true
        }
      };
    case types.GLOBAL_RULE.ADD_GLOBAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.ADD_GLOBAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.UPDATE_GLOBAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: true
        }
      };
    case types.GLOBAL_RULE.UPDATE_GLOBAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.UPDATE_GLOBAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isAddingGlobalRule: false
        }
      };
    case types.GLOBAL_RULE.SET_CURRENT_GLOBAL_RULE:
      return {
        ...state,
        currentGlobalRule: action.payload
      };
    default:
      return state;
  }
};

export default globalRule;
