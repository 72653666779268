import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import transactionServices from '../../../services/transactionServices';

export default function* fetchBankTransactionByIdSaga() {
  yield takeEvery(actionTypes.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_REQUEST, fetchBankTransactionById);
}

export function* fetchBankTransactionById(action) {
  try {
    const response = yield call(transactionServices.fetchById, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.setCurrentTransaction(data));
      yield put(actions.fetchBankTransactionByIdSuccess(data));
    }
  } catch (error) {
    yield put(actions.fetchBankTransactionByIdFail(error));
  }
}
