import * as actionTypes from '../../actionTypes/ActionTypes';

export const stripe = {
  createPaymentIntent: payload => {
    return {
      type: actionTypes.STRIPE.CREATE_PAYMENT_INTENT,
      payload
    };
  },
  createPaymentIntentSuccess: payload => {
    return {
      type: actionTypes.STRIPE.CREATE_PAYMENT_INTENT_SUCCESS,
      payload
    };
  },
  createPaymentIntentFail: payload => {
    return {
      type: actionTypes.STRIPE.CREATE_PAYMENT_INTENT_FAIL,
      payload
    };
  }
};
