import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  addToPaymentList: async (params) => {
    try {
      const url = api.voucherPayments.addToPaymentList;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  payToBank: async (params) => {
    try {
      const url = api.voucherPayments.payToBank;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async (params) => {
    try {
      if (!params.from || !params.to) {
        delete params.from;
        delete params.to;
      }
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPayments.fetchAll}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getByVoucherId: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPaymentsV2.getByVoucherId}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  saveVoucherPayments: async (params) => {
    try {
      const url = api.voucherPayments.save;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  saveVoucherListPayments: async (params) => {
    try {
      const url = api.voucherPayments.saveList;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateVoucherPayments: async (params) => {
    try {
      const { UUID } = params;
      const url = api.voucherPayments.update.replace(':uuid', UUID);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateVoucherPaymentsStatus: async (params) => {
    try {
      const url = api.voucherPayments.updateVoucherPaymentsStatus;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  checkAllowPayment: async (params) => {
    try {
      const url = api.voucherPayments.checkAllowPayment;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  handleAfterCreateAsice: async (params) => {
    try {
      const url = api.voucherPayments.handleAfterCreateAsice;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateByPaymentsIds: async (params) => {
    try {
      const url = api.voucherPayments.updateByPaymentsIds;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getByPaymentsUUID: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPaymentsV2.getByPaymentsUUID}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatistic: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPayments.getStatistic}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  autoAddOldPayment: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucherPayments.autoAddOldPayment}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatisticInternal: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.internalTransfer.getStatistic}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addUnpaidEhfVouchersToPaymentList: async (params) => {
    try {
      const { companyId } = params;
      const url = api.voucherPayments.addUnpaidEhfVouchersToPaymentList.replace(':companyId', companyId);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getPaymentActions: async (params) => {
    try {
      const { companyId } = params;
      const url = api.voucherPayments.getPaymentActions.replace(':companyId', companyId);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  editInternalTransfer: async (params) => {
    try {
      const { voucherPaymentId } = params;
      const url = api.voucherPaymentsV2.editInternalTransfer.replace(':id', voucherPaymentId);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
