import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import CompanyUsersServices from '../../../services/companyUsersServices';

export default function* deleteCompanyUsersSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS.DELETE_COMPANY_USERS_REQUEST, deleteCompanyUsers);
}

export function* deleteCompanyUsers(action) {
  try {
    const response = yield call(CompanyUsersServices.delete, action.payload);
    if (response.success) {
      yield put(actions.deleteCompanyUsersSuccess(response.data));
    } else {
      yield put(actions.deleteCompanyUsersFail(response.data));
    }
  } catch (error) {
    yield put(actions.deleteCompanyUsersFail(error));
  }
}
