import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isShowPaymentInfoModal: false
};

const paymentList = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_LIST.SET_SHOW_PAYMENT_INFO_MODAL:
      return {
        ...state,
        isShowPaymentInfoModal: action.payload
      };
    default:
      return state;
  }
};

export default paymentList;
