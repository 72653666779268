import React from 'react';
import { withRouter } from 'react-router-dom';
import Logger from '../configs/logger.configs';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  goBack = () => {
    window.history.go(-1);
    setInterval(() => {
      window.location.reload();
    }, 200);
  };

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log(14, { error, errorInfo });
    console.log(error);
    Logger(`${window.location.href} - ${error.toString()}`);
    Logger(`ErrorInfo: ${JSON.stringify(errorInfo)}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="app-error">
          <div className="orange-text">
            <span className="material-icons vertical-align-middle">report</span> OOP!
          </div>
          <div className="red-text">Something went wrong!</div>
          <div className="red-text">
            <span className="cursor-pointer" onClick={this.goBack}>
              <span className="material-icons vertical-align-middle">arrow_back</span>
            </span>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
