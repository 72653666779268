/*
  Use for verify Payment and save payment info in process
*/

import {
  put,
  takeEvery,
  call,
  select
} from 'redux-saga/effects';

import Actions from '../../../actions/3rd/signant.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import signantServices from '../../../../services/3rd/signant';
import * as selectors from '../../../../selectors';
import rgbPaymentsAuthsService from '../../../../services/3rd/rgb/rgbPaymentsAuths.service';

export default function* signantInitAuthSaga() {
  yield takeEvery(actionTypes.SIGNANT.INIT_AUTH, signantInitAuth);
}

export function* signantInitAuth(action) {
  try {
    const response = yield call(signantServices.initAuth, action.payload);
    const { data, success, message } = response;
    if (success) {
      // get voucherPaymentBasket
      const currentBasketUUID = yield select(selectors.currentBasketUUID);
      if (currentBasketUUID) data.BasketUUID = currentBasketUUID;
      // save paymentsAuths
      const paymentsAuthsParams = {
        basketUUID: currentBasketUUID,
        authenticationUrl: data.AuthenticationUrl,
        requestID: data.RequestID,
        statusID: data.StatusID,
        statusText: data.StatusText,
        trackingID: data.TrackingID
      };
      yield call(rgbPaymentsAuthsService.add, paymentsAuthsParams);
      yield put(Actions.initAuthSuccess(data));
    } else {
      yield put(Actions.initAuthFail(message || 'Failed to fetch'));
    }
  } catch (error) {
    yield put(Actions.initAuthFail(error));
  }
}
