import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import BankTransactionServices from '../../../services/transactionServices';

export default function* getTransactionMissingSummary() {
  yield takeEvery(actionTypes.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_REQUEST, getTransactionMissingSummarySaga);
}

export function* getTransactionMissingSummarySaga(action) {
  try {
    const response = yield call(BankTransactionServices.getTransactionMissingSummary, action.payload);
    if (response.success) {
      yield put(actions.getTransactionMissingSummarySuccess(response.data));
    } else {
      yield put(actions.getTransactionMissingSummaryFail(response.data));
    }
  } catch (error) {
    yield put(actions.getTransactionMissingSummaryFail(error));
  }
}
