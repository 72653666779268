import * as types from '../../actionTypes/ActionTypes';
// INIT
const initialState = {
  isInitAuthorize: false,
  isLoadingAuth: false,
  isGettingAuthStatus: false,
  isHandlingAuthorizeCompanyBanks: false,
  signantAuthRequestData: null,
  signantAuthorizeData: null,
  handleAuthorizeCompanyBanksData: null,
  errors: {
    initAuthorize: null,
    handleAuthorizeCompanyBanks: null
  }
};

const signant = (state = initialState, action) => {
  switch (action.type) {
    case types.SIGNANT.INIT_AUTH:
      return {
        ...state,
        isInitAuthorize: true,
        signantAuthRequestData: {},
        errors: {
          initAuthorize: null
        }
      };
    case types.SIGNANT.INIT_AUTH_SUCCESS:
      return {
        ...state,
        isInitAuthorize: false,
        signantAuthRequestData: action.payload
      };
    case types.SIGNANT.INIT_AUTH_FAIL:
      return {
        ...state,
        isInitAuthorize: false,
        errors: {
          initAuthorize: action.payload
        }
      };
    case types.SIGNANT.GET_AUTH_STATUS:
      return {
        ...state,
        signantAuthorizeData: {},
        isGettingAuthStatus: true
      };
    case types.SIGNANT.GET_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        signantAuthorizeData: action.payload,
        isGettingAuthStatus: false
      };
    case types.SIGNANT.GET_AUTH_STATUS_FAIL:
      return {
        ...state,
        isGettingAuthStatus: false
      };
    case types.SIGNANT.AUTH_REQUEST:
      return {
        ...state,
        isLoadingAuth: true,
        signantAuthRequestData: {}
      };
    case types.SIGNANT.AUTH_SUCCESS:
      return {
        ...state,
        isLoadingAuth: false,
        signantAuthRequestData: action.payload
      };
    case types.SIGNANT.AUTH_FAIL:
      return {
        ...state,
        isLoadingAuth: false
      };
    case types.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS:
      return {
        ...state,
        isHandlingAuthorizeCompanyBanks: true,
        handleAuthorizeCompanyBanksData: {},
        errors: {
          handleAuthorizeCompanyBanks: null
        }
      };
    case types.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS_SUCCESS:
      return {
        ...state,
        isHandlingAuthorizeCompanyBanks: false,
        errors: {
          handleAuthorizeCompanyBanks: null
        },
        handleAuthorizeCompanyBanksData: action.payload
      };
    case types.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS_FAIL:
      return {
        ...state,
        errors: {
          handleAuthorizeCompanyBanks: action.payload
        },
        isHandlingAuthorizeCompanyBanks: false
      };
    default:
      return state;
  }
};

export default signant;
