import * as types from '../actionTypes/ActionTypes';

export const setOpenModal = payload => ({
  type: types.COMPANY_USERS.SET_OPEN_MODAL,
  payload
});
export const setSelectedUser = payload => ({
  type: types.COMPANY_USERS.SET_SELECTED_USER,
  payload
});

export const fetchAllCompanyUsers = payload => ({
  type: types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_REQUEST,
  payload
});
export const fetchAllCompanyUsersSuccess = payload => ({
  type: types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_SUCCESS,
  payload
});
export const fetchAllCompanyUsersFail = payload => ({
  type: types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_FAIL,
  payload
});

export const deleteCompanyUsers = payload => ({
  type: types.COMPANY_USERS.DELETE_COMPANY_USERS_REQUEST,
  payload
});
export const deleteCompanyUsersSuccess = payload => ({
  type: types.COMPANY_USERS.DELETE_COMPANY_USERS_SUCCESS,
  payload
});
export const deleteCompanyUsersFail = payload => ({
  type: types.COMPANY_USERS.DELETE_COMPANY_USERS_FAIL,
  payload
});

export const addCompanyUsers = payload => ({
  type: types.COMPANY_USERS.ADD_COMPANY_USERS_REQUEST,
  payload
});
export const addCompanyUsersSuccess = payload => ({
  type: types.COMPANY_USERS.ADD_COMPANY_USERS_SUCCESS,
  payload
});
export const addCompanyUsersFail = payload => ({
  type: types.COMPANY_USERS.ADD_COMPANY_USERS_FAIL,
  payload
});
