import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as companyPaymentPackagesActions from '../../actions/companyPaymentPackages.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import stripeServices from '../../../services/3rd/stripe';

export default function* buyPaymentPackagesSaga() {
  yield takeEvery(actionTypes.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_REQUEST, buyPaymentPackages);
}

export function* buyPaymentPackages(action) {
  try {
    const response = yield call(stripeServices.createPaymentForRgbPackages, action.payload);
    const { success, data } = response;
    if (success) {
      const { onClose } = action.payload;
      yield put(companyPaymentPackagesActions.buyCompanyPaymentPackagesSuccess(data));
      onClose();
    } else {
      const { message, messageCode } = response;
      yield put(companyPaymentPackagesActions.buyCompanyPaymentPackagesFail({ ...message && { message }, ...messageCode && { messageCode } }));
    }
  } catch (error) {
    yield put(companyPaymentPackagesActions.buyCompanyPaymentPackagesFail(error));
  }
}
