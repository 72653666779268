import { createUseStyles } from 'react-jss';
import { BREAK_POINT, COLOR } from '../../common/color';

export const useStyles = createUseStyles({
  container: {
    width: '100vw',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: COLOR.white,
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& .ck-editor__main': {
        height: '100%',
      },
    },
    '& > div': {
      flex: 1,
    },
    '& > p': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      marginLeft: 'auto',
      textAlign: 'right',
      height: '35px',
      marginRight: 10,
      color: COLOR.dodgerBlue,
      '& .material-icons': {
        cursor: 'pointer',
      },
    },
  },
});
