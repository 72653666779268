import React from 'react';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import { VIDEO_PASSWORD } from '../../constants/video.constant';
import { loadNewBody, revertBody } from '../../utils/Utils';

const bodyClass = ['1-colum', 'bg-full-screen-image', 'blank-page'];

class VidePaymentSP1Android extends React.PureComponent {
  componentDidMount() {
    loadNewBody(bodyClass);
    // require password
    this.requirePass();
  }

  componentWillUnmount() {
    revertBody(bodyClass);
  }

  passForm = async () => {
    const { t } = this.props;
    setTimeout(() => {
      try {
        document.getElementsByClassName('swal2-input')[0].onkeydown = function (event) {
          if (event.which === 32) return false;
        };
        document.getElementsByClassName('swal2-input')[0].onchange = function () {
          this.value = this.value.replace(/\s/g, '');
        };
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    const { value } = await Swal.fire({
      title: t('title'),
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonText: t('confirm'),
    });
    return value;
  };

  requirePass = async () => {
    const value = await this.passForm();
    if (value === VIDEO_PASSWORD) {
      return true;
    } else {
      window.location.reload();
    }
    return false;
  };

  render() {
    return (
      <div className="row d-flex justify-content-center align-items-center height-100vh">
        <div>
          <video width="320" height="440" controls src="https://s3.eu-north-1.amazonaws.com/bilagsky.no/video/BilagSky+Pay+Sp1.mp4">
            <track
              default
              kind="captions"
              srcLang="no"
              crossOrigin="anonymous"
              src="https://s3.eu-north-1.amazonaws.com/bilagsky.no/video/BilagSky+Pay+Sp1.mp4"
            />
            Your browser does not support the video tag
          </video>
          <span className="d-block center">Payment SpareBank1</span>
          <span className="d-block center">(Android)</span>
        </div>
      </div>
    );
  }
}

export default withNamespaces('video')(VidePaymentSP1Android);
