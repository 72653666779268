import * as actionTypes from '../actionTypes/ActionTypes';

export const setShowModalSelectCompany = (payload) => {
  return {
    type: actionTypes.COMPANY.SET_SHOW_MODAL_SELECT_COMPANY,
    payload,
  };
};

export const getCompaniesRequest = (payload, callback) => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_REQUEST,
    payload,
    callback,
  };
};
export const getCompaniesSuccess = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_SUCCESS,
    payload,
  };
};
export const getCompaniesFail = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_FAIL,
    payload,
  };
};

export const getOwnerRequest = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_REQUEST,
    payload,
  };
};
export const getOwnerSuccess = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_SUCCESS,
    payload,
  };
};
export const getOwnerFail = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_FAIL,
    payload,
  };
};

export const setCurrentCompany = (payload) => {
  return {
    type: actionTypes.COMPANY.SET_CURRENT_COMPANY,
    payload,
  };
};

export const getCompanyDidivisionByOrg = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_DIVISION_BY_ORG,
    payload,
  };
};
export const getCompanyDidivisionByOrgSuccess = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_DIVISION_BY_ORG_SUCCESS,
    payload,
  };
};
export const getCompanyDidivisionByOrgFail = (payload) => {
  return {
    type: actionTypes.COMPANY.GET_DIVISION_BY_ORG_FAIL,
    payload,
  };
};

export const setEditDivision = (payload) => {
  return {
    type: actionTypes.COMPANY.SET_EDIT_DIVISION,
    payload,
  };
};
