import psd2BaseServices from './psd2BaseServices';
import baseServices from '../../baseServices';
import API from '../../../constants/3rd/api.psd2Sparebank1.constant';
import { APIError } from '../../../constants/errorCode/system';
import { api } from '../../../constants/api.constant';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  createAsice: async params => {
    try {
      const url = API.createAsice;
      const data = await psd2BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  handleUpdatePaymentsByBasketUUID: async params => {
    try {
      const url = api.psd2Sparebank1.handleUpdatePaymentsByBasketUUID;
      const data = await baseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  crsAuthorize: async params => {
    // organisationNumber, ssn, accountNumber
    try {
      const url = API.crsAuthorize;
      const data = await psd2BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
