import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor-ckeditor5-build-classic-mention';
import React, { useEffect, useState } from 'react';
import MyUploadAdapter from './MyUploadAdapter';

function customItemRenderer(item) {
  const itemElement = document.createElement('span');

  itemElement.classList.add('custom-item');
  itemElement.id = `mention-list-item-id-${item.userId}`;
  itemElement.textContent = `${item.name}`;

  const usernameElement = document.createElement('span');

  usernameElement.classList.add('custom-item-username');
  //   usernameElement.textContent = item.id;

  itemElement.appendChild(usernameElement);

  return itemElement;
}

const Editor = (props) => {
  const { feed = [], data = '', onChangeCommentEditor, isReadOnly = false, setIsUpload, minHeight } = props;
  const [editor, setEditor] = useState();

  useEffect(() => {
    if (editor) editor.isReadOnly = isReadOnly;
  }, [isReadOnly]);

  return (
    <CKEditor
      config={{
        extraPlugins: ['Mention'],
        ...(feed.length > 0
          ? {
              mention: {
                feeds: [
                  {
                    marker: '@',
                    feed:
                      feed?.map((u) => ({
                        id: `@${u.FullName}`,
                        userId: u.UserId,
                        name: u.FullName,
                      })) || [],
                    itemRenderer: customItemRenderer,
                  },
                ],
              },
            }
          : {}),
        startupFocus: true,
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          '|',
          'undo',
          'redo',
          'imageUpload',
        ],
      }}
      editor={ClassicEditor}
      data={data}
      onChange={(evt, editor) => onChangeCommentEditor(editor)}
      onReady={(editor) => {
        editor?.editing.view.focus();
        editor.isReadOnly = isReadOnly;
        setEditor(editor);
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return new MyUploadAdapter(loader, setIsUpload);
        };
        editor.editing.view.change((writer) => {
          writer.setStyle('min-height', minHeight ? minHeight : '120px', editor.editing.view.document.getRoot());
        });
      }}
    />
  );
};

export default Editor;
