import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as selectors from '../../../selectors';
import BankTransactionServices from '../../../services/transactionServices';
import * as actions from '../../actions';
import { fetchBankTransactionById } from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';

export default function* fetchBankDataSaga() {
  yield takeEvery(actionTypes.BANK.FETCH_BANK_DATA_REQUEST, fetchBankData);
}

export function* fetchBankData(action) {
  try {
    // get company
    const { payload } = action;
    const company = yield select(selectors.company) || {};
    const { CompanyId: companyId } = company;
    let queryFilter = {};
    if (companyId) {
      let filter = yield select(selectors.filterBank);
      filter.isCheckAll = false;
      if (payload?.filter) {
        filter = payload.filter;
      }
      delete filter.false;
      // 1- get years
      const response = yield call(BankTransactionServices.getYears, companyId);
      const data = {
        bankStatisticData: {
          statis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        bankTransactionData: {
          total: 0,
          rows: [],
        },
      };
      if (response.success) {
        const { data: yearsData } = response;
        if (yearsData && yearsData.length > 0) {
          // 2 set filter voucher
          // get filter
          filter.companyId = companyId;
          if (
            localStorage.getItem('bankYear') &&
            yearsData.map((e) => e.value).includes(+localStorage.getItem('bankYear')) &&
            Number.isFinite(+localStorage.getItem('bankYear'))
          ) {
            filter.year = localStorage.getItem('bankYear');
          }

          filter.years = yearsData;
          filter.year = filter.year || (filter.year === null ? yearsData[0].value : '');
          if (filter.isPrivate === '') {
            delete filter.isPrivate;
          }
          if (payload?.isClearId) {
            filter.id = null;
          }
          if (filter.id) {
            filter.year = '';
            yield put(fetchBankTransactionById(filter.id));
          }
          // 3 - get voucher statistic
          queryFilter = {
            year: filter.year,
            companyId: filter.companyId,
            companyBankId: filter.companyBankId,
            from: filter.from,
            to: filter.to,
            status: filter.status,
            pageNumber: filter.pageNumber,
            pageSize: filter.pageSize,
            month: filter.month,
            isPrivate: filter.isPrivate,
            filterAmount: filter.filterAmount,
            id: filter.id,
            sortBy: filter.sortBy,
            sortType: filter.sortType,
            keyword: filter.keyword,
          };
          // isChangePage
          const bankStatisticDataResponse = yield call(BankTransactionServices.getStatistic, queryFilter);
          if (bankStatisticDataResponse.success) data.bankStatisticData = bankStatisticDataResponse.data;
          const bankTransactionDataResponse = yield call(BankTransactionServices.fetchAll, queryFilter);
          if (bankTransactionDataResponse.success) data.bankTransactionData = bankTransactionDataResponse.data;
        }
        // dont have year
        yield put(actions.fetchBankDataSuccess(data));
        yield put(actions.setFilterBank(filter));
        if (!queryFilter?.id) {
          yield put(actions.setCurrentTransaction(null));
        }
      }
    }
  } catch (error) {
    yield put(actions.fetchBankDataFail(error));
  }
}
