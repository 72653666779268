import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyBankServices from '../../../services/companyBankServices';

export default function* deleteCompanyBankSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK.DELETE_COMPANY_BANK, deleteCompanyBank);
}

export function* deleteCompanyBank(action) {
  try {
    const response = yield call(companyBankServices.deleteCompanyBank, action.payload);
    const { success, data, message } = response;
    if (success) {
      yield put(actions.companyBank.deleteCompanyBankSuccess(data));
    } else {
      yield put(actions.companyBank.deleteCompanyBankSuccess(message));
    }
  } catch (error) {
    yield put(actions.companyBank.deleteCompanyBankSuccess(error));
  }
}
