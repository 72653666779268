import { createUseStyles } from 'react-jss';
import { BREAK_POINT, COLOR } from '../../../../common/color';

export const useStyles = createUseStyles({
  accepted: {
    padding: '20px 10px',
    width: '100%',
    border: `1px solid ${COLOR.grayBorder}`,
    color: COLOR.jungleGreen,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLOR.white,
    borderRadius: '7px',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  rejected: {
    padding: 10,
    textAlign: 'center',
    width: '100%',
    border: `1px solid ${COLOR.grayBorder}`,
    color: COLOR.flushMahogany,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLOR.white,
    borderRadius: '7px',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
