import * as types from '../actionTypes/ActionTypes';

const INITIAL_STATE = {
  forwardSuccessData: null
};

const adminEmail = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GET INBOX
    case types.ADMIN_EMAIL.GET_FORWARD_SUCCESS_DATA:
      return {
        ...state,
        forwardSuccessData: action.payload
      };
    case types.ADMIN_EMAIL.SET_FORWARD_SUCCESS_DATA:
      return {
        ...state,
        forwardSuccessData: action.payload
      };
    default:
      return state;
  }
};
export default adminEmail;
