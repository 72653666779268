import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isBuyingCompanyPaymentPackages: false
  },
  error: null,
  buyCompanyPaymentPackagesData: null,
  companyPaymentPackage: null
};

const companyPaymentPackages = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_REQUEST:
      return {
        ...state,
        loading: {
          isFetching: true
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        companyPaymentPackage: action.payload,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_FAIL:
      return {
        ...state,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_REQUEST:
      return {
        ...state,
        loading: {
          isAdding: true
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: {
          isAdding: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_FAIL:
      return {
        ...state,
        loading: {
          isAdding: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_REQUEST:
      return {
        ...state,
        loading: {
          isUpdating: true
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: {
          isUpdating: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_FAIL:
      return {
        ...state,
        loading: {
          isUpdating: false
        }
      };
    case types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_REQUEST:
      return {
        ...state,
        loading: {
          isBuyingCompanyPaymentPackages: true
        },
        error: null
      };
    case types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: {
          isBuyingCompanyPaymentPackages: false
        },
        buyCompanyPaymentPackagesData: action.payload,
        error: null
      };
    case types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: {
          isBuyingCompanyPaymentPackages: false
        }
      };
    default:
      return state;
  }
};

export default companyPaymentPackages;
