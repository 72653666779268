export const COMPANY_PAYMENT_PACKAGES = {
  GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_REQUEST: 'GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_REQUEST',
  GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_SUCCESS: 'GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_SUCCESS',
  GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_FAIL: 'GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_FAIL',
  ADD_COMPANY_PAYMENT_PACKAGES_REQUEST: 'ADD_COMPANY_PAYMENT_PACKAGES_REQUEST',
  ADD_COMPANY_PAYMENT_PACKAGES_SUCCESS: 'ADD_COMPANY_PAYMENT_PACKAGES_SUCCESS',
  ADD_COMPANY_PAYMENT_PACKAGES_FAIL: 'ADD_COMPANY_PAYMENT_PACKAGES_FAIL',
  UPDATE_COMPANY_PAYMENT_PACKAGES_REQUEST: 'UPDATE_COMPANY_PAYMENT_PACKAGES_REQUEST',
  UPDATE_COMPANY_PAYMENT_PACKAGES_SUCCESS: 'UPDATE_COMPANY_PAYMENT_PACKAGES_SUCCESS',
  UPDATE_COMPANY_PAYMENT_PACKAGES_FAIL: 'UPDATE_COMPANY_PAYMENT_PACKAGES_FAIL',
  BUY_PAYMENT_PACKAGES_REQUEST: 'BUY_PAYMENT_PACKAGES_REQUEST',
  BUY_PAYMENT_PACKAGES_SUCCESS: 'BUY_PAYMENT_PACKAGES_SUCCESS',
  BUY_PAYMENT_PACKAGES_FAIL: 'BUY_PAYMENT_PACKAGES_FAIL'
};
