import React, { useEffect, useState } from 'react';
import { authorization } from '../../../configs/system.configs';
import taskServices from '../../../services/taskServices';
import { getJsonFromUrl, onChangeCommentEditor } from '../../../utils/Utils';
import Editor from '../../UI/Editor';
import Loading from '../../UI/LoadingBilagSky';
import { useStyles } from '../styles';
import Swal from 'sweetalert2';
import LoadingIcon from '../../UI/Loading';

const AddComment = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [users, setUsers] = useState([]);
  const [detail, setDetail] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const [description, setDescription] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  const queryUrl = getJsonFromUrl();
  const { companyId, token, taskId } = queryUrl || {};
  if (!companyId || !token || !taskId) {
    return <Loading />;
  }
  // check exist companyId , taskId
  // get list user for mentions
  const fetchDataMentions = async () => {
    try {
      const { data } = await taskServices.getListAssignUser({ companyId });
      setUsers(data);
    } catch (error) {}
  };

  // get detail by taskId
  const fetchDetailTask = async () => {
    try {
      const { data } = await taskServices.getById(taskId);
      if (data) {
        setDetail(data);
        setDescription(data?.description);
      }
    } catch (error) {}
  };

  const inititalData = async () => {
    try {
      localStorage.setItem(authorization, token);
      await fetchDataMentions();
      await fetchDetailTask();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (processing || description === '') return;
    setProcessing(true);
    try {
      const values = {
        content: description,
      };
      const { success, message } = await taskServices.addComment(taskId, values);
      if (success) {
        setIsCompleted(true);
        window?.ReactNativeWebView?.postMessage('success');
      } else {
        Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        }).fire({
          icon: 'error',
          title: message,
        });
      }
    } catch (error) {
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    inititalData();
  }, []);

  return (
    <div className={classes.container}>
      {loading || !detail ? (
        <Loading />
      ) : (
        <>
          <LoadingIcon loading={processing || isUpload} />
          {isCompleted && <input type="hidden" id="completed-webview" />}
          <div>
            <Editor
              data={detail?.content}
              onChangeCommentEditor={(editor) => onChangeCommentEditor(editor, setDescription, false)}
              feed={users}
              isReadOnly={false}
              setIsUpload={setIsUpload}
              minHeight={'100%'}
            />
          </div>
          <p>
            <span className="material-icons" onClick={onSubmit}>
              send
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default AddComment;
