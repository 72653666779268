import * as types from '../actionTypes/ActionTypes';

export const buyRemittance = payload => ({
  type: types.COMPANY_BANK_SERVICE.BUY_REMITTANCE_REQUEST,
  payload
});
export const buyRemittanceSuccess = payload => ({
  type: types.COMPANY_BANK_SERVICE.BUY_REMITTANCE_SUCCESS,
  payload
});
export const buyRemittanceFail = payload => ({
  type: types.COMPANY_BANK_SERVICE.BUY_REMITTANCE_FAIL,
  payload
});
