import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyPaymentPackagesServices from '../../../services/companyPaymentPackagesServices';

export default function* updateCompanyPaymentPackagesSaga() {
  yield takeEvery(actionTypes.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_REQUEST, updateCompanyPaymentPackages);
}

export function* updateCompanyPaymentPackages(action) {
  try {
    const response = yield call(companyPaymentPackagesServices.update, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.updateCompanyPaymentPackagesSuccess(data));
    } else {
      yield put(actions.updateCompanyPaymentPackagesFail(response));
    }
  } catch (error) {
    yield put(actions.updateCompanyPaymentPackagesFail(error));
  }
}
