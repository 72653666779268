import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import orytonServices from '../../../services/orytonServices';

export default function* getInboxSaga() {
  yield takeEvery(actionTypes.INBOX.GET_INBOX_REQUEST, getInbox);
}

export function* getInbox(action) {
  try {
    const url = orytonServices.getInbox;
    const response = yield call(url, action.payload);
    if (response.isSuccess) {
      yield put(actions.getInboxSuccess(response.data));
    }
    yield put(actions.getInboxFail(response?.errors));
  } catch (error) {
    yield put(actions.getInboxFail(error));
  }
}
