import * as types from '../actionTypes/ActionTypes';

const initialState = {
  loading: {
    isLoadingCompanyBankInfo: false,
    isLoadingBalance: false,
    isDeleting: false,
    isLoadingGetDefaultAccountNumber: false
  },
  companyBanksBalance: [],
  companyBanksBalanceByAccountNumber: {},
  errors: {
    delete: null
  }
};

const companyBank = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID: {
      return {
        ...state,
        loading: {
          isLoadingCompanyBankInfo: true
        }
      };
    }
    case types.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_SUCCESS: {
      return {
        ...state,
        loading: {
          isLoadingCompanyBankInfo: false
        },
        companyBanksBalance: action.payload
      };
    }
    case types.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_FAIL: {
      return {
        ...state,
        loading: {
          isLoadingCompanyBankInfo: false
        }
      };
    }
    case types.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER: {
      return {
        ...state,
        loading: {
          isLoadingBalance: true
        },
        companyBanksBalanceByAccountNumber: {}
      };
    }
    case types.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_SUCCESS: {
      return {
        ...state,
        loading: {
          isLoadingBalance: false
        },
        companyBanksBalanceByAccountNumber: action.payload
      };
    }
    case types.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_FAIL: {
      return {
        ...state,
        loading: {
          isLoadingBalance: false
        }
      };
    }
    case types.COMPANY_BANK.DELETE_COMPANY_BANK: {
      return {
        ...state,
        loading: {
          isDeleting: true
        },
        errors: {
          delete: null
        }
      };
    }
    case types.COMPANY_BANK.DELETE_COMPANY_BANK_SUCCESS: {
      return {
        ...state,
        loading: {
          isDeleting: false
        }
      };
    }
    case types.COMPANY_BANK.DELETE_COMPANY_BANK_FAIL: {
      return {
        ...state,
        loading: {
          isDeleting: false
        },
        errors: {
          delete: action.payload
        }
      };
    }
    case types.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER: {
      return {
        ...state,
        loading: {
          isLoadingGetDefaultAccountNumber: true
        },
        defaultAccountNumber: {}
      };
    }
    case types.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER_SUCCESS: {
      return {
        ...state,
        loading: {
          isLoadingGetDefaultAccountNumber: false
        },
        defaultAccountNumber: action.payload
      };
    }
    case types.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER_FAIL: {
      return {
        ...state,
        loading: {
          isLoadingGetDefaultAccountNumber: false
        }
      };
    }
    default:
      return state;
  }
};

export default companyBank;
