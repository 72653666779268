import { call, put, takeEvery } from 'redux-saga/effects';
import orytonServices from '../../../services/orytonServices';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';

export default function* getCompaniesSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_COMPANIES_REQUEST, getCompanies);
}

export function* getCompanies(action) {
  try {
    const response = yield call(orytonServices.getCompanyByUser, action.payload);
    const ownResponse = yield call(orytonServices.getOwnCompanys);
    let dataOwner = [];
    if (ownResponse.isSuccess) {
      if (ownResponse?.data?.length > 0) {
        dataOwner = ownResponse?.data
          .filter((d) => ([undefined, null].includes(d.SubscriptionId) || d.Status === 0) && d.IsSpecial !== 1)
          .map((e) => ({
            ...e,
            CompanyId: e.Id,
            isOwner: true,
          }));
      }
    }
    const { success, data, message } = response;
    if (success) {
      if (action.callback) {
        action.callback(data.data, actions.getCompaniesSuccess({ total: data.total, data: data.data, dataOwner }));
      }
    } else {
      yield put(actions.getCompaniesFail(message));
    }
  } catch (error) {
    yield put(actions.getCompaniesFail(error));
  }
}
