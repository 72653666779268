import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isAdding: false
};

const companyUsersAlertVoucher = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_REQUEST:
      return {
        ...state,
        isAdding: true
      };
    case types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_SUCCESS:
      return {
        ...state,
        isAdding: false
      };
    case types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_FAIL:
      return {
        ...state,
        isAdding: false
      };

    default:
      return state;
  }
};

export default companyUsersAlertVoucher;
