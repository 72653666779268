/*
  Bank = CompanyBank tables
*/

import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './baseServices';

export default {
  get: async (params) => {
    const requestUrl = api.task.get;
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.getDataToken(url);
    return await data.json();
  },
  getCompanies: async () => {
    const requestUrl = api.task.getCompanies;
    const data = await BaseServices.getDataToken(requestUrl);
    return await data.json();
  },
  getCreators: async () => {
    const requestUrl = api.task.getCreators;
    const data = await BaseServices.getDataToken(requestUrl);
    return await data.json();
  },
  getOverview: async (params) => {
    const requestUrl = api.task.getOverview;
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.getDataToken(url);
    return await data.json();
  },
  static: async () => {
    const requestUrl = api.task.static;
    const data = await BaseServices.getDataToken(requestUrl);
    return await data.json();
  },
  getById: async (id, open) => {
    const url = api.task.getById.replace(':id', id);
    const data = await BaseServices.getDataToken(url + (open ? '?open=1' : ''));
    return await data.json();
  },
  add: async (body) => {
    const url = api.task.add;
    const data = await BaseServices.postDataTokenForm(url, body);
    return data.json();
  },
  update: async (id, body) => {
    const url = api.task.update.replace(':id', id);
    const data = await BaseServices.putDataToken(url, body);
    return data.json();
  },
  delete: async (id, body) => {
    const url = api.task.update.replace(':id', id);
    const data = await BaseServices.deleteDataToken(url);
    return data.json();
  },
  getAttachments: async (id) => {
    const requestUrl = api.task.getAttachments.replace(':id', id);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  getComments: async (id, params) => {
    const requestUrl = api.task.getComments.replace(':id', id);
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.getDataToken(url);
    return data.json();
  },
  addAttachment: async (id, body) => {
    const requestUrl = api.task.addAttachments.replace(':id', id);
    const data = await BaseServices.postDataTokenForm(requestUrl, body);
    return data.json();
  },
  addComment: async (id, body) => {
    const requestUrl = api.task.addComment.replace(':id', id);
    const data = await BaseServices.postDataToken(requestUrl, body);
    return data.json();
  },
  updateComment: async (id, commentId, body) => {
    const requestUrl = api.task.updateComment.replace(':id', id).replace(':commentId', commentId);
    const data = await BaseServices.putDataToken(requestUrl, body);
    return data.json();
  },
  deleteComment: async (id, commentId) => {
    const requestUrl = api.task.deleteComment.replace(':id', id).replace(':commentId', commentId);
    const data = await BaseServices.deleteDataToken(requestUrl);
    return data.json();
  },
  deleteAttachment: async (taskId, fileId, params) => {
    const requestUrl = api.task.deleteAttachments.replace(':id', taskId).replace(':fileId', fileId);
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.deleteDataToken(url);
    return data.json();
  },
  getByCommentId: async ({ taskId, commentId }) => {
    const requestUrl = api.task.getCommentById.replace(':id', taskId).replace(':commentId', commentId);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  sortIndex: async (taskId, body) => {
    const requestUrl = api.task.sortIndex.replace(':id', taskId);
    const data = await BaseServices.postDataToken(requestUrl, body);
    return data.json();
  },
  getLabelS2: async (taskId) => {
    const requestUrl = api.task.getLabelS2.replace(':id', taskId);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  updateLabelS2: async (taskId, body) => {
    const requestUrl = api.task.updateLabelS2.replace(':id', taskId);
    const data = await BaseServices.putDataToken(requestUrl, body);
    return data.json();
  },
  getListAssignUser: async (params) => {
    const requestUrl = api.task.assignUsers;
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.getDataToken(url);
    return data.json();
  },
  simpleUpload: async (body) => {
    const requestUrl = api.task.simpleUpload;
    const data = await BaseServices.postDataTokenForm(requestUrl, body);
    return data.json();
  },
  getHistories: async (id) => {
    const requestUrl = api.task.getHistories.replace(':id', id);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  getNotifications: async (isUnRead) => {
    const requestUrl = api.task.getNotifications + '?' + 'isUnRead=' + isUnRead;
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  markAll: async () => {
    const requestUrl = api.task.markAll;
    const data = await BaseServices.putDataToken(requestUrl);
    return data.json();
  },
  readed: async (id) => {
    const requestUrl = api.task.readed.replace(':id', id);
    const data = await BaseServices.putDataToken(requestUrl);
    return data.json();
  },
  getCountPending: async (id) => {
    const requestUrl = api.task.countPending.replace(':companyId', id);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  getListDashboard: async (id) => {
    const requestUrl = api.task.dashboard.replace(':companyId', id);
    const data = await BaseServices.getDataToken(requestUrl);
    return data.json();
  },
  sendAttachments: async ({ id, companyId }) => {
    const requestUrl = api.task.sendAttachments.replace(':id', id);
    const data = await BaseServices.postDataToken(requestUrl, { companyId });
    return data.json();
  },
};
