/*
  Bank = CompanyBank tables
*/

import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  getBankBySwiftCode: async (swiftCode) => {
    try {
      const params = httpHelper.serialize({
        swiftCode,
      });
      const data = await BaseServices.getDataToken(`${api.bank.getBankBySwiftCode}?${params}`);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addBankAccount: async (params) => {
    try {
      const data = await BaseServices.postDataToken(api.bank.index, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async (query) => {
    try {
      const params = httpHelper.serialize(query);
      const url = `${api.bank.index}?${params}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getAllBankByCompany: async (companyId) => {
    try {
      const params = httpHelper.serialize({ companyId });
      const data = await BaseServices.getDataToken(`${api.bank.getByCompany}?${params}`);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  update: async (params) => {
    //  id, status, bankAccount,  bankName
    try {
      const data = await BaseServices.putDataToken(api.bank.index, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getBankByFilter: async (params) => {
    try {
      const paramString = httpHelper.serialize(params);
      const url = `${api.bank.getByFilter}?${paramString}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addAutoImport: async (params) => {
    //  id, status, accountName,  accountNumber
    try {
      const data = await BaseServices.postDataToken(api.bank.addAutoImport, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  updateAutoImport: async (params) => {
    //  id, status, accountName,  accountNumber
    try {
      const data = await BaseServices.putDataToken(api.bank.updateAutoImport, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  updateStatus: async (params) => {
    //  id, status, accountName,  accountNumber
    try {
      const data = await BaseServices.putDataToken(api.bank.updateStatus, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getBankBalance: async (params) => {
    const { source } = params;
    const urls = {
      DNB_53: api.dnb.getBankBalance,
      SP1_53: api.sp1.getBankBalance,
    };
    const requestUrl = urls[source];
    delete params.source;
    const paramString = httpHelper.serialize(params);
    const url = `${requestUrl}?${paramString}`;
    const data = await BaseServices.getBankDataToken(url);
    return await data.json();
  },
  getBankTransactionInfo: async (params) => {
    try {
      const requestUrl = api.transactionInfo.get;
      delete params.source;
      const paramString = httpHelper.serialize(params);
      const url = `${requestUrl}?${paramString}`;
      const data = await BaseServices.getBankDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getById: async (id) => {
    try {
      const data = await BaseServices.getDataToken(`${api.bank.getById}/${id}`);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getByTemplate: async (template) => {
    try {
      const data = await BaseServices.getDataToken(`${api.bank.getByTemplate}?template=${template}`);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchAdminAutoImport: async (query) => {
    try {
      const params = httpHelper.serialize(query);
      const url = `${api.bank.adminAutoImport}?${params}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
};
