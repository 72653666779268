/*
  Use for verify user and get ssn only
*/

import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import Actions from '../../../actions/3rd/signant.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import signantServices from '../../../../services/3rd/signant';
import rgbSsparebank1Service from '../../../../services/3rd/rgb/rgb.ssparebank1.service';
import companyUsersAssignPaymentServices from '../../../../services/companyUsersAssignPaymentServices';

export default function* handleAuthorizeCompanyBanksSaga() {
  yield takeEvery(actionTypes.SIGNANT.HANDLE_AUTHORIZE_COMANY_BANKS, handleAuthorizeCompanyBanks);
}

export function* handleAuthorizeCompanyBanks(action) {
  try {
    const response = yield call(signantServices.getAuthStatus, action.payload);
    const { data, success } = response;
    if (success) {
      const { UserSSN: ssn } = data;
      if (ssn) {
        // authorize with ssn
        const {
          organisationNumber, accountNumbers, companyId, assignUser
        } = action.payload;
        const arrAccountNumbers = accountNumbers.split(',');
        const authResponse = yield call(rgbSsparebank1Service.crsAuthorize, {
          organisationNumber,
          ssn,
          accountNumbers: arrAccountNumbers
        });
        if (authResponse?.success) {
          const { data: authorizeData } = authResponse;
          // clear signant
          yield put(Actions.signantAuthSuccess({}));
          // update to companyBankService (3)
          const isVerifieds = authorizeData.map(authData => {
            let result = 0;
            const { statusCode, resData } = authData;
            if (statusCode === 200) {
              const [approvaldData] = resData;
              const { approvalAccessIndicator } = approvaldData;
              if (approvalAccessIndicator === 'OK') result = 1;
            }
            return result;
          });
          // show loading again for authorize company bank
          yield put(Actions.handleAuthorizeCompanyBanks({}));
          const responseAddUpdate = yield call(companyUsersAssignPaymentServices.addUpdateBanksVerifiedPayment, {
            accountNumbers: arrAccountNumbers,
            companyId,
            isAllowPayments: isVerifieds,
            assignUser
          });
          if (responseAddUpdate.success) {
            yield put(Actions.handleAuthorizeCompanyBanksSuccess({ accountNumbers: arrAccountNumbers, authorizeData }));
          } else {
            yield put(Actions.handleAuthorizeCompanyBanksFail(responseAddUpdate.message));
          }
        } else {
          yield put(Actions.handleAuthorizeCompanyBanksFail(authResponse));
        }
      }
    } else {
      yield put(Actions.handleAuthorizeCompanyBanksFail(data));
    }
  } catch (error) {
    yield put(Actions.handleAuthorizeCompanyBanksFail(error));
  }
}
