import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import GlobalRuleServices from '../../../services/globalRuleServices';

export default function* updateGlobalRuleSaga() {
  yield takeEvery(actionTypes.GLOBAL_RULE.UPDATE_GLOBAL_RULE_REQUEST, updateGlobalRule);
}

export function* updateGlobalRule(action) {
  try {
    const response = yield call(GlobalRuleServices.update, action.payload);
    if (response.success) {
      yield put(actions.updateGlobalRuleSuccess(response.data));
    } else {
      yield put(actions.updateGlobalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.updateGlobalRuleFail(error));
  }
}
