import * as actionTypes from '../../actionTypes/ActionTypes';

export const psd2Sp1 = {
  crsAuthorize: payload => {
    return {
      type: actionTypes.PSD2_SP1.CRS_AUTHORIZE,
      payload
    };
  },
  crsAuthorizeSuccess: payload => {
    return {
      type: actionTypes.PSD2_SP1.CRS_AUTHORIZE_SUCCESS,
      payload
    };
  },
  crsAuthorizeFail: payload => {
    return {
      type: actionTypes.PSD2_SP1.CRS_AUTHORIZE_FAIL,
      payload
    };
  }
};
