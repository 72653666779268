import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyBankServices from '../../../services/companyBankServices';
import { bankNumber } from '../../../utils/Utils';

export default function* getBankAccounts() {
  yield takeEvery(actionTypes.SETTING_BANK.GET_SETTING_BANK_ACCOUNTS, requestBankAccounts);
}

export function* requestBankAccounts(action) {
  try {
    // save both alert and reminder data in 1 saga
    const response = yield call(companyBankServices.getSettingBankAccount, action.payload);
    if (response.success) {
      const result = response.data.map((item) => {
        const result = item;
        result.AccountNumber = bankNumber(item.AccountNumber);
        result.Type = item.Type && item.Type.toString();
        return result;
      });
      yield put(actions.settingBank.getBankAccountSuccess(result));
    }
    action?.callback && action?.callback();
    // if (response.success) {
    //   yield put(actions.addCompanyUsersSuccess(response.data));
    // } else {
    //   yield put(actions.addCompanyUsersFail(response.data));
    // }
  } catch (error) {
    action?.callback && action?.callback();
  }
}
