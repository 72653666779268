import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  getListInternalTransfer: async (params) => {
    try {
      const url = api.internalTransfer.getList.replace(':companyId', params.companyId);
      const queryParams = httpHelper.serialize(params);
      const data = await BaseServices.getDataToken(url + '?' + queryParams);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompanyBanks: async ({ companyId }) => {
    try {
      const url = api.internalTransfer.getCompanyBank + `?companyId=${companyId}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  insertTransactionTransfer: async ({ companyId, payload }) => {
    try {
      const url = api.internalTransfer.insertTransaction.replace(':companyId', companyId);
      const data = await BaseServices.postDataToken(url, payload);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  addInternalTransfer: async (params = {}) => {
    try {
      const url = api.voucherPaymentsV2.addInternalTransfer;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
};
