import BaseServices from './signantBaseServices';
import SignantConstants from '../../../constants/3rd/signant.constant';
import httpHelper from '../../../helpers/httpHelper';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  initAuth: async params => {
    try {
      const url = SignantConstants.api.initAuth;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  getAuthStatus: async params => {
    try {
      const { requestID, trackingID } = params;
      const query = httpHelper.serialize({ requestID, trackingID });
      const url = SignantConstants.api.getAuthStatus + `?${query}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, error };
    }
  }
};
