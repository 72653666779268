import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  fetchAll: async params => {
    try {
      const queryString = httpHelper.serialize(params);
      const url = api.paymentPackages.fetchAll + `?${queryString}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
