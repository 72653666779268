import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions/voucherPaymentInternal.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getPaymentActionsInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_PAYMENT_ACTIONS, getPaymentActions);
}

export function* getPaymentActions(action) {
  try {
    const response = yield call(VoucherPaymentServices.getPaymentActions, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.getPaymentActionsSuccessInternal(data));
    } else {
      yield put(actions.getPaymentActionsFailInternal(response.message));
    }
  } catch (error) {
    yield put(actions.getPaymentActionsFailInternal(error));
  }
}
