import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import LocaRuleServices from '../../../../services/localRuleServices';

export default function* fetchAllGlobalRuleSaga() {
  yield takeEvery(actionTypes.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_REQUEST, fetchAllLocalRule);
}

export function* fetchAllLocalRule(action) {
  try {
    const response = yield call(LocaRuleServices.fetchAll, action.payload);
    if (response.success) {
      yield put(actions.fetchAllLocalRuleSuccess(response.data));
    } else {
      yield put(actions.fetchAllLocalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.fetchAllLocalRuleFail(error));
  }
}
