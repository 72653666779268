import * as Config from '../../configs/server.configs';

const endPoint = Config[process.env.NODE_ENV].rgbEndpoint;
// const endPoint = 'https://sandboxapi.psd.dnb.no';

export default {
  sparebank1: {
    crsAuthorize: `${endPoint}/crs/v1/authorize`
  },
  payments: {
    add: `${endPoint}/api/v1/payments`,
    get: `${endPoint}/api/v1/payments`,
    checkDuplicate: `${endPoint}/api/v1/payments/check-duplicate`,
    update: `${endPoint}/api/v1/payments/:id`,
    savePaymentsBaskets: `${endPoint}/api/v1/payments/save-payments-baskets`, // save both payments and bastket in 1 api
    saveListPaymentsBaskets: `${endPoint}/api/v1/payments/save-list-payments-baskets`, // save both payments and bastket in 1 api
    handleUpdatePaymentsByBasketUUID: `${endPoint}/api/v1/payments/handle-update-payments-by-basketUuid`, // update to signingBasket
    signingBaskets: `${endPoint}/api/v1/payments/signing-baskets/:basketUUID`, // update to signingBasket
    signingBasketStatus: `${endPoint}/api/v1/payments/signing-baskets/:basketUUID/status`, // update to signingBasket
    updatePaymentStatus: `${endPoint}/api/v1/payments/update-payment-status`
  },
  paymentsAuths: {
    add: `${endPoint}/api/v1/payments-auths`,
    updateByBasketUUID: `${endPoint}/api/v1/payments-auths/update-by-basketUuid`
  },
  paymentsBaskets: {
    getByBasketUUID: `${endPoint}/api/v1/payments-baskets/get-by-basketUuid`
  }
};
