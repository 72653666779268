import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyBankServices from '../../../services/companyBankServices';

export default function* buyRemittanceSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK_SERVICE.BUY_REMITTANCE_REQUEST, buyRemittance);
}

export function* buyRemittance(action) {
  try {
    const response = yield call(companyBankServices.buyRemittance, action.payload);
    const { data, success } = response;
    if (success) {
      yield put(actions.buyRemittanceSuccess(data));
    } else {
      yield put(actions.buyRemittanceFail(data));
    }
  } catch (error) {
    yield put(actions.buyRemittanceFail(error));
  }
}
