import * as types from '../actionTypes/ActionTypes';

export const settingBank = {
  setShowModal: (payload) => ({
    type: types.SETTING_BANK.SET_SHOW_MODAL,
    payload,
  }),
  setShowBankAccountTable: (payload) => ({
    type: types.SETTING_BANK.SET_SHOW_BANK_ACCOUNT_TABLE,
    payload,
  }),
  getBankAccountSuccess: (payload) => ({
    type: types.SETTING_BANK.GET_SETTING_BANK_ACCOUNTS_SUCCESS,
    payload,
  }),
  getBankAccount: (payload, callback) => ({
    type: types.SETTING_BANK.GET_SETTING_BANK_ACCOUNTS,
    payload,
    callback,
  }),
};
