import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';
import * as actions from '../../actions/voucherPayment.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getVoucherPaymentStatisticSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC, getVoucherPaymentStatistic);
}

export function* getVoucherPaymentStatistic(action) {
  try {
    const response = yield call(VoucherPaymentServices.getStatistic, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.getVoucherPaymentStatisticSuccess(data));
    } else {
      yield put(actions.getVoucherPaymentStatisticFail(response.message));
    }
  } catch (error) {
    yield put(actions.getVoucherPaymentStatisticFail(error));
  }
}
