import { CONSENT_ACTION_TYPES } from '../actionTypes/consent.actionTypes';
// INIT
const initialState = {
  isShowCreateConsentModal: false,
  isShowRenewConsentModal: false,
  consents: []
};

const consent = (state = initialState, action) => {
  switch (action.type) {
    case CONSENT_ACTION_TYPES.SET_SHOW_CREATE_CONSENT_MODAL:
      return {
        ...state,
        isShowCreateConsentModal: action.payload,
      };
    case CONSENT_ACTION_TYPES.SET_SHOW_CREATE_CONSENT_MODAL:
      return {
        ...state,
        isShowCreateConsentModal: action.payload,
      };
    case CONSENT_ACTION_TYPES.SET_SHOW_RENEW_CONSENT_MODAL:
      return {
        ...state,
        isShowRenewConsentModal: action.payload,
      };
    case CONSENT_ACTION_TYPES.SET_CONSENTS:
      return {
        ...state,
        consents: action.payload,
      };
    default:
      return state;
  }
};

export default consent;
