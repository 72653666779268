import * as actionTypes from '../../actionTypes/3rd/rgb.actionTypes';

export const rgb = {
  updatePaymentStatus: payload => {
    return {
      type: actionTypes.RGB.UPDATE_PAYMENT_STATUS,
      payload
    };
  },
  updatePaymentStatusSuccess: payload => {
    return {
      type: actionTypes.RGB.UPDATE_PAYMENT_STATUS_SUCCESS,
      payload
    };
  },
  updatePaymentStatusFail: payload => {
    return {
      type: actionTypes.RGB.UPDATE_PAYMENT_STATUS_FAIL,
      payload
    };
  }
};
