import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyServices from '../../../services/companyServices';

export default function* addCompanyServiceSaga() {
  yield takeEvery(actionTypes.COMPANY_SERVICE.ADD_COMPANY_SERVICE_REQUEST, addCompanyServices);
}

export function* addCompanyServices(action) {
  try {
    const response = yield call(companyServices.add, action.payload);
    const { success } = response;
    if (success) {
      yield put(actions.addCompanyServiceSuccess(response.data));
      yield put(actions.fetchAllCompanyService({ companyId: action.payload.companyId }));
    }
  } catch (error) {
    yield put(actions.addCompanyServiceFail(error));
  }
}
