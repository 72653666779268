export const VOUCHER_PAYMENT_INTERNAL = {
  INTERNAL_SET_PAYMENT_LIST_TABLE_CHECKED: 'INTERNAL_SET_PAYMENT_LIST_TABLE_CHECKED',
  INTERNAL_SET_CURRENT_VOUCHER_PAYMENT: 'INTERNAL_SET_CURRENT_VOUCHER_PAYMENT',
  INTERNAL_SET_OPEN_FULLSCREEN: 'INTERNAL_SET_OPEN_FULLSCREEN',
  INTERNAL_SET_FILTER: 'INTERNAL_SET_FILTER',
  INTERNAL_SET_SHOW_PAYMENT_OPTIONS: 'INTERNAL_SET_SHOW_PAYMENT_OPTIONS',

  INTERNAL_ADD_TO_PAYMENT_LIST_REQUEST: 'INTERNAL_ADD_TO_PAYMENT_LIST_REQUEST',
  INTERNAL_ADD_TO_PAYMENT_LIST_SUCCESS: 'INTERNAL_ADD_TO_PAYMENT_LIST_SUCCESS',
  INTERNAL_ADD_TO_PAYMENT_LIST_FAIL: 'INTERNAL_ADD_TO_PAYMENT_LIST_FAIL',

  INTERNAL_FETCH_VOUCHER_PAYMENT_REQUEST: 'INTERNAL_FETCH_VOUCHER_PAYMENT_REQUEST',
  INTERNAL_FETCH_VOUCHER_PAYMENT_SUCCESS: 'INTERNAL_FETCH_VOUCHER_PAYMENT_SUCCESS',
  INTERNAL_FETCH_VOUCHER_PAYMENT_FAIL: 'INTERNAL_FETCH_VOUCHER_PAYMENT_FAIL',

  INTERNAL_PAY_TO_BANK_REQUEST: 'INTERNAL_PAY_TO_BANK_REQUEST',
  INTERNAL_PAY_TO_BANK_SUCCESS: 'INTERNAL_PAY_TO_BANK_SUCCESS',
  INTERNAL_PAY_TO_BANK_FAIL: 'INTERNAL_PAY_TO_BANK_FAIL',

  INTERNAL_SAVE_VOUCHER_PAYMENT_REQUEST: 'INTERNAL_SAVE_VOUCHER_PAYMENT_REQUEST',
  INTERNAL_SAVE_VOUCHER_PAYMENT_SUCCESS: 'INTERNAL_SAVE_VOUCHER_PAYMENT_SUCCESS',
  INTERNAL_SAVE_VOUCHER_PAYMENT_FAIL: 'INTERNAL_SAVE_VOUCHER_PAYMENT_FAIL',

  INTERNAL_GET_BY_VOUCHER_ID_REQUEST: 'INTERNAL_GET_BY_VOUCHER_ID_REQUEST',
  INTERNAL_GET_BY_VOUCHER_ID_SUCCESS: 'INTERNAL_GET_BY_VOUCHER_ID_SUCCESS',
  INTERNAL_GET_BY_VOUCHER_ID_FAIL: 'INTERNAL_GET_BY_VOUCHER_ID_FAIL',

  INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS: 'INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS',
  INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS: 'INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS',
  INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL: 'INTERNAL_UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL',

  INTERNAL_GET_BY_PAYMENTS_UUID_REQUEST: 'INTERNAL_GET_BY_PAYMENTS_UUID_REQUEST',
  INTERNAL_GET_BY_PAYMENTS_UUID_SUCCESS: 'INTERNAL_GET_BY_PAYMENTS_UUID_SUCCESS',
  INTERNAL_GET_BY_PAYMENTS_UUID_FAIL: 'INTERNAL_GET_BY_PAYMENTS_UUID_FAIL',

  INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC: 'INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC',
  INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS: 'INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS',
  INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_FAIL: 'INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC_FAIL',

  INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST: 'INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST',
  INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS: 'INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS',
  INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL: 'INTERNAL_ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL',

  INTERNAL_GET_PAYMENT_ACTIONS: 'INTERNAL_GET_PAYMENT_ACTIONS',
  INTERNAL_GET_PAYMENT_ACTIONS_SUCCESS: 'INTERNAL_GET_PAYMENT_ACTIONS_SUCCESS',
  INTERNAL_GET_PAYMENT_ACTIONS_FAIL: 'INTERNAL_GET_PAYMENT_ACTIONS_FAIL',
  // TYPE LOADING DELETE
  INTERNAL_DELETE_RECORD_PAYMENT_REQUEST: 'INTERNAL_DELETE_RECORD_PAYMENT_REQUEST',
  INTERNAL_DELETE_RECORD_PAYMENT_SUCCESS: 'INTERNAL_DELETE_RECORD_PAYMENT_SUCCESS',
};
