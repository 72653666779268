import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isOpenNotification: false,
  loading: false,
  isUnRead: 0,
  todays: [],
  older: [],
  countUnRead: 0,
  ztlNotifications: {
    total: 0,
    data: []
  }
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case types.NOTIFICATION.SET_OPEN_NOTIFICATION:
      return {
        ...state,
        isOpenNotification: action.payload,
      };
    case types.NOTIFICATION.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.NOTIFICATION.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case types.NOTIFICATION.CHANGE_IS_READ:
      return {
        ...state,
        isUnRead: action.payload,
      };
    case types.NOTIFICATION.SET_ZTL_NOTIFICATION:
      return {
        ...state,
        ztlNotifications: action.payload,
      };
    default:
      return state;
  }
};

export default notification;
