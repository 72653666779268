import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import inboxServices from '../../../services/inboxServices';

export default function* getInboxDataSaga() {
  yield takeEvery(actionTypes.INBOX.GET_INBOX_DATA_REQUEST, getInboxData);
}

export function* getInboxData(action) {
  try {
    // get innboxDetail
    const inboxRes = yield call(inboxServices.getInboxByUUID, action.payload);
    let { isSuccess, data } = inboxRes;
    if (isSuccess) {
      yield put(actions.getInboxDataSuccess(data));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getInboxDataFail(error));
  }
}
