import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import BankServices from '../../../services/bankServices';

export default function* fetchBankByCompanySaga() {
  yield takeEvery(actionTypes.BANK.FETCH_BANK_BY_COMPANY_REQUEST, fetchBankByCompany);
}

export function* fetchBankByCompany(action) {
  try {
    // get company
    // 1- get years
    const response = yield call(BankServices.getAllBankByCompany, action.payload) || {};
    const { success, data } = response;
    if (success) {
      yield put(actions.fetchBankByCompanySuccess(data));
    } else {
      yield put(actions.fetchBankByCompanyFail(data));
    }
  } catch (error) {
    yield put(actions.fetchBankDataFail(error));
  }
}
