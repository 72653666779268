import * as types from '../actionTypes/ActionTypes';
import { COLUMN_DEFINE_DEFAULT } from '../actionTypes/bank.actionTypes';

export const resetFilter = {
  years: null,
  year: null,
  sortBy: 'BankTransaction.Date',
  sortType: 'desc',
  month: 0,
  from: '',
  to: '',
  companyBankId: 0,
  accountNumber: null,
  pageNumber: 1,
  pageSize: 50,
  status: 0,
  keyword: '',
  filterAmount: 'all',
  isCheckAll: false,
};

const filter = { ...resetFilter };

const initialState = {
  currenBankBalance: null,
  currentTransaction: null,
  bankStatisticData: {
    statis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  bankTransactionData: {
    total: 0,
    rows: [],
  },
  banksByCompany: [],
  bankByFilter: [],
  loading: {
    swiftCode: false,
    statistic: false,
    bankTransaction: false,
    banksByCompany: false,
    bankByFilter: false,
  },
  filter,
  swiftCode: null,
  bankImport: {
    companyId: null,
    columnDefine: COLUMN_DEFINE_DEFAULT,
    columnByTable: [null, null, null, null, null, null],
    dataRows: [],
    columnExels: [],
  },
  bankDetail: {
    selectTab: 0,
    selectVoucher: {},
  },
};

const bank = (state = initialState, action) => {
  switch (action.type) {
    case types.BANK.SET_CURRENT_BANK_BALANCE:
      return {
        ...state,
        currenBankBalance: action.payload,
      };
    case types.BANK.SET_TAB_BANK_DETAIL:
      return {
        ...state,
        bankDetail: {
          ...state.bankDetail,
          ...action.payload,
        },
      };
    case types.BANK.SET_FILTER_BANK:
      return {
        ...state,
        filter: action.payload,
      };
    case types.BANK.SET_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransaction: action.payload,
      };
    case types.BANK.RESET_FILTER_BANK:
      return {
        ...state,
        filter: {
          ...resetFilter,
          ...(action.payload ?? {}),
        },
      };
    case types.BANK.FETCH_BANK_DATA_REQUEST:
      return {
        ...state,
        loading: {
          statistic: true,
          bankTransaction: true,
        },
      };
    case types.BANK.FETCH_BANK_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          statistic: false,
          bankTransaction: false,
        },
        bankStatisticData: action.payload.bankStatisticData,
        bankTransactionData: action.payload.bankTransactionData,
      };
    case types.BANK.FETCH_BANK_DATA_FAIL:
      return {
        ...state,
        loading: {
          statistic: false,
          bankTransaction: false,
        },
      };
    case types.BANK.FETCH_BANK_BY_COMPANY_REQUEST:
      return {
        ...state,
        loading: {
          banksByCompany: true,
        },
      };
    case types.BANK.FETCH_BANK_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: {
          banksByCompany: false,
        },
        banksByCompany: action.payload,
      };
    case types.BANK.FETCH_BANK_BY_COMPANY_FAIL:
      return {
        ...state,
        loading: {
          banksByCompany: false,
        },
      };
    case types.BANK.SET_BANK_TRANSACTION:
      return {
        ...state,
        bankTransactionData: action.payload,
      };
    case types.BANK.GET_BANK_BY_SWIFT_CODE_REQUEST: {
      return {
        ...state,
        loading: {
          swiftCode: true,
        },
      };
    }
    case types.BANK.GET_BANK_BY_SWIFT_CODE_SUCCESS: {
      return {
        ...state,
        loading: {
          swiftCode: false,
        },
        swiftCode: action.payload,
      };
    }
    case types.BANK.GET_BANK_BY_SWIFT_CODE_FAIL: {
      return {
        ...state,
        loading: {
          swiftCode: false,
        },
      };
    }
    case types.BANK.GET_BANK_BY_FILTER_REQUEST: {
      return {
        ...state,
        loading: {
          bankByFilter: true,
        },
      };
    }
    case types.BANK.GET_BANK_BY_FILTER_SUCCESS: {
      return {
        ...state,
        loading: {
          bankByFilter: false,
        },
        bankByFilter: action.payload,
      };
    }
    case types.BANK.GET_BANK_BY_FILTER_FAIL: {
      return {
        ...state,
        loading: {
          bankByFilter: false,
        },
      };
    }
    case types.BANK.CHANGE_COLUMN_DEFINE_IMPORT:
    case types.BANK.CHANGE_DATA_IMPORT:
    case types.BANK.RESET_DATA_IMPORT:
      return {
        ...state,
        bankImport: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default bank;
