import actions from 'redux-form/lib/actions';
import timeHelper from '../../helpers/timeHelper';
import * as types from '../actionTypes/voucherPayment.actionTypes';
// INIT
const filter = {
  isShowDate: null,
  pageSize: 10,
  pageNumber: 1,
  statuses: ['0'],
  from: timeHelper.monthAgo(),
  paymentSource: -1,
  to: null,
  keyword: '',
  sortType: 'asc',
  typeDate: -1,
};

const cloneFilter = { ...filter };

const initialState = {
  filter: cloneFilter,
  isOpenFullScreen: false,
  voucherPaymentData: {
    total: 0,
    rows: [],
  },
  voucherPaymentByVoucherId: [],
  voucherPaymentByUUID: {},
  currentVoucherPayment: {},
  voucherPaymentStatistic: null,
  loading: {
    addToPaymentList: null,
    fetchVoucherPayments: null,
    getVoucherPaymentByVoucherId: null,
    getVoucherPaymentByUUID: null,
  },
  isAddUnpaidEhfVoucherToPaymentList: null,
  isGetVoucherPaymentStatistic: null,
  arrPaymentListTableChecked: [],
  isShowPaymentOptions: false,
  paymentActions: {},
  isGettingPaymentActions: false,
};

const voucherPayment = (state = initialState, action) => {
  switch (action.type) {
    case types.VOUCHER_PAYMENT.SET_SHOW_PAYMENT_OPTIONS:
      return {
        ...state,
        isShowPaymentOptions: action.payload,
      };
    case types.VOUCHER_PAYMENT.SET_PAYMENT_LIST_TABLE_CHECKED:
      return {
        ...state,
        arrPaymentListTableChecked: action.payload,
      };
    case types.VOUCHER_PAYMENT.SET_CURRENT_VOUCHER_PAYMENT:
      return {
        ...state,
        currentVoucherPayment: action.payload,
      };
    case types.VOUCHER_PAYMENT.SET_OPEN_FULLSCREEN:
      return {
        ...state,
        isOpenFullScreen: action.payload,
      };
    case types.VOUCHER_PAYMENT.SET_FILTER:
      return {
        ...state,
        filter: action.payload === null ? cloneFilter : action.payload,
      };
    case types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: {
          addToPaymentList: true,
        },
      };
    case types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: {
          addToPaymentList: false,
        },
      };
    case types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_FAIL:
      return {
        ...state,
        loading: {
          addToPaymentList: false,
        },
      };
    case types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_REQUEST:
      return {
        ...state,
        loading: {
          fetchVoucherPayments: true,
        },
      };
    case types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_SUCCESS:
      return {
        ...state,
        voucherPaymentData: action.payload,
        loading: {
          fetchVoucherPayments: false,
        },
      };
    case types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_FAIL:
      return {
        ...state,
        loading: {
          fetchVoucherPayments: false,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_REQUEST:
      return {
        ...state,
        loading: {
          getVoucherPaymentByVoucherId: true,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_SUCCESS:
      return {
        ...state,
        voucherPaymentByVoucherId: action.payload,
        loading: {
          getVoucherPaymentByVoucherId: false,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_FAIL:
      return {
        ...state,
        loading: {
          getVoucherPaymentByVoucherId: false,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_REQUEST:
      return {
        ...state,
        loading: {
          getVoucherPaymentByUUID: true,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_SUCCESS:
      return {
        ...state,
        voucherPaymentByUUID: action.payload,
        loading: {
          getVoucherPaymentByUUID: false,
        },
      };
    case types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_FAIL:
      return {
        ...state,
        loading: {
          getVoucherPaymentByUUID: false,
        },
      };
    case types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC:
      return {
        ...state,
        isGetVoucherPaymentStatistic: true,
      };
    case types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS:
      return {
        ...state,
        voucherPaymentStatistic: action.payload,
        isGetVoucherPaymentStatistic: false,
      };
    case types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC_FAIL:
      return {
        ...state,
        isGetVoucherPaymentStatistic: false,
      };
    case types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST:
      return {
        ...state,
        isAddUnpaidEhfVoucherToPaymentList: true,
      };
    case types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        isAddUnpaidEhfVoucherToPaymentList: false,
      };
    case types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL:
      return {
        ...state,
        isAddUnpaidEhfVoucherToPaymentList: false,
      };
    case types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS:
      return {
        ...state,
        isGettingPaymentActions: true,
      };
    case types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS_SUCCESS:
      return {
        ...state,
        paymentActions: action.payload,
        isGettingPaymentActions: false,
      };
    case types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS_FAIL:
      return {
        ...state,
        isGettingPaymentActions: false,
      };
    default:
      return state;
  }
};

export default voucherPayment;
