import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import LocaRuleServices from '../../../../services/localRuleServices';

export default function* copyLocalRuleSaga() {
  yield takeEvery(actionTypes.LOCAL_RULE.COPY_LOCAL_RULE_REQUEST, copyLocalRule);
}

export function* copyLocalRule(action) {
  try {
    const response = yield call(LocaRuleServices.copy, action.payload);
    if (response.success) {
      yield put(actions.copyLocalRuleSuccess(response.data));
    } else {
      yield put(actions.copyLocalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.copyLocalRuleFail(error));
  }
}
