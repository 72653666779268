
export const BANK_TRANSACTION = {
  GET_TRANSACTION_MISSING_SUMMARY_REQUEST: 'GET_TRANSACTION_MISSING_SUMMARY_REQUEST',
  GET_TRANSACTION_MISSING_SUMMARY_SUCCESS: 'GET_TRANSACTION_MISSING_SUMMARY_SUCCESS',
  GET_TRANSACTION_MISSING_SUMMARY_FAIL: 'GET_TRANSACTION_MISSING_SUMMARY_FAIL',

  GET_SUGGEST_TRANSACTION_REQUEST: 'GET_SUGGEST_TRANSACTION_REQUEST',
  GET_SUGGEST_TRANSACTION_SUCCESS: 'GET_SUGGEST_TRANSACTION_SUCCESS',
  GET_SUGGEST_TRANSACTION_FAIL: 'GET_SUGGEST_TRANSACTION_FAIL',

  FETCH_BANK_TRANSACTION_BY_ID_REQUEST: 'FETCH_BANK_TRANSACTION_BY_ID_REQUEST',
  FETCH_BANK_TRANSACTION_BY_ID_SUCCESS: 'FETCH_BANK_TRANSACTION_BY_ID_SUCCESS',
  FETCH_BANK_TRANSACTION_BY_ID_FAIL: 'FETCH_BANK_TRANSACTION_BY_ID_FAIL',

  GET_HISTORY: 'GET_HISTORY',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_HISTORY_FAIL: 'GET_HISTORY_FAIL'
};
