
export const COMPANY_USERS = {
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SET_OPEN_MODAL: 'SET_OPEN_MODAL',
  FETCH_ALL_COMPANY_USERS_REQUEST: 'FETCH_ALL_COMPANY_USERS_REQUEST',
  FETCH_ALL_COMPANY_USERS_SUCCESS: 'FETCH_ALL_COMPANY_USERS_SUCCESS',
  FETCH_ALL_COMPANY_USERS_FAIL: 'FETCH_ALL_COMPANY_USERS_FAIL',
  DELETE_COMPANY_USERS_REQUEST: 'DELETE_COMPANY_USERS_REQUEST',
  DELETE_COMPANY_USERS_SUCCESS: 'DELETE_COMPANY_USERS_SUCCESS',
  DELETE_COMPANY_USERS_FAIL: 'DELETE_COMPANY_USERS_FAIL',
  ADD_COMPANY_USERS_REQUEST: 'ADD_COMPANY_USERS_REQUEST',
  ADD_COMPANY_USERS_SUCCESS: 'ADD_COMPANY_USERS_SUCCESS',
  ADD_COMPANY_USERS_FAIL: 'ADD_COMPANY_USERS_FAIL'
};
