import * as types from '../actionTypes/ActionTypes';

export const addCompanyUsersAlertVoucher = payload => ({
  type: types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_REQUEST,
  payload
});
export const addCompanyUsersAlertVoucherSuccess = payload => ({
  type: types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_SUCCESS,
  payload
});
export const addCompanyUsersAlertVoucherFail = payload => ({
  type: types.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_FAIL,
  payload
});
