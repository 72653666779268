import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherServices from '../../../services/voucherServices';

export default function* fetchVoucherBalanceSaga() {
  yield takeEvery(actionTypes.VOUCHER.GET_VOUCHER_BALANCE_REQUEST, getVoucherBalance);
}

export function* getVoucherBalance(action) {
  try {
    const response = yield call(VoucherServices.getVoucherBalance, action.payload);
    if (response.success) {
      yield put(actions.getVoucherBalanceSuccess(response.data));
    } else {
      yield put(actions.getVoucherBalanceFail(response.data));
    }
  } catch (error) {
    yield put(actions.getVoucherBalanceFail(error));
  }
}
