import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import CompanyUsersAlertVoucherServices from '../../../services/companyUsersAlertVoucherServices';

export default function* addCompanyUsersAlertVoucherSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_ALERT_VOUCHER.ADD_COMPANY_USERS_ALERT_VOUCHER_REQUEST, addCompanyUsersAlertVoucher);
}

export function* addCompanyUsersAlertVoucher(action) {
  try {
    const response = yield call(CompanyUsersAlertVoucherServices.add, action.payload);
    if (response.success) {
      yield put(actions.addCompanyUsersAlertVoucherSuccess(response.data));
    } else {
      yield put(actions.addCompanyUsersAlertVoucherFail(response.data));
    }
  } catch (error) {
    yield put(actions.addCompanyUsersAlertVoucherFail(error));
  }
}
