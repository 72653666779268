import * as actionTypes from '../actionTypes/ActionTypes';

export const companyBank = {
  fetchAllCompanyBankInfoByComapyId: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID,
      payload,
    };
  },
  fetchAllCompanyBankInfoByComapyIdSuccess: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_SUCCESS,
      payload,
    };
  },
  fetchAllCompanyBankInfoByComapyIdFail: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID_FAIL,
      payload,
    };
  },
  getBankBalanceByAccountNumber: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER,
      payload,
    };
  },
  getBankBalanceByAccountNumberSuccess: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_SUCCESS,
      payload,
    };
  },
  getBankBalanceByAccountNumberFail: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_BANK_BALANCE_BY_ACCOUNT_NUMBER_FAIL,
      payload,
    };
  },
  setDefaultAccountNumber: (payload, callback) => {
    return {
      type: actionTypes.COMPANY_BANK.SET_DEFAULT_ACCOUNT_NUMBER,
      payload,
      callback,
    };
  },
  setDefaultAccountNumberSuccess: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.SET_DEFAULT_ACCOUNT_NUMBER_SUCCESS,
      payload,
    };
  },
  setDefaultAccountNumberFail: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.SET_DEFAULT_ACCOUNT_NUMBER_FAIL,
      payload,
    };
  },
  deleteCompanyBank: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.DELETE_COMPANY_BANK,
      payload,
    };
  },
  deleteCompanyBankSuccess: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.DELETE_COMPANY_BANK_SUCCESS,
      payload,
    };
  },
  deleteCompanyBankFail: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.DELETE_COMPANY_BANK_FAIL,
      payload,
    };
  },
  getDefaultAccountNumber: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER,
      payload,
    };
  },
  getDefaultAccountNumberSuccess: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER_SUCCESS,
      payload,
    };
  },
  getDefaultAccountNumberFail: (payload) => {
    return {
      type: actionTypes.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER_FAIL,
      payload,
    };
  },
};
