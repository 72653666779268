import * as types from '../actionTypes/ActionTypes';

export const getTransactionMissingSummary = payload => ({
  type: types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_REQUEST,
  payload
});

export const getTransactionMissingSummarySuccess = payload => ({
  type: types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_SUCCESS,
  payload
});

export const getTransactionMissingSummaryFail = payload => ({
  type: types.BANK_TRANSACTION.GET_TRANSACTION_MISSING_SUMMARY_FAIL,
  payload
});

export const getSuggestTransaction = payload => ({
  type: types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_REQUEST,
  payload
});

export const getSuggestTransactionSuccess = payload => ({
  type: types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_SUCCESS,
  payload
});

export const getSuggestTransactionFail = payload => ({
  type: types.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_FAIL,
  payload
});

export const updateMissingTransaction = payload => ({
  type: types.BANK_TRANSACTION.UPDATE_MISSING_TRANSACTION_REQUEST,
  payload
});

export const updateMissingTransactionSuccess = payload => ({
  type: types.BANK_TRANSACTION.UPDATE_MISSING_TRANSACTION_SUCCESS,
  payload
});

export const updateMissingTransactionFail = payload => ({
  type: types.BANK_TRANSACTION.UPDATE_MISSING_TRANSACTION_FAIL,
  payload
});

export const fetchBankTransactionById = payload => ({
  type: types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_REQUEST,
  payload
});
export const fetchBankTransactionByIdSuccess = payload => ({
  type: types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_SUCCESS,
  payload
});
export const fetchBankTransactionByIdFail = payload => ({
  type: types.BANK_TRANSACTION.FETCH_BANK_TRANSACTION_BY_ID_FAIL,
  payload
});


export const getHistory = payload => ({
  type: types.BANK_TRANSACTION.GET_HISTORY,
  payload
});
export const getHistorySuccess = payload => ({
  type: types.BANK_TRANSACTION.GET_HISTORY_SUCCESS,
  payload
});
export const getHistoryFail = payload => ({
  type: types.BANK_TRANSACTION.GET_HISTORY_FAIL,
  payload
});
