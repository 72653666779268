import * as types from '../actionTypes/ActionTypes';

export const paymentPackages = {
  setCurrentPaymentPackage: payload => ({
    type: types.PAYMENT_PACKAGES.SET_CURRENT_PAYMENT_PACKAGE,
    payload
  }),
  fetchAllRequest: payload => ({
    type: types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_REQUEST,
    payload
  }),
  fetchAllSuccess: payload => ({
    type: types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_SUCCESS,
    payload
  }),
  fetchAllFail: payload => ({
    type: types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_FAIL,
    payload
  })
};
