import { put, takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyServices from '../../../services/companyServices';
import { getCompanyDidivisionByOrgFail, getCompanyDidivisionByOrgSuccess } from '../../actions';

export default function* getCompanyDivisionByOrgSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_DIVISION_BY_ORG, getCompanyDivisionByOrg);
}

export function* getCompanyDivisionByOrg(action) {
  try {
    const response = yield call(companyServices.getCompanyDivisionByOrg, action.payload);
    const { success, data, message } = response;
    if (success) {
      yield put(getCompanyDidivisionByOrgSuccess(data));
    } else {
      yield put(getCompanyDidivisionByOrgFail(message));
    }
  } catch (error) {
    yield put(getCompanyDidivisionByOrgFail(error));
  }
}
