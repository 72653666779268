import { api } from '../../constants/api.constant';
import { APIError } from '../../constants/errorCode/system';
import BaseServices from '../baseServices';

export const voucherServicesV2 = {
  getVoucherBalances: async (params) => {
    try {
      const { id } = params;
      const url = api.voucherV2.getVoucherBalances.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addBalance: async (params) => {
    try {
      const url = api.voucherV2.addVoucherBalances.replace(':id', params.voucherId);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      console.log(24, error);
      return { success: false, code: APIError.ConnectFail };
    }
  },
  removeBalance: async (params) => {
    try {
      const url = api.voucherV2.removeVoucherBalances
        .replace(':id', params.voucherId)
        .replace(':voucherBalanceId', params.id)
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
