import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import CompanyUsersServices from '../../../services/companyUsersServices';

export default function* addCompanyUsersSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS.ADD_COMPANY_USERS_REQUEST, addCompanyUsers);
}

export function* addCompanyUsers(action) {
  try {
    const response = yield call(CompanyUsersServices.add, action.payload);
    if (response.success) {
      yield put(actions.addCompanyUsersSuccess(response.data));
    } else {
      yield put(actions.addCompanyUsersFail(response.data));
    }
  } catch (error) {
    yield put(actions.addCompanyUsersFail(error));
  }
}
