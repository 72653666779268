import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  selectedUser: {}
};

const companyUsersReminder = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_USERS_REMINDER.ADD_REMINDER_REQUEST:
      return {
        ...state,
        selectedUser: action.payload
      };
    case types.COMPANY_USERS_REMINDER.ADD_REMINDER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload
      };
    case types.COMPANY_USERS_REMINDER.ADD_REMINDER_FAIL:
      return {
        ...state,
        selectedUser: action.payload
      };
    default:
      return state;
  }
};

export default companyUsersReminder;
