import * as actionTypes from '../actionTypes/ActionTypes';

export const getForwardSuccessData = payload => {
  return {
    type: actionTypes.ADMIN_EMAIL.GET_FORWARD_SUCCESS_DATA,
    payload
  };
};
export const setForwardSuccessData = payload => {
  return {
    type: actionTypes.ADMIN_EMAIL.SET_FORWARD_SUCCESS_DATA,
    payload
  };
};
