import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyBankServices from '../../../services/companyBankServices';

export default function* getDefaultAccountNumberSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK.GET_DEFAULT_ACCOUNT_NUMBER, getDefaultAccountNumber);
}

export function* getDefaultAccountNumber(action) {
  try {
    const response = yield call(companyBankServices.getDefaultAccountNumber, action.payload);
    const { success, data, message } = response;
    if (success) {
      yield put(actions.companyBank.getDefaultAccountNumberSuccess(data));
    } else {
      yield put(actions.companyBank.getDefaultAccountNumberFail(message));
    }
  } catch (error) {
    yield put(actions.companyBank.getDefaultAccountNumberFail(error));
  }
}
