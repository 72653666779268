import * as types from '../../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isUpdating: false
  }
};

const rgbPayments = (state = initialState, action) => {
  switch (action.type) {
    case types.RGB.UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        loading: {
          isUpdating: true
        }
      };
    case types.RGB.UPDATE_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: {
          isUpdating: false
        }
      };
    case types.RGB.UPDATE_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        loading: {
          isUpdating: false
        }
      };
    default:
      return state;
  }
};

export default rgbPayments;
