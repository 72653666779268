import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  count: 0,
};

const TaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TASK.SET_COUNT_PENDING:
      return {
        count: action.payload,
      };
    default:
      return state;
  }
};

export default TaskReducer;
