import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyUsersAssignPaymentServices from '../../../services/companyUsersAssignPaymentServices';

export default function* fetchAssignUsersByCompanySaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_ASSIGN_PAYMENT.FETCH_ASSIGN_USERS_BY_COMPANY, fetchAssignUsersByCompany);
}

export function* fetchAssignUsersByCompany(action) {
  try {
    const response = yield call(companyUsersAssignPaymentServices.fetchAssignUsersByCompany, action.payload);
    if (response.success) {
      yield put(actions.companyUsersAssignPayment.fetchAssignUsersByCompanySuccess(response.data));
    } else {
      yield put(actions.companyUsersAssignPayment.fetchAssignUsersByCompanyFail(response));
    }
  } catch (error) {
    yield put(actions.companyUsersAssignPayment.fetchAssignUsersByCompanyFail(error));
  }
}
