import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions/companyPaymentPackages.actions';
import * as paymentPackagesAction from '../../actions/paymentPackages.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyPaymentPackagesServices from '../../../services/companyPaymentPackagesServices';

export default function* getByCompanyIdSaga() {
  yield takeEvery(actionTypes.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_REQUEST, getByCompanyId);
}

export function* getByCompanyId(action) {
  try {
    const response = yield call(companyPaymentPackagesServices.getByCompanyId, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.getByCompanyIdSuccess(data));
      // set current payment package
      yield put(paymentPackagesAction.paymentPackages.setCurrentPaymentPackage(data));
    } else {
      yield put(actions.getByCompanyIdFail(response));
    }
  } catch (error) {
    yield put(actions.getByCompanyIdFail(error));
  }
}
