import {
  put,
  takeEvery,
  call,
  select
} from 'redux-saga/effects';
import * as selectors from '../../../selectors';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import CompanyUsersServices from '../../../services/companyUsersServices';
import companyServices from '../../../services/companyServices';

// const sortOwnerOnTop = ({ data, ownerData }) => {
//   const { rows } = data;
//   const results = rows.reduce((arr, a) => {
//     if (a.Email === ownerData.Email) {
//       arr.unshift(a);
//     } else {
//       arr.push(a);
//     }
//     return arr;
//   }, []);
//   return results;
// };

export default function* fetchAllCompanyUsersSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_REQUEST, fetchAllCompanyUsers);
}

export function* fetchAllCompanyUsers(action) {
  try {
    // get company
    // const company = yield select(selectors.company);
    // const { CompanyId: companyId } = company;
    // get ownerInfo
    // let ownerData = yield select(selectors.ownerData);
    // const { UserId: ownerDataUserId } = ownerData;
    // if (!ownerDataUserId) {
    //   const ownerResponse = yield call(companyServices.getOwner, action.payload);
    //   if (ownerResponse.success) {
    //     const { data } = ownerResponse;
    //     yield put(actions.getOwnerSuccess(data));
    //     ownerData = data;
    //   }
    // }
    const response = yield call(CompanyUsersServices.fetchAll, action.payload);
    if (response.success) {
      // const { data: { rows } } = response;
      // check if owner not exist in companyUsers
      // const found = rows.find(item => item.UserId === ownerData.UserId);
      // auto add owner for first time
      // if (!found) {
      //   if (rows.length !== 3) yield call(CompanyUsersServices.add, { companyId, userId: ownerData.UserId });
      //   const responseNew = yield call(CompanyUsersServices.fetchAll, { companyId });
      //   if (responseNew.success) {
      //     const { data } = responseNew;
      //     const newRows = sortOwnerOnTop({ data, ownerData });
      //     data.rows = newRows;
      //     yield put(actions.fetchAllCompanyUsersSuccess(data));
      //   }
      // } else {
      const { data } = response;
      // const newRows = sortOwnerOnTop({ data, ownerData });
      // data.rows = newRows;
      yield put(actions.fetchAllCompanyUsersSuccess(data));
      // }
    } else {
      yield put(actions.fetchAllCompanyUsersFail(response.message));
    }
  } catch (error) {
    yield put(actions.fetchAllCompanyUsersFail(error));
  }
}
