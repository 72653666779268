import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import CompanyUsersReminverServices from '../../../services/companyUsersReminderServices';

export default function* addCompanyUsersReminderSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_REMINDER.ADD_REMINDER_REQUEST, addCompanyUsersReminder);
}

export function* addCompanyUsersReminder(action) {
  try {
    const response = yield call(CompanyUsersReminverServices.add, action.payload);
    if (response.success) {
      yield put(actions.addCompanyUsersReminderSuccess(response.data));
    } else {
      yield put(actions.addCompanyUsersReminderFail(response.data));
    }
  } catch (error) {
    yield put(actions.addCompanyUsersReminderFail(error));
  }
}
