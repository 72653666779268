import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import signantActions from '../../store/actions/3rd/signant.actions';
import * as Utils from '../../utils/Utils';
import Loading from '../UI/Loading';
import SignNextBankAuthorize from './SignNextBankAuthorize';
import rgbPaymentsBasketsService from '../../services/3rd/rgb/rgbPaymentsBaskets.service';
import rgbPaymentsService from '../../services/3rd/rgb/rgbPayments.service';
import voucherPaymentServices from '../../services/voucherPaymentServices';
import ErrorMessage from '../UI/ErrorMessage';
class SignNext extends Component {
  constructor() {
    super();
    this.timer;
    this.state = {
      isLoading: false,
      seconds: 5
    };
  }
  
  componentDidUpdate(prevProps) {
    const { isHandlingAuthorizeCompanyBanks } = this.props;
    if (prevProps.isHandlingAuthorizeCompanyBanks === true && isHandlingAuthorizeCompanyBanks === false) {
      setTimeout(() => {
        this._autoClickCloseModal();
      }, 100);
    }
  }

  componentDidMount = () => {
    const { history } = this.props;
    const action = Utils.getParameterByName('action');
    const bankGroup = Utils.getParameterByName('bankGroup');
    this._action = action;
    switch (action) {
      case 'signant-auth':
        this.handleCompanyBankVerifyProcess({ bankGroup });
        break;
      case 'payment-auth':
        this.handlePaymentProcess();
        break;
      default:
        history.push('/404');
        break;
    }
  };

  getTextContent() {
    const { isLoading } = this.state;
    const { t, isHandlingAuthorizeCompanyBanks } = this.props;
    const texts = ['payment-accepted', 'text-next'];
    if (this._action === 'payment-auth') return isLoading ? t('processing') : t(texts[0]);
    if (this._action === 'signant-auth') return isHandlingAuthorizeCompanyBanks ? t('processing') : t(texts[1]);
    return t(texts[1]);
  }

  handleCompanyBankVerifyProcess = async ({ bankGroup }) => {
    const { dispatch } = this.props;
    // handle update accountNumbers after authorized
    const signantAuth = localStorage.getItem('tmp-signant-auth') && JSON.parse(localStorage.getItem('tmp-signant-auth'));
    const { RequestID: requestID, TrackingID: trackingID, companyId, assignUser } = signantAuth;
    const companyBankIds = Utils.getParameterByName('companyBankIds');
    const accountNumbers = Utils.getParameterByName('accountNumbers');
    const organisationNumber = Utils.getParameterByName('organisationNumber');
    const bankGroups = ['sparebank1', 'dnb', 'handelsbanken'];
    if (bankGroups.includes(bankGroup)) {
      // if (!companyBankIds || !requestID || trackingID) history.push('/404');
      await dispatch(
        signantActions.handleAuthorizeCompanyBanks({
          assignUser,
          companyId,
          requestID,
          trackingID,
          organisationNumber,
          companyBankIds,
          accountNumbers,
        })
      );
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({ seconds });
    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  handlePaymentProcess = async () => {
    const { history } = this.props;
    const basketUUID = await this.getBasketUUID();
    if (basketUUID) {
      // send basketUUID to backend process
      this.setState({ isLoading: true });
      // create asice RGB, signingBakset
      const response = await rgbPaymentsService.signingBaskets({ basketUUID });
      this.setState({ isLoading: false }, async () => {
        const { success, data } = response;
        if (success) {
          // handle update BILAGSKY voucher payment after create asice response
          const paymentsIds = data.map((item) => item.paymentsId);
          await voucherPaymentServices.updateByPaymentsIds({ paymentsIds });
          this._autoClickCloseModal();
        }
      });
    } else {
      history.push('/404');
    }
  };

  getBasketUUID = async () => {
    // get paymentUUID and update status to CANCELED
    const basketUUID = Utils.getParameterByName('basketUUID');
    if (basketUUID) {
      // check basketUUID exist
      const basketResponse = await rgbPaymentsBasketsService.getByBasketUUID({ basketUUID });
      const { success, data } = basketResponse;
      if (!success) return null;
      if (success) if (data.length === 0) return null;
      return basketUUID;
    }
    return null;
  };

  _autoClickCloseModal() {
    this.timer = setInterval(this.countDown, 1000);
    setTimeout(() => {
      const {
        parent: { document },
      } = window;
      const btnClose = document.querySelectorAll('.modal-close-icon');
      if (btnClose && btnClose[0]) btnClose[0].click();
      window.close();
    }, 5000);
  }

  render() {
    const {
      t,
      isGettingAuthStatus,
      isHandlingAuthorizeCompanyBanks,
      authorizeCompanyBanksData,
      errors: { handleAuthorizeCompanyBanks: errorHandleAuthorizeCompanyBanks },
    } = this.props;
    const { isLoading, seconds } = this.state;
    const txtContent = this.getTextContent();
    return (
      <div className="row">
        <div className="col s12">
          <div className="card valign-wrapper">
            <div className="card-content valign center-block">
              {!errorHandleAuthorizeCompanyBanks && (
                <>
                  {txtContent}
                  {seconds > 0 && seconds < 5 && !isLoading
                    ? <div className='red-text center'>( {seconds} )</div>
                    : ''}
                </>
              )}
              {errorHandleAuthorizeCompanyBanks && <ErrorMessage message={t('system:message.error')} />}
            </div>
            <Loading loading={isLoading || isGettingAuthStatus || isHandlingAuthorizeCompanyBanks} />
          </div>
          {authorizeCompanyBanksData && authorizeCompanyBanksData.accountNumbers && (
            <SignNextBankAuthorize t={t} authorizeCompanyBanksData={authorizeCompanyBanksData} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const company = state.company.company;
  const signantAuthRequestData = state.signant.signantAuthRequestData || {}; // use for veirfy payment list
  const isGettingAuthStatus = state.signant.isGettingAuthStatus;
  const isHandlingAuthorizeCompanyBanks = state.signant.isHandlingAuthorizeCompanyBanks;
  const authorizeCompanyBanksData = state.signant.handleAuthorizeCompanyBanksData;
  const errors = state.signant.errors;
  return {
    company,
    signantAuthRequestData,
    isGettingAuthStatus,
    isHandlingAuthorizeCompanyBanks,
    authorizeCompanyBanksData,
    errors,
  };
};

export default withRouter(connect(mapStateToProps)(withNamespaces(['signNext', 'system'])(SignNext)));
