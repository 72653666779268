import React from 'react';

class LoadingProgessAsyncData extends React.PureComponent {
  render() {
    return (
      <div className={this.props.loading ? 'sweet-loading' : 'd-none'}>
        <div
          className="small active"
          style={
            this.props.size !== null && this.props.size !== undefined
              ? { width: this.props.size + 'px', height: this.props.size + 'px' }
              : {}
          }
        >
          <span className="bilagsky-async-data"></span>
        </div>
      </div>
    );
  }
}

export default LoadingProgessAsyncData;
