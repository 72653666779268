import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import bankServices from '../../../services/bankServices';

export default function* getBankBySwiftCodeSaga() {
  yield takeEvery(actionTypes.BANK.GET_BANK_BY_SWIFT_CODE_REQUEST, getBankBySwiftCode);
}

export function* getBankBySwiftCode(action) {
  try {
    const response = yield call(bankServices.getBankBySwiftCode, action.payload);
    if (response.success) {
      yield put(actions.getBankBySwiftCodeSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getBankBySwiftCodeFail(error));
  }
}
