import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyServices from '../../../services/companyServices';

export default function* getOwnerSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_OWNER_REQUEST, getOwner);
}

export function* getOwner(action) {
  try {
    const response = yield call(companyServices.getOwner, action.payload);
    if (response.success) {
      yield put(actions.getOwnerSuccess(response.data || {}));
    }
  } catch (error) {
    yield put(actions.getOwnerFail(error));
  }
}
