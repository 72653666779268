import { call, put, takeEvery } from 'redux-saga/effects';
import GlobalRuleServices from '../../../../services/globalRuleServices';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';

export default function* deleteGlobalRuleSaga() {
  yield takeEvery(
    actionTypes.GLOBAL_RULE.DELETE_GLOBAL_RULE_REQUEST,
    deleteGlobalRule
  );
}

export function* deleteGlobalRule(action) {
  try {
    const response = yield call(GlobalRuleServices.delete, action.payload.id);
    if (response.success) {
      yield put(actions.deleteGlobalRuleSuccess(response.data));
      yield put(actions.fetchAllGlobalRuleRequest(action.payload.params));
    } else {
      yield put(actions.deleteGlobalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.deleteGlobalRuleFail(error));
  }
}
