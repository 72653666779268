import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherServices from '../../../services/voucherServices';

export default function* fetchVoucherBalanceSaga() {
  yield takeEvery(actionTypes.VOUCHER.GET_VOUCHER_DETAIL_REQUEST, getVoucherDetail);
}

export function* getVoucherDetail(action) {
  try {
    const response = yield call(VoucherServices.getVoucherById, action.payload);
    if (response.success) {
      yield put(actions.getVoucherDetailSuccess(response.data));
    } else {
      yield put(actions.getVoucherDetailFail(response.data));
    }
  } catch (error) {
    yield put(actions.getVoucherDetailFail(error));
  }
}
