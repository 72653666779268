import { fork } from 'redux-saga/effects';
// COMPANY
import getCompaniesSaga from './company/getCompanies.sagas';
import getOwnerSaga from './company/getOwner.sagas';
import getCompanyDivisionByOrgSaga from './company/getCompanyDivisionByOrg.sagas';
// INBOX
import getInboxSaga from './inbox/getInbox.sagas';
import getInboxDataSaga from './inbox/getInboxData.sagas';
// VOUCHER
import fetchVoucherDataSaga from './voucher/fetchVoucherData.sagas';
import getVoucherBalance from './voucher/getVoucherBalance.sagas';
import getMissingSummary from './voucher/getMissingSummary.sagas';
import fetchAdminVoucherSaga from './voucher/fetchAdminVoucher.sagas';
import getVoucherDetailSaga from './voucher/getVoucherDetail.sagas';
import getVoucherSuggestInfoSaga from './voucher/getVoucherSuggestInfo.sagas';
// VOUCHER PAYMENT
import addToPaymentListSaga from './voucherPayment/addToPaymentList.sagas';
import fetchVoucherPaymentSaga from './voucherPayment/fetchVoucherPayment.sagas';
import getVoucherPaymentByVoucherIdSaga from './voucherPayment/getByVoucherId.sagas';
import getVoucherPaymentByPaymentsUUIDSaga from './voucherPayment/getByPaymentUUID.sagas';
import getVoucherPaymentStatisticSaga from './voucherPayment/getVoucherPaymentStatistic.sagas';
import addUnpaidEhfVouchersToPaymentListSaga from './voucherPayment/addUnpaidEhfVouchersToPaymentList.sagas';
import getPaymentActionsSaga from './voucherPayment/getPaymentActions.sagas';
// VOUCHER PAYMENT
import addToPaymentListSagaInternal from './voucherPaymentInternal/addToPaymentList.sagas';
import fetchVoucherPaymentSagaInternal from './voucherPaymentInternal/fetchVoucherPayment.sagas';
import getVoucherPaymentByVoucherIdSagaInternal from './voucherPaymentInternal/getByVoucherId.sagas';
import getVoucherPaymentByPaymentsUUIDSagaInternal from './voucherPaymentInternal/getByPaymentUUID.sagas';
import getVoucherPaymentStatisticSagaInternal from './voucherPaymentInternal/getVoucherPaymentStatistic.sagas';
import addUnpaidEhfVouchersToPaymentListSagaInternal from './voucherPaymentInternal/addUnpaidEhfVouchersToPaymentList.sagas';
import getPaymentActionsSagaInternal from './voucherPaymentInternal/getPaymentActions.sagas';
// BANK
import fetchBankDataSaga from './bank/fetchBankData.sagas';
import fetchBankByCompanySaga from './bank/fetchBankByCompany.sagas';
import getBankBySwiftCodeSaga from './bank/getBankBySwiftCode.sagas';
import getBankByFilterSaga from './bank/getBankByFilter.sagas';

// COMPANY BANK SERVICE
import buyRemittanceSaga from './companyBankService/buyRemittance.sagas';

// COMPANY BANK
import deleteCompanyBankSaga from './companyBank/deleteCompanyBank.sagas';
import setDefaultAccountNumberSaga from './companyBank/setDefaultAccountNumber.sagas';
import getDefaultAccountNumberSaga from './companyBank/getDefaultAccountNumber.sagas';

// BANK TRANSACTION
import getTransactionMissingSummarySaga from './transaction/getTransactionMissingSummary.sagas';
import getSuggestTransaction from './transaction/getSuggestTransaction.sagas';
import fetchBankTransactionByIdSaga from './transaction/fetchBankTransactionById.sagas';
import getHistorySaga from './transaction/getHistory.sagas';
// TRANSACTION VOUCHER
import adminVoucherConnect from './transactionVoucher/adminVoucherConnect.sagas';
// CONNECT MISSING
import getConnectMissingBankSaga from './connectMissing/getConnectMissingVoucher.sagas';
// GLOBAL RULES
import addGlobalRuleSaga from './aiSetting/globalRule/addGlobalRule.sagas';
import updateGlobalRuleSaga from './aiSetting/globalRule/updateGlobalRule.sagas';
import fetchAllGlobalRuleSaga from './aiSetting/globalRule/fetchAllGlobalRule.sagas';
import deleteGlobalRuleSaga from './aiSetting/globalRule/deleteGlobalRule.sagas';
// LOCAL RULES
import addLocalRuleSaga from './aiSetting/localRule/addLocalRule.sagas';
import updateLocalRuleSaga from './aiSetting/localRule/updateLocalRule.sagas';
import fetchAllLocalRuleSaga from './aiSetting/localRule/fetchAllLocalRule.sagas';
import deleteLocalRuleSaga from './aiSetting/localRule/deleteLocalRule.sagas';
import copyLocalRuleSaga from './aiSetting/localRule/copyLocalRule.sagas';

// COMPANY USERS
import fetchAllCompanyUsersSaga from './companyUsers/fetchAllCompanyUsers.sagas';
import deleteCompanyUsersSaga from './companyUsers/delete.sagas';
import addCompanyUsersSaga from './companyUsers/add.sagas';
// COMPANY USERS REMINDER
import addCompanyUsersReminderSaga from './companyUsersReminder/addReminder.sagas';
import updateCompanyUsersReminderSaga from './companyUsersReminder/updateReminder.sagas';
// COMPANY USERS ALERT VOUCHER
import addCompanyUsersAlertVoucherSaga from './companyUsersAlertVoucher/addAlertVoucher.sagas';

// SIGNANT
import signantInitAuth from './3rd/signant/signantInitAuth.sagas';
import signantAuth from './3rd/signant/signantAuth.sagas';
import signantGetAuthStatus from './3rd/signant/signantGetAuthStatus.sagas';
import signantHandleAuthorizeCompanyBanks from './3rd/signant/handleAuthrorizeCompanyBanks.sagas';

// COMPANY SERVICE
import addCompanyServiceSaga from './companyService/add.sagas';
import fetchAllCompanyServiceSaga from './companyService/fetchAll.sagas';
import updateCompanyServiceSaga from './companyService/update.sagas';

// COMPANY PAYMENT PACKAGES
import addCompanyPaymentPackagesSaga from './companyPaymentPackages/add.sagas';
import updateCompanyPaymentPackagesSaga from './companyPaymentPackages/update.sagas';
import getCompanyPaymentPackagesByCompanyId from './companyPaymentPackages/getByCompanyId.sagas';

// COMPANY USERS ASSIGN PAYMENT
import getCompanyUsersAssignPaymentSaga from './companyUsersAssignPayment/get.sagas';
import addCompanyUsersAssignPayment from './companyUsersAssignPayment/add.sagas';
import updateCompanyUsersAssignPayment from './companyUsersAssignPayment/update.sagas';
import buyCompanyPaymentPackages from './companyPaymentPackages/buyPaymentPackages.sagas';
import getBanksVerifiedPaymentSaga from './companyUsersAssignPayment/getBanksVerifiedPayment.sagas';
import fetchAssignUsersByCompanySaga from './companyUsersAssignPayment/fetchAssignUsersByCompany.sagas';

// PAYMENT PACKAGES
import fetchAllPaymentPackages from './paymentPackages/fetchAll.sagas';
// STRIPE
import createPaymentIntant from './3rd/stripe/createPaymentIntent.sagas';
// PSD2_SP1
import psd2Sp1CrsAuthorize from './3rd/psd2SpareBank/crsAuthorize.sagas';

// COMPANY BANKS
import fetchAllCompanyBankInfoByComapyId from './companyBank/fetchAllCompanyBankInfoByCompanyId.sagas';
import getBankBalanceByAccountNumber from './companyBank/getBankBalanceByAccountNumber.sagas';
// RGB
import updatePaymentStatusSaga from './3rd/rgb/updatePaymentStatus.sagas';

import saveSettingUserSaga from './setting/saveSettingUser.sagas';
import settingBankAccountSagas from './setting/settingBankAccounts.sagas';
import getVoucherBalancesSaga from './v2/getVoucherBalances.saga';

function* rootSagas() {
  yield fork(saveSettingUserSaga);

  yield fork(getCompaniesSaga);
  yield fork(getOwnerSaga);
  yield fork(getCompanyDivisionByOrgSaga);
  yield fork(getInboxSaga);
  yield fork(getInboxDataSaga);
  // voucher
  yield fork(fetchAdminVoucherSaga);
  yield fork(fetchVoucherDataSaga);
  yield fork(getVoucherBalance);
  yield fork(getMissingSummary);
  yield fork(getVoucherDetailSaga);
  yield fork(getVoucherSuggestInfoSaga);
  // voucher payment
  yield fork(addToPaymentListSaga);
  yield fork(fetchVoucherPaymentSaga);
  yield fork(getVoucherPaymentByVoucherIdSaga);
  yield fork(getVoucherPaymentByPaymentsUUIDSaga);
  yield fork(getVoucherPaymentStatisticSaga);
  yield fork(addUnpaidEhfVouchersToPaymentListSaga);
  yield fork(getPaymentActionsSaga);
  // voucher payment internal
  yield fork(addToPaymentListSagaInternal);
  yield fork(fetchVoucherPaymentSagaInternal);
  yield fork(getVoucherPaymentByVoucherIdSagaInternal);
  yield fork(getVoucherPaymentByPaymentsUUIDSagaInternal);
  yield fork(getVoucherPaymentStatisticSagaInternal);
  yield fork(addUnpaidEhfVouchersToPaymentListSagaInternal);
  yield fork(getPaymentActionsSagaInternal);
  // bank
  yield fork(fetchBankDataSaga);
  yield fork(getBankBySwiftCodeSaga);
  yield fork(fetchBankByCompanySaga);
  yield fork(getBankByFilterSaga);
  // company bank service
  yield fork(buyRemittanceSaga);
  // companybank
  yield fork(setDefaultAccountNumberSaga);
  yield fork(getDefaultAccountNumberSaga);
  yield fork(deleteCompanyBankSaga);
  // banktransaction
  yield fork(getTransactionMissingSummarySaga);
  yield fork(getSuggestTransaction);
  yield fork(fetchBankTransactionByIdSaga);
  yield fork(getHistorySaga);
  // transaction voucher
  yield fork(adminVoucherConnect);
  // connect missing
  yield fork(getConnectMissingBankSaga);
  // global rule
  yield fork(addGlobalRuleSaga);
  yield fork(updateGlobalRuleSaga);
  yield fork(fetchAllGlobalRuleSaga);
  yield fork(deleteGlobalRuleSaga);
  // local rule
  yield fork(addLocalRuleSaga);
  yield fork(updateLocalRuleSaga);
  yield fork(fetchAllLocalRuleSaga);
  yield fork(deleteLocalRuleSaga);
  yield fork(copyLocalRuleSaga);
  // company users
  yield fork(fetchAllCompanyUsersSaga);
  yield fork(deleteCompanyUsersSaga);
  yield fork(addCompanyUsersSaga);
  // company users reminder
  yield fork(addCompanyUsersReminderSaga);
  yield fork(updateCompanyUsersReminderSaga);
  // yield fork(deleteCompanyUsers);
  // company users alert voucher
  yield fork(addCompanyUsersAlertVoucherSaga);
  // SIGNANT
  yield fork(signantInitAuth);
  yield fork(signantAuth);
  yield fork(signantGetAuthStatus);
  yield fork(signantHandleAuthorizeCompanyBanks);
  // COMPANY SERVICES
  yield fork(addCompanyServiceSaga);
  yield fork(fetchAllCompanyServiceSaga);
  yield fork(updateCompanyServiceSaga);
  // COMPANY PAYMENT PACKAGES
  yield fork(addCompanyPaymentPackagesSaga);
  yield fork(updateCompanyPaymentPackagesSaga);
  yield fork(getCompanyPaymentPackagesByCompanyId);
  yield fork(buyCompanyPaymentPackages);
  // company users assign payment
  yield fork(getCompanyUsersAssignPaymentSaga);
  yield fork(addCompanyUsersAssignPayment);
  yield fork(updateCompanyUsersAssignPayment);
  // paymentPackages
  yield fork(fetchAllPaymentPackages);
  // STRIPE
  yield fork(createPaymentIntant);
  // PSD2_SP1
  yield fork(psd2Sp1CrsAuthorize);
  // COMPANY BANKS
  yield fork(fetchAllCompanyBankInfoByComapyId);
  yield fork(getBankBalanceByAccountNumber);
  yield fork(updatePaymentStatusSaga);

  yield fork(getBanksVerifiedPaymentSaga);
  yield fork(fetchAssignUsersByCompanySaga);

  // setting bank accounts
  yield fork(settingBankAccountSagas);

  //v2 voucher
  yield fork(getVoucherBalancesSaga)
}
export default rootSagas;
