import { CONSENT_ACTION_TYPES } from "../actionTypes/consent.actionTypes";

export const setShowCreateConsentModal = (payload) => ({
  type: CONSENT_ACTION_TYPES.SET_SHOW_CREATE_CONSENT_MODAL,
  payload,
});

export const setShowRenewConsentModal = (payload) => ({
  type: CONSENT_ACTION_TYPES.SET_SHOW_RENEW_CONSENT_MODAL,
  payload,
});

export const setConsents = (payload) => ({
  type: CONSENT_ACTION_TYPES.SET_CONSENTS,
  payload,
});
