import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import * as selectors from '../../../selectors';
import timeHelper from '../../../helpers/timeHelper';
import voucherPaymentServicesV2 from '../../../services/v2/voucherPaymentServicesV2';
import { VOUCHER_PAYMENTS_STATUS } from '../../../constants/voucher-payments.constants';

export default function* fetchVoucherPaymentSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_REQUEST, fetchVoucherPayment);
}

export function* fetchVoucherPayment(action) {
  try {
    // get company
    const company = yield select(selectors.company);
    const { CompanyId: companyId } = company;
    if (companyId) {
      const filter = yield select(selectors.voucherPaymentFilter);
      const cloneFilter = { ...filter, companyId };

      const statisticParams = {
        companyId,
        keyword: cloneFilter.keyword,
        ...cloneFilter.swiftcode && { swiftcode: cloneFilter.swiftcode },
        ...(filter.paymentSource === 1 ? { paymentSource: filter.paymentSource } : {}),
        ...(filter.paymentSource === 'isInternalTransfer' ? { isInternalTransfer: 1 } : {}),
        ...(cloneFilter.from && cloneFilter.to
          ? { fromDate: timeHelper.formatUtcDate(cloneFilter.from, 'YYYY-MM-DD'), toDate: timeHelper.formatUtcDate(cloneFilter.to, 'YYYY-MM-DD') }
          : {}),
      };

      const response = yield call(voucherPaymentServicesV2.getStatistic, statisticParams);
      const { success, data } = response;
      if (success) {
        const found = data.find((item) => item.total > 0) || {};
        const { status } = found;
        const statuses = [status && status + '' || '0'];
        cloneFilter.statuses = statuses;
        // set filter
        const { statuses: filterStatuses } = filter;
        if (filterStatuses !== statuses) {
          // check filterStatus have data
          const foundStatuses = data.find((item) => item.status === +filterStatuses && item.total > 0);
          // if exist keep status else auto get from statisic list
          cloneFilter.statuses = foundStatuses ? filterStatuses : statuses;
        }
        yield put(actions.getVoucherPaymentStatisticSuccess(data));
      }

      // delete cloneFilter.isShowDate;
      cloneFilter.template = cloneFilter.template == '0' ? '' : cloneFilter.template;
      // handle search and it statuses = PAID => update sort to desc
      const sortType = +cloneFilter.statuses[0] === VOUCHER_PAYMENTS_STATUS.PAID && filter.statuses[0] !== cloneFilter.statuses[0] && 'desc';
      if (sortType) cloneFilter.sortType = sortType;

      const requestParams = {
        ...cloneFilter,
        ...(cloneFilter.paymentSource === 1 ? { paymentSource: cloneFilter.paymentSource } : { paymentSource: null }),
        ...(cloneFilter.paymentSource === 'isInternalTransfer' ? { isInternalTransfer: 1 } : {}),
        ...(cloneFilter.from && cloneFilter.to ? {
          fromDate: timeHelper.formatUtcDate(cloneFilter.from, 'YYYY-MM-DD'),
          toDate: timeHelper.formatUtcDate(cloneFilter.to, 'YYYY-MM-DD')
        } : {}),
      }

      const responseData = yield call(voucherPaymentServicesV2.fetchAll, requestParams);
      const { success: successRes, data: dataRes } = responseData;
      if (successRes) {
        yield put(
          actions.fetchVoucherPaymentSuccess(
            dataRes || {
              total: 0,
              rows: [],
            }
          )
        );
        yield put(actions.setFilter(cloneFilter));
      } else {
        yield put(actions.fetchVoucherPaymentFail(responseData.message));
      }
    }
    yield put(actions.fetchVoucherPaymentFail('Invalid params'));
  } catch (error) {
    yield put(actions.fetchVoucherPaymentFail(error));
  }
}
