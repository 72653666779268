import fetch from 'isomorphic-fetch';

export default {
  insert: async (params) => {
    /*
      params: {
        level,
        log,
        system
      }
    */
    try {
      const {
        location: { host },
      } = window;
      if (host !== 'localhost:4002') {
        let url = 'https://api-logging.oryton.no/api/logging';
        const localHosts = ['dev.bilagsky.no', 'www.dev.bilagsky.no'];
        if (localHosts.includes(host)) {
          url = 'https://api-logging.oryton.vn/api/logging';
        }
        fetch(url, {
          method: 'post',
          body: JSON.stringify(params),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((res) => res.json())
          .then((json) => console.log(json));
      }
    } catch (error) {
      // logger(`ajax.service - convertPdfToThumb`, error.toString());
    }
  },
};
