import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './baseServices';

export default {
  getMenuServices: async () => {
    try {
      const url = api.oryton.getMenuServices;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompanyByUser: async () => {
    try {
      const url = api.company.getOrytonCompanies;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getOwnCompanys: async () => {
    try {
      const url = api.company.getOwnCompany;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getInbox: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.oryton.getInbox}?${query}`;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  listUsers: async () => {
    try {
      const url = api.oryton.listUsers;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  renewSubscription: async (url) => {
    try {
      const data = await BaseServices.postDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchResponsibleAccountants: async () => {
    try {
      const url = api.oryton.listAccountants;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompanyStartEnd: async (companyIds) => { // str 12,333
    try {
      const url = api.oryton.getCompanyStartEnd + '?company=' + companyIds;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
};
