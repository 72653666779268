import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';
import * as actions from '../../actions/voucherPayment.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getPaymentActionsSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS, getPaymentActions);
}

export function* getPaymentActions(action) {
  try {
    const response = yield call(VoucherPaymentServices.getPaymentActions, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.getPaymentActionsSuccess(data));
    } else {
      yield put(actions.getPaymentActionsFail(response.message));
    }
  } catch (error) {
    yield put(actions.getPaymentActionsFail(error));
  }
}
