import * as Config from '../../configs/server.configs';

const env = process.env.NODE_ENV;
const endPoint = Config[env].signantEndpoint;
// const endPoint = 'https://sandboxapi.psd.dnb.no';

const origin = window.location.origin;

export default {
  api: {
    initAuth: `${endPoint}/signant/v1/auth`,
    getAuthStatus: `${endPoint}/signant/v1/get-auth-status` // http://localhost:7001/signant/v1/get-auth-status?requestID=b2f3e5b6-376f-4fca-a530-c910b390089f&trackingID=0eb8a194-56e0-4511-80b2-4f7006b6d3e1
  },
  cancelUrl: `${origin}/:company/sign-cancel?action=:action`,
  nextUrl: `${origin}/:company/sign-next?action=:action`
};
