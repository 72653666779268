import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import Services from '../../../services/companyBankServices';

export default function* fetchAllCompanyBankInfoByCompanyIdSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK.FETCH_ALL_COMPANY_BANK_INFO_BY_COMPANY_ID, fetchAllCompanyBankInfoByCompanyId);
}

export function* fetchAllCompanyBankInfoByCompanyId(action) {
  try {
    // get company
    // 1- get years
    const response = yield call(Services.fetchAllCompanyBankInfoByCompanyId, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.companyBank.fetchAllCompanyBankInfoByComapyIdSuccess(data));
    } else {
      yield put(actions.companyBank.fetchAllCompanyBankInfoByComapyIdFail(data));
    }
  } catch (error) {
    yield put(actions.companyBank.fetchAllCompanyBankInfoByComapyIdFail(error));
  }
}
