import { call, put, select, takeEvery } from 'redux-saga/effects';
import timeHelper from '../../../helpers/timeHelper';
import * as selectors from '../../../selectors';
import VoucherServices from '../../../services/voucherServices';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';

export default function* fetchVoucherDataSaga() {
  yield takeEvery(actionTypes.VOUCHER.FETCH_VOUCHER_DATA_REQUEST, fetchVoucherData);
}

export function* fetchVoucherData(action) {
  try {
    // get company
    const company = yield select(selectors.company);
    const { CompanyId: companyId } = company;
    if (companyId) {
      // 1- get years
      const yearsData = yield call(VoucherServices.getYears, companyId);
      const sfilter = yield select(selectors.filterVoucher);
      const filter = { ...sfilter };
      // set company filter
      filter.companyId = companyId;
      const data = {
        voucherStatisticData: {
          statis: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        voucherListData: {
          total: 0,
          rows: [],
        },
      };

      if (yearsData.success) {
        if (yearsData.data && yearsData.data.length) {
          // 2 set filter voucher
          // get filter
          filter.years = yearsData.data;
          if (
            localStorage.getItem('voucherYear') &&
            yearsData.data.map((e) => e.value).includes(+localStorage.getItem('voucherYear')) &&
            Number.isFinite(+localStorage.getItem('voucherYear')) &&
            !filter.voucherId
          ) {
            filter.year = localStorage.getItem('voucherYear');
          }

          if (action.payload?.isInit) {
            if (filter.voucherId) {
              const reponseVoucher = yield call(VoucherServices.fetchAll, filter);
              if (reponseVoucher.success) {
                const {
                  data: { rows = [] },
                } = reponseVoucher;
                if (rows.length === 1) {
                  const voucherYear = timeHelper.formatDate(rows[0].Date, 'YYYY');
                  filter.year = filter.year ?? voucherYear;
                  localStorage.setItem('voucherYear', filter.year);
                  yield put(actions.setCurrentVoucher(rows[0]));
                }
              }
            }
          } else {
            if (action.payload?.replace && action.payload?.history?.location?.search?.includes('?')) {
              action.payload?.history.push(location.pathname);
            }
            filter.voucherId = null;
            yield put(actions.setCurrentVoucher({}));
          }

          filter.year = filter.year || (filter.year === null && filter.year !== '' ? yearsData.data[0].value : '');
          // 3 - get voucher statistic'
          const voucherStatisticDataResponse = yield call(VoucherServices.getStatistic, filter);
          const voucherListDataResponse = yield call(VoucherServices.fetchAll, filter);
          if (voucherStatisticDataResponse.success) data.voucherStatisticData = voucherStatisticDataResponse.data;
          if (voucherListDataResponse.success) data.voucherListData = voucherListDataResponse.data;
        }
        yield put(actions.fetchVoucherDataSuccess(data));
        yield put(actions.setFilterVoucher(filter));
      }
    }
  } catch (error) {
    console.log(error);
    yield put(actions.fetchVoucherDataFail(error));
  }
}
