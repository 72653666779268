import * as types from '../actionTypes/ActionTypes';

const INITIAL_STATE = {
  isShowCompanySelect: false,
  isLoadingCompany: false,
  isLoadingOwner: false,
  isLoadingGetDivision: false,
  companyData: null,
  company: {},
  ownerData: {},
  divisionData: [],
  editDivision: {}
};

const company = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COMPANY.SET_SHOW_MODAL_SELECT_COMPANY: {
      return {
        ...types,
        isShowCompanySelect: action.payload
      };
    }
    case types.COMPANY.SET_CURRENT_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case types.COMPANY.GET_COMPANIES_REQUEST:
      return {
        ...state,
        isLoadingCompany: true
      };
    case types.COMPANY.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companyData: action.payload,
        isLoadingCompany: false
      };
    case types.COMPANY.GET_COMPANIES_FAIL:
      return {
        ...state,
        isLoadingCompany: false
      };
    case types.COMPANY.GET_OWNER_REQUEST:
      return {
        ...state,
        isLoadingOwner: true
      };
    case types.COMPANY.GET_OWNER_SUCCESS:
      return {
        ...state,
        ownerData: action.payload,
        isLoadingOwner: false
      };
    case types.COMPANY.GET_OWNER_FAIL:
      return {
        ...state,
        isLoadingOwner: false
      };
    case types.COMPANY.GET_DIVISION_BY_ORG:
      return {
        ...state,
        isLoadingGetDivision: true
      };
    case types.COMPANY.GET_DIVISION_BY_ORG_SUCCESS:
      return {
        ...state,
        divisionData: action.payload,
        isLoadingGetDivision: false
      };
    case types.COMPANY.GET_DIVISION_BY_ORG_FAIL:
      return {
        ...state,
        isLoadingGetDivision: false
      };
    case types.COMPANY.SET_EDIT_DIVISION:
      return {
        ...state,
        editDivision: action.payload
      };
    default:
      return state;
  }
};
export default company;
