import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import paymentPackagesSercices from '../../../services/paymentPackagesServices';

export default function* fetchAllPaymentPackagesSaga() {
  yield takeEvery(actionTypes.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_REQUEST, fetchAllPaymentPackages);
}

export function* fetchAllPaymentPackages(action) {
  try {
    const response = yield call(paymentPackagesSercices.fetchAll, action.payload);
    const { data, success } = response;
    if (success) {
      yield put(actions.paymentPackages.fetchAllSuccess(data));
    } else {
      yield put(actions.paymentPackages.fetchAllFail(data));
    }
  } catch (error) {
    yield put(actions.paymentPackages.fetchAllFail(error));
  }
}
