import * as types from '../actionTypes/ActionTypes';

export const fetchAllCompanyService = payload => ({
  type: types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_REQUEST,
  payload
});
export const fetchAllCompanyServiceSuccess = payload => ({
  type: types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_SUCCESS,
  payload
});
export const fetchAllCompanyServiceFail = payload => ({
  type: types.COMPANY_SERVICE.FETCH_ALL_COMPANY_SERVICE_FAIL,
  payload
});

export const addCompanyService = payload => ({
  type: types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_REQUEST,
  payload
});
export const addCompanyServiceSuccess = payload => ({
  type: types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_SUCCESS,
  payload
});
export const addCompanyServiceFail = payload => ({
  type: types.COMPANY_SERVICE.ADD_COMPANY_SERVICE_FAIL,
  payload
});

export const updateCompanyService = payload => ({
  type: types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_REQUEST,
  payload
});
export const updateCompanyServiceSuccess = payload => ({
  type: types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_SUCCESS,
  payload
});
export const updateCompanyServiceFail = payload => ({
  type: types.COMPANY_SERVICE.UPDATE_COMPANY_SERVICE_FAIL,
  payload
});
