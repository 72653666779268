export default {
  // use for format amount on table
  format(money) {
    if (money) {
      let result = money;
      if (typeof money === 'string') result = parseFloat(result);
      return result
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$& ');
    }
    return null;
  },
  formatMoney(amount, decimalCount = 2, thousands = ' ') {
    /*
     FORCUS ON NOWARY MONEY:
     + 1.123,23
     + 1.123.456,23
     + -1 233,23
     + -1 233 788,23
    */
    // 1 - replace space with no space
    amount = amount.toString().replace(/ /g, '');
    // invalid amount => dont format and return amount
    const regex1 = /^-?((?:\d+|\d{1,3}(?:.\d{3})+)(?:,\d+)?)$/;
    const regex2 = /^-?((?:\d+|\d{1,3}(?:,\d{3})+)(?:.\d+)?)$/;

    if (regex1.test(amount) === false && regex2.test(amount) === false) return amount;
    const periods = (amount.match(/\./g) || []).length;
    const commas = (amount.match(/,/g) || []).length;

    /*
      case 1: 1 period and no coma keep and but 1 coma replace with period
      123.22
      -123.22
      123,22
      -123,22
    */

    if (periods === 0 && commas === 1) {
      amount = amount.replace(/\,/, '.');
    }
    /* CASE 2: 1 period and 1 coma
     1,233.22
     -1,233.22
     1.233,22
     -1.233,22
    */
    if (periods === 1 && commas === 1) {
      // get coma pos and poriod pos
      const comaPos = amount.indexOf(',');
      const periodPos = amount.indexOf('.');
      // console.log(50, { comaPos, periodPos });
      if (periodPos > comaPos) {
        // period last
        amount = amount.replace(/,/g, '');
      } else {
        // coma last
        amount = amount.replace(/\./, '').replace(/\,/, '.');
      }
    }

    /* CASE 3: multiple period and 1 coma
    1.123.456,23
    -1.123.456,23
    */
    if (periods > 1 && commas === 1) {
      amount = amount.replace(/\./g, '').replace(/,/, '.');
    }

    /* CASE 3: multiple coma and 1 period
      1,123,456.23
      -1,123,456.23
    */
    if (commas > 1 && periods === 1) {
      amount = amount.replace(/,/g, '');
    }
    /* CASE 4: multiple coma only
      1,123,456,233
      -1,123,456,233
    */
    if (commas > 1 && periods === 0) {
      amount = amount.replace(/,/g, '');
    }
    /* CASE 5: multiple period only
      1.123.456.233
      -1.123.456.233
    */
    if (commas === 0 && periods > 1) {
      amount = amount.replace(/\./g, '');
    }
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    const i = (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).replace('.', ',');
    // const f = amount.split('.');
    const num = i.split(',')[0];
    const j = num.length > 3 ? num.length % 3 : 0;
    const t = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
    return `${t}`;
  },
  clearFormatMoney(x) {
    if (x) {
      return x.toString().replace(/\s+/g, '').replace(',', '.');
    }
    return '';
  },
  clearFormatMoneyToSaveDB(x) {
    if (x) {
      return x.toString().replace(/\s+/g, '').replace(',', '.');
    }
    return '';
  },
  // redux-form and amount is string
  formatAmount(amount) {
    if (!amount) return '';
    // Convert to currency format
    const formated = amount
      .toString()
      .replace(/[a-z]|[A-Z]/g, '')
      .replace(/ /g, '')
      .replace(/\./g, ',')
      .replace(/,(?=.*,)/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (formated.indexOf(',') !== -1) {
      const str = formated.split(',');
      const first = str[0];
      let last = str[1].replace(/ /g, '');
      last = last.length >= 2 ? last.substr(0, 2) : last;
      return `${first},${last}`;
    }
    return formated;
  },
  nomalizeAmount(amount) {
    if (!amount) return '';
    // Convert to currency format
    return amount.replace(/ /g, '').replace(/,/g, '.');
  },
  handleChange: (value) => {
    value = value.replace(/[a-zA-Z]/g, '');
    return value;
  },
  handleChangePositiveAmount: (value) => {
    // additional char " "
    value = value.replace(/[^0-9\., ]/g, '');
    return value;
  },
  strToFloat: (value) => {
    return value && parseFloat(value.replace(',', '.'));
  },
};
