import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  get: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = api.companyUsersAssignPayment.get + '?' + query;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  add: async params => {
    try {
      const url = api.companyUsersAssignPayment.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.companyUsersAssignPayment.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getBanksVerifiedPayment: async params => {
    try {
      const { companyId, assignUser } = params;
      const query = httpHelper.serialize({ companyId });
      const url = api.companyUsersAssignPayment.getBanksVerifiedPayment.replace(':assignUser', assignUser) + '?' + query;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addUpdateBanksVerifiedPayment: async params => {
    try {
      const { assignUser } = params;
      const url = api.companyUsersAssignPayment.getBanksVerifiedPayment.replace(':assignUser', assignUser);
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAssignUsersByCompany: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = api.companyUsersAssignPayment.fetchAssignUserByCompany + '?' + query;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
