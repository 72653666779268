import * as types from '../actionTypes/ActionTypes';

const initialState = {
  notifications: [],
  notification: {}
};

const system = (state = initialState, action) => {
  switch (action.type) {
    case types.SYSTEM.NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case types.SYSTEM.Breadcrumb:
      return {
        ...state,
        breadcrumbs: action.payload
      };
    default:
      return state;
  }
};

export default system;
