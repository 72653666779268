import BaseServices from '../../baseServices';
import APIConstant from '../../../constants/3rd/api.stripe.constant';

export default {
  createPaymentIntent: async params => {
    try {
      const url = APIConstant.createPaymentIntent;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  createPaymentForRgbPackages: async params => {
    try {
      const url = APIConstant.createPaymentForRgbPackages;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, error };
    }
  }
};
