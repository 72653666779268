export * from './3rd/psd2Sp1.actionTypes';
export * from './3rd/rgb.actionTypes';
export * from './3rd/signant.actionTypes';
export * from './3rd/stripe.actionTypes';
export * from './bank.actionTypes';
export * from './bankTransaction.actionTypes';
export * from './companyBank.actionTypes';
export * from './companyBankService.actionTypes';
export * from './companyPaymentPackages.actionTypes';
export * from './companyService.actionTypes';
export * from './companyUsers.actionTypes';
export * from './companyUsersAlertVoucher.actionTypes';
export * from './companyUsersAssignPayment.actionTypes';
export * from './companyUsersReminder.actionTypes';
export * from './connectMissing.actionTypes';
export * from './notification.actionTypes';
export * from './paymentPackages.actionTypes';
export * from './roles.actionTypes';
export * from './setting.actionTypes';
export * from './settingBank.actionTypes';
export * from './transactionVoucher.actionTypes';
export * from './voucher.actionTypes';
export * from './voucherPayment.actionTypes';
export * from './voucherPaymentInternal.actionTypes';
export * from './voucherPaymentInternalFilter.actionTypes';
export * from './settingZtl.actionTypes';

export const SYSTEM = {
  ShowLoading: 'ShowLoading',
  HideLoading: 'HideLoading',
  NOTIFICATION: 'NOTIFICATION',
  SystemError: 'SystemError',
  CallAPIFailure: 'CallAPIFailure',
  ChangeUserMenu: 'ChangeUserMenu',
  Toggle: 'Toggle',
  Menu: 'Menu',
  Breadcrumb: 'Breadcrumb',
};

export const TASK = {
  SET_COUNT_PENDING: 'SET_COUNT_PENDING',
};

export const COMPANY = {
  SET_SHOW_MODAL_SELECT_COMPANY: 'SET_SHOW_MODAL_SELECT_COMPANY',
  GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAIL: 'GET_COMPANIES_FAIL',
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  GET_OWNER_REQUEST: 'GET_OWNER_REQUEST',
  GET_OWNER_SUCCESS: 'GET_OWNER_SUCCESS',
  GET_OWNER_FAIL: 'GET_OWNER_FAIL',
  GET_DIVISION_BY_ORG: 'GET_DIVISION_BY_ORG',
  GET_DIVISION_BY_ORG_SUCCESS: 'GET_DIVISION_BY_ORG_SUCCESS',
  GET_DIVISION_BY_ORG_FAIL: 'GET_DIVISION_BY_ORG_FAIL',
  SET_EDIT_DIVISION: 'SET_EDIT_DIVISION',
};

export const INBOX = {
  GET_INBOX_REQUEST: 'GET_INBOX_REQUEST',
  GET_INBOX_SUCCESS: 'GET_INBOX_SUCCESS',
  GET_INBOX_FAIL: 'GET_INBOX_FAIL',
  GET_INBOX_DATA_REQUEST: 'GET_INBOX_DATA_REQUEST',
  GET_INBOX_DATA_SUCCESS: 'GET_INBOX_DATA_SUCCESS',
  GET_INBOX_DATA_FAIL: 'GET_INBOX_DATA_FAIL',
  SET_INBOX_FILTER: 'SET_INBOX_FILTER',
  SET_INBOX_DETAIL: 'SET_INBOX_DETAIL',
  SET_VOUCHER_INBOX_INFO: 'SET_VOUCHER_INBOX_INFO',
};

export const ADMIN_EMAIL = {
  GET_FORWARD_SUCCESS_DATA: 'GET_FORWARD_SUCCESS_DATA',
  SET_FORWARD_SUCCESS_DATA: 'SET_FORWARD_SUCCESS_DATA',
};

export const GLOBAL_RULE = {
  SET_SHOW_MODAL_RULE: 'SET_SHOW_MODAL_RULE',
  SET_CURRENT_GLOBAL_RULE: 'SET_CURRENT_GLOBAL_RULE',
  FETCH_ALL_GLOBAL_RULE_REQUEST: 'FETCH_ALL_GLOBAL_RULE_REQUEST',
  FETCH_ALL_GLOBAL_RULE_SUCCESS: 'FETCH_ALL_GLOBAL_RULE_SUCCESS',
  FETCH_ALL_GLOBAL_RULE_FAIL: 'FETCH_ALL_GLOBAL_RULE_FAIL',
  ADD_GLOBAL_RULE_REQUEST: 'ADD_GLOBAL_RULE_REQUEST',
  ADD_GLOBAL_RULE_SUCCESS: 'ADD_GLOBAL_RULE_SUCCESS',
  ADD_GLOBAL_RULE_FAIL: 'ADD_GLOBAL_RULE_FAIL',
  UPDATE_GLOBAL_RULE_REQUEST: 'UPDATE_GLOBAL_RULE_REQUEST',
  UPDATE_GLOBAL_RULE_SUCCESS: 'UPDATE_GLOBAL_RULE_SUCCESS',
  UPDATE_GLOBAL_RULE_FAIL: 'UPDATE_GLOBAL_RULE_FAIL',
  DELETE_GLOBAL_RULE_REQUEST: 'DELETE_GLOBAL_RULE_REQUEST',
  DELETE_GLOBAL_RULE_SUCCESS: 'DELETE_GLOBAL_RULE_SUCCESS',
  DELETE_GLOBAL_RULE_FAIL: 'DELETE_GLOBAL_RULE_FAIL',
};

export const LOCAL_RULE = {
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_SHOW_MODAL_COPY: 'SET_SHOW_MODAL_COPY',
  SET_COPY_RULE_SUCCESS: 'SET_COPY_RULE_SUCCESS',
  SET_CURRENT_LOCAL_RULE: 'SET_CURRENT_LOCAL_RULE',
  FETCH_ALL_LOCAL_RULE_REQUEST: 'FETCH_ALL_LOCAL_RULE_REQUEST',
  FETCH_ALL_LOCAL_RULE_SUCCESS: 'FETCH_ALL_LOCAL_RULE_SUCCESS',
  FETCH_ALL_LOCAL_RULE_FAIL: 'FETCH_ALL_LOCAL_RULE_FAIL',
  ADD_LOCAL_RULE_REQUEST: 'ADD_LOCAL_RULE_REQUEST',
  ADD_LOCAL_RULE_SUCCESS: 'ADD_LOCAL_RULE_SUCCESS',
  ADD_LOCAL_RULE_FAIL: 'ADD_LOCAL_RULE_FAIL',
  UPDATE_LOCAL_RULE_REQUEST: 'UPDATE_LOCAL_RULE_REQUEST',
  UPDATE_LOCAL_RULE_SUCCESS: 'UPDATE_LOCAL_RULE_SUCCESS',
  UPDATE_LOCAL_RULE_FAIL: 'UPDATE_LOCAL_RULE_FAIL',
  DELETE_LOCAL_RULE_REQUEST: 'DELETE_LOCAL_RULE_REQUEST',
  DELETE_LOCAL_RULE_SUCCESS: 'DELETE_LOCAL_RULE_SUCCESS',
  DELETE_LOCAL_RULE_FAIL: 'DELETE_LOCAL_RULE_FAIL',
  COPY_LOCAL_RULE_REQUEST: 'COPY_LOCAL_RULE_REQUEST',
  COPY_LOCAL_RULE_SUCCESS: 'COPY_LOCAL_RULE_SUCCESS',
  COPY_LOCAL_RULE_FAIL: 'COPY_LOCAL_RULE_FAIL',
};

export const STATISTIC_ACTIONTYPES = {
  STATISTIC_SET_TOTAL_PAYMENT_FAILED: 'STATISTIC_SET_TOTAL_PAYMENT_FAILED',
};

export const PAYMENT_LIST = {
  SET_SHOW_PAYMENT_INFO_MODAL: 'SET_SHOW_PAYMENT_INFO_MODAL'
}