import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './baseServices';

export default {
  getYears: async (companyId) => {
    // companyId, accountNumber
    try {
      const queryParams = httpHelper.serialize({ companyId });
      const url = `${api.transaction.getYears}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatistic: async (params) => {
    // year, companyId, companybankId
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.transaction.getStatistic}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updatePayment: async (id, paymentStatus, paymentDate) => {
    try {
      const params = {
        paymentStatus,
        paymentDate,
      };
      const data = await BaseServices.putDataToken(api.transaction.updatePayment + id, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getTransactionById: async (id) => {
    try {
      const data = await BaseServices.getDataToken(api.transaction.get + id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateMissing: async (id, formData, companyId) => {
    try {
      let index = 0;
      let form = { companyId };
      let url = `${api.transaction.updateMissing}/${id}`;
      if (formData.isPrivate !== undefined) {
        form = Object.assign(form, { isPrivate: formData.isPrivate });
      }
      if (typeof formData.note === 'string') {
        form = Object.assign(form, { note: formData.note });
      }
      if (formData.files && formData.files.length) {
        for (let i = 0; i < formData.files.length; i++) {
          if (formData.files[i] != null) {
            form[`files[${index++}]`] = formData.files[i];
          }
        }
        // if upload file, using this url
        url = `${api.transaction.updateMissingByAddVoucher}/${id}`;
      }
      const data = await BaseServices.putDataTokenForm(url, form);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getPdfFile: (path) => {
    return api.file + path;
  },
  import: async (params) => {
    // files, companyId, companyBankId
    try {
      let index = 0;
      const form = {
        companyId: params.companyId,
        companyBankId: params.companyBankId,
      };
      for (let i = 0; i < params.files.length; i++) {
        if (params.files[i] != null) {
          form[`files[${index++}]`] = params.files[i];
        }
      }
      const url = api.transaction.import;
      const data = await BaseServices.postDataTokenForm(url, form);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  readFileUpload: async (file) => {
    try {
      const url = api.transaction.readFileUpload;
      const data = await BaseServices.postDataTokenForm(url, { file });
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  importer: async (form) => {
    try {
      const url = api.transaction.importer;
      const data = await BaseServices.postDataToken(url, form);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  importDataUpload: async (form) => {
    try {
      const url = api.transaction.importDataUpload;
      const data = await BaseServices.postDataToken(url, form);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateStatus: async (transactions) => {
    try {
      const data = await BaseServices.putDataToken(api.transaction.updateStatus, { transactions });
      const result = await data.json();
      return result.success;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getFilDownload: (path) => {
    return api.download + path;
  },
  fetchAll: async (params) => {
    try {
      let queryParams = params;
      queryParams = httpHelper.serialize(params);
      const url = `${api.transaction.get}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateMissingByVoucher: async (params) => {
    try {
      const data = await BaseServices.putDataToken(api.transaction.updateMissingByVoucher, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getTransactionMissingSummary: async (params) => {
    try {
      let queryParams = params;
      queryParams = httpHelper.serialize(params);
      const url = `${api.transaction.getMissingSummary}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAdminImportAuto: async (params) => {
    try {
      const { template } = params;
      let queryParams = params;
      queryParams = httpHelper.serialize(params);
      let url = '';
      const sparebank1List = ['sparebank1', 'nordnorge'];
      if (template === 'dnb') url = `${api.dnb.fetchAll}?${queryParams}`;
      if (sparebank1List.includes(template)) {
        url = `${api.sp1.fetchAll}?${queryParams}`;
      }
      const data = await BaseServices.getBankDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  add: async (params) => {
    try {
      const url = `${api.transaction.add}`;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async (transactionId) => {
    try {
      const url = api.transaction.delete.replace(':id', transactionId);
      const data = await BaseServices.deleteDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addList: async (params) => {
    try {
      const url = `${api.transaction.addList}`;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getSuggestTransaction: async (params) => {
    try {
      let queryParams = params;
      queryParams = httpHelper.serialize(params);
      const url = `${api.transaction.getSuggestTransaction}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  autoUpdateTransaction: async (params) => {
    try {
      const url = `${api.transaction.autoUpdateTransaction}`;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  autoUpdateTransactionByAiLocal: async (params) => {
    try {
      const url = `${api.transaction.autoUpdateTransactionByAiLocal}`;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchById: async (transactionId) => {
    try {
      const url = api.transaction.fetchById.replace(':id', transactionId);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getHistory: async ({ bankTransactionId }) => {
    try {
      const query = httpHelper.serialize({ bankTransactionId });
      const url = api.transaction.getHistory + `?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  downloadTransactionPdfPrivate: async ({ companyBankId, status, companyId, year }) => {
    try {
      const query = httpHelper.serialize({ companyBankId, status, companyId, year });
      const url = api.transaction.downloadFilePdfPrivateLost + `?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
