/*
  Use for verify user and get ssn only
*/


import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import Actions from '../../../actions/3rd/signant.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import signantServices from '../../../../services/3rd/signant';

export default function* handleAuthorizeCompanyBanksSaga() {
  yield takeEvery(actionTypes.SIGNANT.GET_AUTH_STATUS, signantGetAuthStatus);
}

export function* signantGetAuthStatus(action) {
  try {
    const response = yield call(signantServices.getAuthStatus, action.payload);
    const { data, success } = response;
    if (success) {
      yield put(Actions.getAuthStatusSuccess(data));
    } else {
      yield put(Actions.getAuthStatusFail(data));
    }
  } catch (error) {
    yield put(Actions.getAuthStatusFail(error));
  }
}
