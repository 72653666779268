import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherServices from '../../../services/voucherServices';

export default function* getMissingSummary() {
  yield takeEvery(actionTypes.VOUCHER.GET_MISSING_SUMMARY_REQUEST, getMissingSummarySaga);
}

export function* getMissingSummarySaga(action) {
  try {
    const response = yield call(VoucherServices.getMissingSummary, action.payload);
    if (response.success) {
      yield put(actions.getMissingSummarySuccess(response.data));
    } else {
      yield put(actions.getMissingSummaryFail(response.data));
    }
  } catch (error) {
    yield put(actions.getMissingSummaryFail(error));
  }
}
