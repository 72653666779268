import { call, put, takeEvery } from 'redux-saga/effects';
import LocaRuleServices from '../../../../services/localRuleServices';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';

export default function* deleteLocalRuleSaga() {
  yield takeEvery(
    actionTypes.LOCAL_RULE.DELETE_LOCAL_RULE_REQUEST,
    deleteLocalRule
  );
}

export function* deleteLocalRule(action) {
  try {
    const response = yield call(LocaRuleServices.delete, action.payload.id);
    if (response.success) {
      yield put(actions.deleteLocalRuleSuccess(response.data));
      yield put(actions.fetchAllLocalRuleRequest(action.payload.params));
    } else {
      yield put(actions.deleteLocalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.deleteLocalRuleFail(error));
  }
}
