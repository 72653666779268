import { call, put, takeEvery } from 'redux-saga/effects';
import GlobalRuleServices from '../../../../services/globalRuleServices';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';

export default function* updateGlobalRuleSaga() {
  yield takeEvery(actionTypes.GLOBAL_RULE.UPDATE_GLOBAL_RULE_REQUEST, updateGlobalRule);
}

export function* updateGlobalRule(action) {
  try {
    const response = yield call(GlobalRuleServices.update, action.payload.values);
    if (response.success) {
      yield put(actions.updateGlobalRuleSuccess(response.data));
    } else {
      yield put(actions.updateGlobalRuleFail(response.data));
    }
    if (action.payload?.callback) {
      action.payload?.callback(response);
    }
  } catch (error) {
    yield put(actions.updateGlobalRuleFail(error));
  }
}
