import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isShowModalSettingBank: false,
  isShowBankAccountTable: false,
  data: [],
};

const settingBank = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTING_BANK.SET_SHOW_MODAL:
      return {
        ...state,
        isShowModalSettingBank: action.payload,
      };
    case types.SETTING_BANK.SET_SHOW_BANK_ACCOUNT_TABLE:
      return {
        ...state,
        isShowBankAccountTable: action.payload,
      };
    case types.SETTING_BANK.GET_SETTING_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default settingBank;
