import baseServices from '../../baseServices';
import { APIError } from '../../../constants/errorCode/system';
import { api } from '../../../constants/api.constant';
import httpHelper from '../../../helpers/httpHelper';
import API_RGBConstant from '../../../constants/3rd/API_RGB.constant';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  getByBasketUUID: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = API_RGBConstant.paymentsBaskets.getByBasketUUID + `?${query}`;
      const data = await baseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
