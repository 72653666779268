import * as types from '../actionTypes/voucherPaymentsBasket.actionTypes';
// INIT
const initialState = {
  currentBasketUUID: null
};

const voucherPaymentsBasket = (state = initialState, action) => {
  switch (action.type) {
    case types.VOUCHER_PAYMENTS_BASKET.SET_CURRENT_BASKET_UUID:
      return {
        ...state,
        currentBasketUUID: action.payload
      };
    default:
      return state;
  }
};

export default voucherPaymentsBasket;
