import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isFetching: false
  },
  arrPaymentPackages: null,
  currentPaymentPackage: null
};

const paymentPackages = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_PACKAGES.SET_CURRENT_PAYMENT_PACKAGE:
      return {
        ...state,
        currentPaymentPackage: action.payload
      };
    case types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_REQUEST:
      return {
        ...state,
        loading: {
          isFetching: true
        }
      };
    case types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        arrPaymentPackages: action.payload,
        loading: {
          isFetching: false
        }
      };
    case types.PAYMENT_PACKAGES.FETCH_ALL_PAYMENT_PACKAGES_FAIL:
      return {
        ...state,
        loading: {
          isFetching: false
        }
      };
    default:
      return state;
  }
};

export default paymentPackages;
