/*
  Use for verify user and get ssn only
*/


import {
  put,
  takeEvery,
  call,
  select
} from 'redux-saga/effects';

import Actions from '../../../actions/3rd/signant.actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import signantServices from '../../../../services/3rd/signant';
import * as selectors from '../../../../selectors';

export default function* signantAuthSaga() {
  yield takeEvery(actionTypes.SIGNANT.AUTH_REQUEST, signantAuth);
}

export function* signantAuth(action) {
  try {
    const response = yield call(signantServices.initAuth, action.payload);
    const { data, success } = response;
    if (success) {
      const company = yield select(selectors.company) || {};
      const { CompanyId: companyId } = company;
      const { _user: { Id: assignUser } } = window;
      const objdata = Object.assign(data, { companyId, assignUser });
      localStorage.setItem('tmp-signant-auth', JSON.stringify(objdata));
      yield put(Actions.signantAuthSuccess(data));
    } else {
      yield put(Actions.signantAuthFail(data));
    }
  } catch (error) {
    yield put(Actions.signantAuthFail(error));
  }
}
