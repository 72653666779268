import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyUsersAssignPaymentServices from '../../../services/companyUsersAssignPaymentServices';

export default function* addCompanyUsersAssignPaymentSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_ASSIGN_PAYMENT.ADD_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST, addCompanyUsersAssignPayment);
}

export function* addCompanyUsersAssignPayment(action) {
  try {
    const response = yield call(companyUsersAssignPaymentServices.add, action.payload);
    if (response.success) {
      yield put(actions.companyUsersAssignPayment.addSuccess(response.data));
    } else {
      yield put(actions.companyUsersAssignPayment.addFail(response.data));
    }
  } catch (error) {
    yield put(actions.companyUsersAssignPayment.addFail(error));
  }
}
