import { call, put, takeEvery } from 'redux-saga/effects';
import LocaRuleServices from '../../../../services/localRuleServices';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';

export default function* updateLocalRuleSaga() {
  yield takeEvery(
    actionTypes.LOCAL_RULE.UPDATE_LOCAL_RULE_REQUEST,
    updateLocalRule
  );
}

export function* updateLocalRule(action) {
  try {
    const response = yield call(LocaRuleServices.update, action.payload.values);
    if (response.success) {
      yield put(actions.updateLocalRuleSuccess(response.data));
      if (action.payload?.callback) {
        action.payload?.callback();
      }
    } else {
      yield put(actions.updateLocalRuleFail(response.data));
    }
  } catch (error) {
    yield put(actions.updateLocalRuleFail(error));
  }
}
