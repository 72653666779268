import BaseServices from './baseServices';
import { APIError } from '../constants/errorCode/system';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  add: async params => {
    try {
      const url = api.companyUsers.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async (params = {}) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companyUsers.fetchAll}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.companyUsers.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
