import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyBankServices from '../../../services/companyBankServices';

export default function* setDefaultAccountNumberSaga() {
  yield takeEvery(actionTypes.COMPANY_BANK.SET_DEFAULT_ACCOUNT_NUMBER, setDefaultAccountNumber);
}

export function* setDefaultAccountNumber(action) {
  try {
    const response = yield call(companyBankServices.setDefaultAccountNumber, action.payload);
    const { success, data, message } = response;
    if (success) {
      action?.callback && action?.callback();
      yield put(actions.companyBank.setDefaultAccountNumberSuccess(data));
    } else {
      yield put(actions.companyBank.setDefaultAccountNumberFail(message));
    }
  } catch (error) {
    yield put(actions.companyBank.setDefaultAccountNumberFail(error));
  }
}
