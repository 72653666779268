import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyUsersAssignPaymentServices from '../../../services/companyUsersAssignPaymentServices';

export default function* getCompanyUsersAssignPaymentSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_ASSIGN_PAYMENT.GET_COMPANY_USERS_ASSIGN_PAYMENT_REQUEST, getCompanyUsersAssignPayment);
}

export function* getCompanyUsersAssignPayment(action) {
  try {
    const response = yield call(companyUsersAssignPaymentServices.get, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.companyUsersAssignPayment.getSuccess(data));
    } else {
      yield put(actions.companyUsersAssignPayment.getFail(data));
    }
  } catch (error) {
    yield put(actions.companyUsersAssignPayment.getFail(error));
  }
}
