import * as types from '../../actionTypes/ActionTypes';
// INIT
const initialState = {
  isLoading: false,
  paymentIntent: null,
  errors: {
    paymentIntent: null
  }
};

const stripe = (state = initialState, action) => {
  switch (action.type) {
    case types.STRIPE.CREATE_PAYMENT_INTENT:
      return {
        ...state,
        isLoading: true,
        errors: {
          paymentIntent: null
        }
      };
    case types.STRIPE.CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentIntent: action.payload
      };
    case types.STRIPE.CREATE_PAYMENT_INTENT_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: {
          paymentIntent: action.payload
        }
      };
    default:
      return state;
  }
};

export default stripe;
