import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actionTypes/ActionTypes';
import rgbPaymentsService from '../../../../services/3rd/rgb/rgbPayments.service';


export default function* updatePaymentStatusSaga() {
  yield takeEvery(actionTypes.RGB.UPDATE_PAYMENT_STATUS, updatePaymentStatus);
}

export function* updatePaymentStatus(action) {
  try {
    const response = yield call(rgbPaymentsService.updatePaymentStatus, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.rgb.updatePaymentStatusSuccess(data));
    } else {
      yield put(actions.rgb.updatePaymentStatusFail(response.message));
    }
  } catch (error) {
    yield put(actions.rgb.updatePaymentStatusFail(error));
  }
}
