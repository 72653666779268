import React from 'react';
import * as Utils from '../../utils/Utils';

class SignNextBankAuthorize extends React.PureComponent {
  render() {
    const { t, authorizeCompanyBanksData } = this.props;
    const { accountNumbers, authorizeData } = authorizeCompanyBanksData;
    return (
      <div>
        <table>
          <tbody>
            {authorizeData.map((item, index) => {
              const accountNumber = accountNumbers[index] && Utils.bankNumber(accountNumbers[index]);
              const { resData } = item;
              let status = 0;
              const [approvaldData] = resData;
              const { approvalAccessIndicator } = approvaldData;
              if (approvalAccessIndicator === 'OK') status = 1;
              const text = status === 1 ? t('authorized') : t('unAuthorized');
              return (
                <tr key={index}>
                  <td>{accountNumber}</td>
                  <td>{text}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SignNextBankAuthorize;
