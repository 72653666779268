import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isShowModal: false,
  isShowModalCopy: false,
  loading: {
    isFetchingAllLocalRule: false,
    isDeletingAllLocalRule: false,
    isAddingLocalRule: false,
    isCopyingLocalRule: false
  },
  localRules: {
    rows: [],
    total: 0
  },
  copyRules: null,
  currentLocalRule: {}
};

const localRule = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCAL_RULE.SET_SHOW_MODAL:
      return {
        ...state,
        isShowModal: action.payload
      };
    case types.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isFetchingAllLocalRule: true
        }
      };
    case types.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_SUCCESS:
      return {
        ...state,
        localRules: action.payload,
        loading: {
          isFetchingAllLocalRule: false
        }
      };
    case types.LOCAL_RULE.FETCH_ALL_LOCAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isFetchingAllLocalRule: false
        }
      };
    case types.LOCAL_RULE.DELETE_LOCAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isDeletingAllLocalRule: true
        }
      };
    case types.LOCAL_RULE.DELETE_LOCAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isDeletingAllLocalRule: false
        }
      };
    case types.LOCAL_RULE.DELETE_LOCAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isDeletingAllLocalRule: false
        }
      };
    case types.LOCAL_RULE.ADD_LOCAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isAddingLocalRule: true
        }
      };
    case types.LOCAL_RULE.ADD_LOCAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isAddingLocalRule: false
        }
      };
    case types.LOCAL_RULE.ADD_LOCAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isAddingLocalRule: false
        }
      };
    case types.LOCAL_RULE.UPDATE_LOCAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isAddingLocalRule: true
        }
      };
    case types.LOCAL_RULE.UPDATE_LOCAL_RULE_SUCCESS:
      return {
        ...state,
        loading: {
          isAddingLocalRule: false
        }
      };
    case types.LOCAL_RULE.UPDATE_LOCAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isAddingLocalRule: false
        }
      };
    case types.LOCAL_RULE.COPY_LOCAL_RULE_REQUEST:
      return {
        ...state,
        loading: {
          isCopyingLocalRule: true
        }
      };
    case types.LOCAL_RULE.COPY_LOCAL_RULE_SUCCESS:
      return {
        ...state,
        copyRules: action.payload,
        loading: {
          isCopyingLocalRule: false
        }
      };
    case types.LOCAL_RULE.COPY_LOCAL_RULE_FAIL:
      return {
        ...state,
        loading: {
          isCopyingLocalRule: false
        }
      };
    case types.LOCAL_RULE.SET_CURRENT_LOCAL_RULE:
      return {
        ...state,
        currentLocalRule: action.payload
      };
    case types.LOCAL_RULE.SET_SHOW_MODAL_COPY:
      return {
        ...state,
        isShowModalCopy: action.payload
      };
    case types.LOCAL_RULE.SET_COPY_RULE_SUCCESS:
      return {
        ...state,
        copyRules: action.payload
      };
    default:
      return state;
  }
};

export default localRule;
