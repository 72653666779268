export const BANK = {
  SET_CURRENT_TRANSACTION: 'SET_CURRENT_TRANSACTION',
  SET_FILTER_BANK: 'SET_FILTER_BANK',
  RESET_FILTER_BANK: 'RESET_FILTER_BANK',
  FETCH_BANK_DATA_REQUEST: 'FETCH_BANK_DATA_REQUEST',
  FETCH_BANK_DATA_SUCCESS: 'FETCH_BANK_DATA_SUCCESS',
  FETCH_BANK_DATA_FAIL: 'FETCH_BANK_DATA_FAIL',
  SET_BANK_TRANSACTION: 'SET_BANK_TRANSACTION',
  GET_BANK_BY_SWIFT_CODE_REQUEST: 'GET_BANK_BY_SWIFT_CODE_REQUEST',
  GET_BANK_BY_SWIFT_CODE_SUCCESS: 'GET_BANK_BY_SWIFT_CODE_SUCCESS',
  GET_BANK_BY_SWIFT_CODE_FAIL: 'GET_BANK_BY_SWIFT_CODE_FAIL',
  FETCH_BANK_BY_COMPANY_REQUEST: 'FETCH_BANK_BY_COMPANY_REQUEST',
  FETCH_BANK_BY_COMPANY_SUCCESS: 'FETCH_BANK_BY_COMPANY_SUCCESS',
  FETCH_BANK_BY_COMPANY_FAIL: 'FETCH_BANK_BY_COMPANY_FAIL',
  GET_BANK_BY_FILTER_REQUEST: 'GET_BANK_BY_FILTER_REQUEST',
  GET_BANK_BY_FILTER_SUCCESS: 'GET_BANK_BY_FILTER_SUCCESS',
  GET_BANK_BY_FILTER_FAIL: 'GET_BANK_BY_FILTER_FAIL',
  CHANGE_COLUMN_DEFINE_IMPORT: 'CHANGE_COLUMN_DEFINE_IMPORT',
  CHANGE_DATA_IMPORT: 'CHANGE_DATA_IMPORT',
  RESET_DATA_IMPORT: 'RESET_DATA_IMPORT',
  SET_TAB_BANK_DETAIL: 'SET_TAB_BANK_DETAIL'
};

export const COLUMN_DEFINE_DEFAULT = [
  'date',
  'text',
  'text2',
  'amountIn',
  'amountOut',
  'amount'
];
