import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyPaymentPackagesServices from '../../../services/companyPaymentPackagesServices';

export default function* addCompanyPaymentPackagesSaga() {
  yield takeEvery(actionTypes.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_REQUEST, addCompanyPaymentPackages);
}

export function* addCompanyPaymentPackages(action) {
  try {
    const response = yield call(companyPaymentPackagesServices.add, action.payload);
    const { success } = response;
    if (success) yield put(actions.addCompanyPaymentPackagesSuccess(response.data));
  } catch (error) {
    yield put(actions.addCompanyPaymentPackagesFail(error));
  }
}
