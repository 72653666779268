export default {
  serialize: (obj) => {
    const data = Object.keys(obj)
      .map((key) => {
        if (Array.isArray(obj[key])) {
          let strArr = '';
          for (let i = 0; i < obj[key].length; i++) {
            const char = i === obj[key].length - 1 ? '' : '&';
            strArr += `${key}[]=${encodeURIComponent(obj[key][i])}${char}`;
          }
          return strArr;
        }
        const isNotValid = obj[key] !== null && obj[key] !== undefined;
        return isNotValid ? `${key}=${encodeURIComponent(obj[key])}` : '';
      })
      .join('&');
    return data;
  },
};
