import BaseServices from './baseServices';
import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';

export default {
  computeData: async params => {
    try {
      const url = api.inbox.computeData;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  readById: async id => {
    try {
      const url = api.inbox.readById.replace(':id', id);
      const data = await BaseServices.postDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getInboxByUUID: async params => {
    try {
      const { uuid } = params;
      const url = api.oryton.getInboxDetail.replace(':uuid', uuid);
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  readInbox: async params => {
    try {
      const uuid = params.uuid;
      const url = api.oryton.readInbox.replace(':uuid', uuid) + '/read';
      const data = await BaseServices.putOrytonDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
