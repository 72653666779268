import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  counter: {
    totalFailed: 0,
  },
};

const statisticReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.STATISTIC_ACTIONTYPES.STATISTIC_SET_TOTAL_PAYMENT_FAILED:
      return {
        ...state,
        counter: {
          ...state.counter,
          totalFailed: action.payload,
        },
      };
    default:
      return state;
  }
};

export default statisticReducers;
