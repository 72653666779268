import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions/voucherPaymentInternal.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getByVoucherIdInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_BY_VOUCHER_ID_REQUEST, getByVoucherId);
}

export function* getByVoucherId(action) {
  try {
    const response = yield call(VoucherPaymentServices.getByVoucherId, action.payload);
    if (response.success) {
      const { data } = response;
      yield put(actions.getByVoucherIdSuccessInternal(data));
    } else {
      yield put(actions.getByVoucherIdFailInternal(response.message));
    }
  } catch (error) {
    yield put(actions.getByVoucherIdFailInternal(error));
  }
}
