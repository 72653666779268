import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions/voucherPaymentInternal.actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import VoucherPaymentServices from '../../../services/voucherPaymentServices';

export default function* getVoucherPaymentStatisticInternalSaga() {
  yield takeEvery(actionTypes.VOUCHER_PAYMENT_INTERNAL.INTERNAL_GET_VOUCHER_PAYMENT_STATISTIC, getVoucherPaymentStatistic);
}

export function* getVoucherPaymentStatistic(action) {
  try {
    const response = yield call(VoucherPaymentServices.getStatistic, action.payload);
    const { success, data } = response;
    if (success) {
      yield put(actions.getVoucherPaymentStatisticSuccessInternal(data));
    } else {
      yield put(actions.getVoucherPaymentStatisticFailInternal(response.message));
    }
  } catch (error) {
    yield put(actions.getVoucherPaymentStatisticFailInternal(error));
  }
}
