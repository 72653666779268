import baseServices from '../../baseServices';
import { APIError } from '../../../constants/errorCode/system';
import API_RGBConstant from '../../../constants/3rd/API_RGB.constant';

/*
  data: {
    token: Oryton_BankService_1234!@
  }
*/

export default {
  crsAuthorize: async params => {
    // organisationNumber, ssn, accountNumber
    try {
      const url = API_RGBConstant.sparebank1.crsAuthorize;
      const data = await baseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  }
};
