import * as types from '../actionTypes/ActionTypes';

export const getByCompanyId = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_REQUEST,
  payload
});
export const getByCompanyIdSuccess = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_SUCCESS,
  payload
});
export const getByCompanyIdFail = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.GET_COMPANY_PAYMENT_PACKAGES_BY_COMPANY_ID_FAIL,
  payload
});

export const addCompanyPaymentPackages = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_REQUEST,
  payload
});
export const addCompanyPaymentPackagesSuccess = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_SUCCESS,
  payload
});
export const addCompanyPaymentPackagesFail = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.ADD_COMPANY_PAYMENT_PACKAGES_FAIL,
  payload
});

export const updateCompanyPaymentPackages = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_REQUEST,
  payload
});
export const updateCompanyPaymentPackagesSuccess = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_SUCCESS,
  payload
});
export const updateCompanyPaymentPackagesFail = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.UPDATE_COMPANY_PAYMENT_PACKAGES_FAIL,
  payload
});

export const buyCompanyPaymentPackages = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_REQUEST,
  payload
});
export const buyCompanyPaymentPackagesSuccess = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_SUCCESS,
  payload
});
export const buyCompanyPaymentPackagesFail = payload => ({
  type: types.COMPANY_PAYMENT_PACKAGES.BUY_PAYMENT_PACKAGES_FAIL,
  payload
});
