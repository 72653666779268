import * as types from '../actionTypes/ActionTypes';

export const setShowPaymentOptions = payload => ({
  type: types.VOUCHER_PAYMENT.SET_SHOW_PAYMENT_OPTIONS,
  payload
});

export const setPaymentListTableChecked = payload => ({
  type: types.VOUCHER_PAYMENT.SET_PAYMENT_LIST_TABLE_CHECKED,
  payload
});

export const setOpenFullScreen = payload => ({
  type: types.VOUCHER_PAYMENT.SET_OPEN_FULLSCREEN,
  payload
});

export const setFilter = payload => ({
  type: types.VOUCHER_PAYMENT.SET_FILTER,
  payload
});

export const addToPaymentList = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_REQUEST,
  payload
});
export const addToPaymentListSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_SUCCESS,
  payload
});
export const addToPaymentListFail = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_TO_PAYMENT_LIST_FAIL,
  payload
});

export const fetchVoucherPayment = payload => ({
  type: types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_REQUEST,
  payload
});
export const fetchVoucherPaymentSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_SUCCESS,
  payload
});
export const fetchVoucherPaymentFail = payload => ({
  type: types.VOUCHER_PAYMENT.FETCH_VOUCHER_PAYMENT_FAIL,
  payload
});

export const payToBank = payload => ({
  type: types.VOUCHER_PAYMENT.PAY_TO_BANK_REQUEST,
  payload
});
export const payToBankSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.PAY_TO_BANK_SUCCESS,
  payload
});
export const payToBankFail = payload => ({
  type: types.VOUCHER_PAYMENT.PAY_TO_BANK_FAIL,
  payload
});

export const saveVoucherPayment = payload => ({
  type: types.VOUCHER_PAYMENT.SAVE_VOUCHER_PAYMENT_REQUEST,
  payload
});
export const saveVoucherPaymentSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.SAVE_VOUCHER_PAYMENT_SUCCESS,
  payload
});
export const saveVoucherPaymentFail = payload => ({
  type: types.VOUCHER_PAYMENT.SAVE_VOUCHER_PAYMENT_FAIL,
  payload
});

export const getByVoucherId = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_REQUEST,
  payload
});
export const getByVoucherIdSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_SUCCESS,
  payload
});
export const getByVoucherIdFail = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_VOUCHER_ID_FAIL,
  payload
});

export const setCurrentVoucherPayment = payload => ({
  type: types.VOUCHER_PAYMENT.SET_CURRENT_VOUCHER_PAYMENT,
  payload
});

export const updateVoucherPaymentsStatus = payload => ({
  type: types.VOUCHER_PAYMENT.UPDATE_VOUCHER_PAYMENTS_STATUS,
  payload
});

export const updateVoucherPaymentsStatusSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.UPDATE_VOUCHER_PAYMENTS_STATUS_SUCCESS,
  payload
});

export const updateVoucherPaymentsStatusFail = payload => ({
  type: types.VOUCHER_PAYMENT.UPDATE_VOUCHER_PAYMENTS_STATUS_FAIL,
  payload
});

export const getByPaymentsUUID = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_REQUEST,
  payload
});
export const getByPaymentsUUIDSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_SUCCESS,
  payload
});
export const getByPaymentsUUIDdFail = payload => ({
  type: types.VOUCHER_PAYMENT.GET_BY_PAYMENTS_UUID_FAIL,
  payload
});

export const getVoucherPaymentStatistic = payload => ({
  type: types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC,
  payload
});
export const getVoucherPaymentStatisticSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC_SUCCESS,
  payload
});
export const getVoucherPaymentStatisticFail = payload => ({
  type: types.VOUCHER_PAYMENT.GET_VOUCHER_PAYMENT_STATISTIC_FAIL,
  payload
});

export const addUnpaidEhfVouchersToPaymentList = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST,
  payload
});
export const addUnpaidEhfVouchersToPaymentListSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_SUCCESS,
  payload
});
export const addUnpaidEhfVouchersToPaymentListFail = payload => ({
  type: types.VOUCHER_PAYMENT.ADD_UNPAID_EHF_VOUCHERS_TO_PAYMENT_LIST_FAIL,
  payload
});

export const getPaymentActions = payload => ({
  type: types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS,
  payload
});
export const getPaymentActionsSuccess = payload => ({
  type: types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS_SUCCESS,
  payload
});
export const getPaymentActionsFail = payload => ({
  type: types.VOUCHER_PAYMENT.GET_PAYMENT_ACTIONS_FAIL,
  payload
});