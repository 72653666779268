import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyUsersAssignPaymentServices from '../../../services/companyUsersAssignPaymentServices';

export default function* getBanksVerifiedPaymentSaga() {
  yield takeEvery(actionTypes.COMPANY_USERS_ASSIGN_PAYMENT.GET_BANKS_VERIFIED_PAYMENT_REQUREST, getBanksVerifiedPayment);
}

export function* getBanksVerifiedPayment(action) {
  try {
    const response = yield call(companyUsersAssignPaymentServices.getBanksVerifiedPayment, action.payload);
    if (response.success) {
      yield put(actions.companyUsersAssignPayment.getBanksVerifiedPaymentSuccess(response.data));
    } else {
      yield put(actions.companyUsersAssignPayment.getBanksVerifiedPaymentFail(response.data));
    }
  } catch (error) {
    yield put(actions.companyUsersAssignPayment.getBanksVerifiedPaymentFail(error));
  }
}
