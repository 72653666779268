import clsx from 'clsx';
import React, { PureComponent } from 'react';
import withStyles from 'react-jss';
import { webConfig } from '../../configs/system.configs';
import { loadNewBody, revertBody } from '../../utils/Utils';
import styles from './styles';

const bodyClass = ['1-colum', 'bg-full-screen-image', 'blank-page'];
const baseUrl = webConfig.webUrl;

class Unauthorized extends PureComponent {
  componentDidMount() {
    loadNewBody(bodyClass);
  }

  componentWillUnmount() {
    revertBody(bodyClass);
  }

  render() {
    const { location: { pathname } } = window;
    const { classes } = this.props;
    if (pathname.indexOf('unauthorized') !== -1) return null;
    return (
      <div className="row">
        <div className="col s12 center-align white">
          <img
            src={`${baseUrl}/assets/images/gallery/error-2.png`}
            className={clsx('bg-image-404', classes.root)} alt="404-page-img"
          />
          <h1 className="error-code m-0">404</h1>
          <h6 className="mb-2">Not found here</h6>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Unauthorized);
