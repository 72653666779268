import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  loading: {
    isFetching: false,
    isAddingSuccess: false
  },
  companyUsersData: {
    rows: [],
    total: 0
  },
  selectedUser: {},
  companyUsersModal: {
    openModal: false
  }
};

const companyUsers = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_USERS.SET_OPEN_MODAL:
      return {
        ...state,
        companyUsersModal: {
          openModal: action.payload
        }
      };
    case types.COMPANY_USERS.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_REQUEST:
      return {
        ...state,
        loading: {
          isFetching: true
        }
      };
    case types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsersData: action.payload,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_USERS.FETCH_ALL_COMPANY_USERS_FAIL:
      return {
        ...state,
        loading: {
          isFetching: false
        }
      };
    case types.COMPANY_USERS.ADD_COMPANY_USERS_REQUEST:
      return {
        ...state,
        loading: {
          isAdding: true
        }
      };
    case types.COMPANY_USERS.ADD_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        loading: {
          isAdding: false
        }
      };
    case types.COMPANY_USERS.ADD_COMPANY_USERS_FAIL:
      return {
        ...state,
        loading: {
          isAdding: false
        }
      };
    default:
      return state;
  }
};

export default companyUsers;
