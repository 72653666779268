import {
  put,
  takeEvery,
  call
} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import BankTransactionServices from '../../../services/transactionServices';

export default function* getSuggestTransaction() {
  yield takeEvery(actionTypes.BANK_TRANSACTION.GET_SUGGEST_TRANSACTION_REQUEST, getSuggestTransactionSaga);
}

export function* getSuggestTransactionSaga(action) {
  try {
    const response = yield call(BankTransactionServices.getSuggestTransaction, action.payload);
    if (response.success) {
      yield put(actions.getSuggestTransactionSuccess(response.data));
    } else {
      yield put(actions.getSuggestTransactionFail(response.data));
    }
  } catch (error) {
    yield put(actions.getSuggestTransactionFail(error));
  }
}
