import * as types from '../actionTypes/ActionTypes';

export const setConnectMissingBank = (payload) => ({
  type: types.CONNECT_MISSING.SET_CONNECT_MISSING_BANK,
  payload,
});

export const setConnectMissingVoucher = (payload) => ({
  type: types.CONNECT_MISSING.SET_CONNECT_MISSING_VOUCHER,
  payload,
});

export const getConnectMissingVoucher = (payload) => ({
  type: types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_REQUEST,
  payload,
});

export const getConnectMissingVoucherSuccess = (payload) => ({
  type: types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_SUCCESS,
  payload,
});

export const getConnectMissingVoucherFail = (payload) => ({
  type: types.CONNECT_MISSING.GET_CONNECT_MISSING_VOUCHER_FAIL,
  payload,
});

export const removeRecordVoucher = (payload) => ({
  type: types.CONNECT_MISSING.REMOVE_RECORD_VOUCHER,
  payload,
});
