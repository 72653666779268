import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../../actions';
import * as actionTypes from '../../actionTypes/ActionTypes';
import companyUsersAlertVoucherServices from '../../../services/companyUsersAlertVoucherServices';
import companyUsersReminderServices from '../../../services/companyUsersReminderServices';

export default function* saveSettingUserSaga() {
  yield takeEvery(actionTypes.SETTING.SAVE_SETTING_USER, saveSettingUser);
}

export function* saveSettingUser(action) {
  try {
    // save both alert and reminder data in 1 saga

    const { alertParams, remidnerParams } = action.payload;
    const response = yield call(companyUsersAlertVoucherServices.add, alertParams);
    if (response.success) {
      const reminderResponse = yield call(companyUsersReminderServices.add, remidnerParams);
      if (reminderResponse.success) {
        action?.callback();
        yield put(actions.setting.saveSettingUserSuccess(response.data));
      }
    }
    yield put(actions.setting.saveSettingUserFail(response.data));

    // if (response.success) {
    //   yield put(actions.addCompanyUsersSuccess(response.data));
    // } else {
    //   yield put(actions.addCompanyUsersFail(response.data));
    // }
  } catch (error) {
    yield put(actions.setting.saveSettingUserFail(error));
  }
}
