import React from "react";
import RcNotification from 'rc-notification';

export const fireNotification = ({ message, type = 0 }) => {

  const types = {
    0: 'red',
    1: 'blue',
    2: 'green'
  }

  const renderContent = (message) => {
    return (
      <div id="toast-container" style={{ right: '2%', width: 'fit-content', top: '7.5%' }}>
        <div className={`toast panning fadeUp ${types[type]} lighten-1`} style={{ top: 'unset' }}>
          <span>{message}</span>
        </div>
      </div>
    )
  }

  return RcNotification.newInstance({}, (notif) => {
    const option = {
      content: renderContent(message),
      duration: 3,
    };
    notif.notice(option);
  });
}