import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ztlServices from '../../../../services/ztlServices';
import * as Utils from '../../../../utils/Utils';
import LoadingProgessAsyncData from '../../../UI/LoadingProgessAsyncData';
import { useStyles } from './styles';
import companyServices from '../../../../services/companyServices';
import { RoutesUrl } from '../../../../constants/routes.constants';

const ONBOARDING_STATUSES = Array();
ONBOARDING_STATUSES['completed'] = 'Completed';
ONBOARDING_STATUSES['already-onboarded'] = 'already-onboarded';
ONBOARDING_STATUSES['company-not-found'] = 'company-not-found';
ONBOARDING_STATUSES['declined-enk-no-vat'] = 'company-not-found';
ONBOARDING_STATUSES['declined-risk-evaluation'] = 'declined-risk-evaluation';
ONBOARDING_STATUSES['declined-unsupported-company-type'] = 'declined-unsupported-company-type';
ONBOARDING_STATUSES['signorder-expired'] = 'signorder-expired';
ONBOARDING_STATUSES['pending'] = 'pending';
ONBOARDING_STATUSES['success'] = 'success';

const OnboardingCallback = (props) => {
  const { t } = props;
  const [loading, setLoading] = useState(true);
  const [onboardingStatus, setOnboardingStatus] = useState({});
  const [company, setCompany] = useState({});
  const classes = useStyles();

  const handleCallback = async () => {
    setLoading(true);
    try {
      const { companyId } = Utils.getJsonFromUrl();
      if (companyId) {
        const { success, data } = await ztlServices.callbackUpdateStatusOnboarding(companyId);
        if (success) {
          if (data?.status) {
            setOnboardingStatus(data);
          }
        }
        const { isSuccess, data: companyUrl } = await companyServices.getCompany(companyId);
        if (isSuccess) {
          setCompany(companyUrl)
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
      // autoCloseWindow();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (onboardingStatus?.status && company?.Alias) {
        const { origin } = window.location;
        const redirectUrl = origin + RoutesUrl.Setting.replace(':company', company.Alias);
        window.location.href = redirectUrl;
      }
    }, 300);
  }, [onboardingStatus, company])

  // const autoCloseWindow = () => {
  //   setTimeout(() => {
  //     const status = window.close();
  //     if (status) {
  //       setCloseWindown(true);
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    handleCallback();
  }, []);

  // const ttext = ONBOARDING_STATUSES[onboardingStatus?.status] && `onboarding.ztl-status-${onboardingStatus?.status}`;
  // const statusText = t(ttext);
  // const statusCls = ['completed', 'success', 'already-onboarded']
  //   .includes(onboardingStatus?.status)
  //   ? classes.accepted
  //   : classes.rejected;

  return (
    <div className={classes.container}>
      <LoadingProgessAsyncData loading={loading} />
      {/* {statusText && (
        <span className={statusCls}>{statusText}</span>
      )} */}
    </div>
  );
};

export default withNamespaces('ztl')(OnboardingCallback);
