import * as types from '../actionTypes/ActionTypes';
// INIT
const initialState = {
  isSavingSettingUser: false
};

const setting = (state = initialState, action) => {
  switch (action.type) {
    case types.SETTING.SAVE_SETTING_USER:
      return {
        ...state,
        isSavingSettingUser: action.payload
      };
    case types.SETTING.SAVE_SETTING_USER_SUCCESS:
      return {
        ...state,
        isSavingSettingUser: action.payload
      };
    case types.SETTING.SAVE_SETTING_USER_FAIL:
      return {
        ...state,
        isSavingSettingUser: action.payload
      };
    default:
      return state;
  }
};

export default setting;
